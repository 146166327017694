import { useEffect } from "react";
import { ListSkeleton } from "../../skeletons/List";
import { useState } from "react";
import { Button, Container, Typography, Box, Tab, Tabs, styled } from "@mui/material";
import FloatingActionButtons from "../../components/Fab";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import { useBuilder } from "../../../hooks/useBuilder";
import store, { clear, setFieldState } from "../../store/formStore";
import { useSelector } from "react-redux";
import ListTeachers from "../teachers/ListTeachers";
import ListAule from "../aule/ListAule";
import ListDirettori from "../direttore/ListDirettori";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/it';
import corsi from "./corsi";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { AuthHelper } from "../../controllers/AuthHelper";
import { useNavigate } from "react-router-dom";
import html2pdf from "html2pdf.js";

import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import ListDigital from "./ListDigital";
import { savePdf as saveCambio } from "./pdfs/calendario_mensile_corso";
import { savePdf as saveCambioWeek } from "./pdfs/calendario_settimanale";
import ListSedi from "../sedi/ListSedi";

moment.locale('it');
const localizer = momentLocalizer(moment)
const DnDCalendar = withDragAndDrop(Calendar);

const AntTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: '#888',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    color: '#40a9ff',
    opacity: 1,
  },
  '&.Mui-selected': {
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
const ListCertificates = () => {
    const [certificates, setCertificates] = useState([]);
    const [loading, setLoading] = useState(false);
    const { pushMobileOpen, defaultOpen, addRouter } = useDashboardDrawer();
    const {builder, form, refs} = useBuilder();
    const [value, setValue] = useState(0);

    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const elements = useSelector(state => state.elements);
    const refresh = useSelector((state) => state.reload);

    useEffect(() => {
      getCertificates();
    }, [refresh]);
    const columns = [
        { field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header' },
        { field: 'name', headerName: 'Titolo', width: 200, headerClassName: 'data-grid-header' },
        { field: 'Certificato', headerName: 'Certificato', width: 200, headerClassName: 'data-grid-header' },
        { field: 'Docente', headerName: 'Docente', width: 200, headerClassName: 'data-grid-header' },
        { field: 'price', headerName: 'Prezzo', width: 200, headerClassName: 'data-grid-header' }

    ];

    const printMonthlyCalendar = async () => {
      let sorted = certificates.sort((a,b) => moment(a.from).diff(moment(b.from)));
      const groupByMonth = (data) => {
        const grouped = data.filter(item => moment(item.from).format('MM/YYYY') === moment().format('MM/YYYY')).reduce((acc, item) => {
            if(!acc[item['nome corso']])acc[item['nome corso']] = [];
            acc[item['nome corso']].push(item);
          return acc
        }, {});
        return grouped;
      }

      const parsedEvents = groupByMonth(certificates);
      
      const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/05528192-3f81-47cd-82c6-d1d0e21893f3`);
      const payload = await res.json();
      await saveCambio({...payload, month: moment().format('MMMM'), courses: parsedEvents});
    }
    const printCalendar = () => {
      let sorted = certificates.sort((a,b) => moment(a.from).diff(moment(b.from)));
      const monthsOrder = [
        'gennaio', 'febbraio', 'marzo', 'aprile', 'maggio', 'giugno',
        'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'
      ];
      const groupByMonth = (data) => {
        const grouped = data.filter(item => moment(item.from).isValid() && moment(item.from).year() === moment().year()).reduce((acc, item) => {
          if(moment(item.from).isValid()){
            if(!acc[moment(item.from).format('MMMM')])acc[moment(item.from).format('MMMM')] = [];
            acc[moment(item.from).format('MMMM')].push(item);
          }
          return acc
        }, {});
        return grouped;
      }


      const parsedEvents = groupByMonth(sorted);
      
      const reorder = (grouped) => {
        return Object.keys(grouped)
        .sort((a, b) => monthsOrder.indexOf(a) - monthsOrder.indexOf(b))
        .reduce((acc, key) => {
          acc[key] = grouped[key];
          return acc;
        }, {});
      }

      
      const table = `<html>
                    <body style="text-align: center;">
                      <h1 style="text-align: center">Calendario annuale corsi</h1>
                      {months}
                    </body>
                </html>`
      const singlePartial = `
                  <div style="border:1px solid transparent; margin-top: 0; height: 750px; padding-left: 35px; padding-right: 35px;" class="page html2pdf__page-break">
                  <h1 style="width: 100%; text-align: center">{month}</h1>
                        <table style="border-collapse: collapse; border:1px solid #000; width: 100%;">
                            <thead style="border-bottom: 1px solid #000">
                                <tr>
                                    <th style="border-right: 1px solid #000; padding: 10px">Nome corso</th>
                                    <th style="border-right: 1px solid #000">Dal</th>
                                    <th>Al</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                  </div>
      `;

      const partials = [];

      for(let event of monthsOrder){
        if(parsedEvents[event]){
          const row = `<tr style="border-bottom:1px solid">
            <td style="border-right: 1px solid #000; padding: 10px">{course}</td>
            <td style="border-right: 1px solid #000; padding: 10px">{from}</td>
            <td style="padding: 10px">{to}</td>
          </tr>`
          const rows = parsedEvents[event].sort((a,b) => moment(b.from) - moment(a.from)).reverse().map(item => row.replaceAll('{course}', item['nome corso']).replaceAll('{from}', moment(item['from']).format('DD/MM/YYYY')).replaceAll('{to}', moment(item['to']).format('DD/MM/YYYY')));

          const parsedPartial = singlePartial.replaceAll('{month}', `${event} ${moment().format('YYYY')}`).replaceAll('{rows}', rows.join('<br/>'));
          partials.push(parsedPartial);  
        }
       
          
      }
      
      html2pdf().set({
        filename: 'calendario_capitaneria_2025.pdf',
        html2canvas: { useCORS: true },
      }).from(table.replaceAll('{months}', partials.join('')))
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        window.open(pdf.output('bloburl'), '_blank');
      });
    }

    const printMonthCalendar = async () => {
      let sorted = certificates.sort((a,b) => moment(a.from).diff(moment(b.from)));
     
      const groupByMonth = (data) => {
        const grouped = data.filter(item => moment(item.from).isValid() && moment(item.from).year() === moment().year()).reduce((acc, item) => {
          if(moment(item.from).isValid()){
            if(!acc[moment(item.from).format('M')])acc[moment(item.from).format('M')] = [];
            acc[moment(item.from).format('M')].push(item);
          }
          return acc
        }, {});
        return grouped;
      }
      const user = await AuthHelper.getUserFromIdToken();
      const parsedEvents = groupByMonth(sorted);
      const aule = await (await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/aule/${user.Username}`)).json();
      console.log(aule);
      const retrieveMonthly = parsedEvents[Number(moment().format('M')) + 1];
      const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${retrieveMonthly[0].id}`)
      saveCambio({month: `${moment().add(1, 'month').format('MMMM').toUpperCase()} ${moment().format('YYYY')}`, courses: retrieveMonthly.map(course => ({...course, aula_esame: aule.filter(x => x.id === course.aula_esame)[0]['name']})), ...await res.json()})
    }
    const printWeekCalendar = async () => {
      let sorted = certificates.sort((a,b) => moment(a.from).diff(moment(b.from)));
      function filterNextWeekExam(data) {
        const startOfNextWeek = moment().add(1, 'week').startOf('week');
        const endOfNextWeek = moment().add(1, 'week').endOf('week');
      
        return data.filter(item => {
          const examDate = moment(item.data_esame, 'DD/MM/YYYY');
          return examDate.isBetween(startOfNextWeek, endOfNextWeek, null, '[]');
        });
      }

      sorted = filterNextWeekExam(sorted);
      let from = moment().add(1, 'week').startOf('week');
      let end = moment().add(1, 'week').endOf('week');
      const user = await AuthHelper.getUserFromIdToken();
      const aule = await (await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/aule/${user.Username}`)).json();
      const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${sorted[0].id}`)
      saveCambioWeek({month: `${moment().add(1, 'month').format('MMMM').toUpperCase()} ${moment().format('YYYY')}`, courses: sorted.map(course => ({...course, aula_esame: aule.filter(x => x.id === course.aula_esame)[0]['name']})), ...await res.json(), from, end})
    }
    const downloadReport = async() => {
      const user = await AuthHelper.getUserFromIdToken();
      const header = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
      const res = await (await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/registro/verbali/${user.Username}`, {method: 'POST'})).json();
      const a = document.createElement('a');
      a.href = header+res.file;
      a.download = 'report_verbali.xlsx';
      a.click();
    }
    const getCertificates = async() => {
      const user = await AuthHelper.getUserFromIdToken();
      const res = await (await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/courses/${user.Username}`)).json();
        setCertificates([...res]);
        setLoading(false);
    }
    useEffect(() => {
        getCertificates();
        store.dispatch(clear())
        builder('certificates.add');
    }, [])

    const handleClick = (data, name = null) => {
        store.dispatch(clear())
        builder('courses.add');
        if(data.row){
            Object.keys(data.row).forEach((key) => {
                store.dispatch(setFieldState({name: key, value: data.row[key]}));
            });
        }
        pushMobileOpen(true, 500, name ? name : "Modifica Corso", data.row);

    }
    const onEventDrop = async(data) => {
      setLoading(true);
      const {start, end, event} = data;
      const res = await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/career-path/course/dates/${event.id}`, {
        body: JSON.stringify({
          from: start,
          end: end
        }),
        method: 'PUT'
      });
      await getCertificates();
      setLoading(false);
    }
    const onEventResize = async (data) => {
      setLoading(true);
      const {start, end, event} = data;
      console.log(data);
      const res = await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/career-path/course/dates/${event.id}`, {
        body: JSON.stringify({
          from: start,
          end: end
        }),
        method: 'PUT'
      });
      await getCertificates();
      setLoading(false);
    }
    return (<Container sx={{paddingLeft: 24, paddingRight: 24, minWidth: '100vw', marginTop: '3rem'}}>
    <div style={{width: '100%', display: 'flex', justifyContent: 'start', gap: 24, marginBottom: 24}}>
      <Button onClick={() => window.location.href = "/dashboard/docenti/accreditamento"} sx={{backgroundColor: 'rgb(191, 163, 73)', fontWeight: 'bold', paddingX: 4, paddingY: 1, borderRadius: 66, fontSize: 12}}>Inserisci Domanda
Accreditamento Docente</Button>
      <Button onClick={() => window.location.href = "/dashboard/direttori/accreditamento"} sx={{backgroundColor: 'rgb(191, 163, 73)', fontWeight: 'bold', paddingX: 4, paddingY: 1, borderRadius: 66, fontSize: 12}}>Inserisci Domanda
Accreditamento Direttore</Button>
      <Button onClick={() => window.location.href = "/dashboard/aule/accreditamento"} sx={{backgroundColor: 'rgb(191, 163, 73)', fontWeight: 'bold', paddingX: 4, paddingY: 1, borderRadius: 66, fontSize: 12}}>Inserisci Domanda
Accreditamento Aule</Button>

{/* <Button sx={{backgroundColor: 'rgb(191, 163, 73)', fontWeight: 'bold', paddingX: 4, paddingY: 1, borderRadius: 66, fontSize: 12}} onClick={() => printMonthlyCalendar()}>Calendario Mensile</Button> */}
    </div>
    <div style={{width: '100%', display: 'flex', justifyContent: 'start', gap: 24}}>
    <Button sx={{backgroundColor: 'rgb(191, 163, 73)', fontWeight: 'bold', paddingX: 4, paddingY: 1, borderRadius: 66, fontSize: 12}} onClick={() => printCalendar()}>Stampa calendario annuale</Button>
    <Button sx={{backgroundColor: 'rgb(191, 163, 73)', fontWeight: 'bold', paddingX: 4, paddingY: 1, borderRadius: 66, fontSize: 12}} onClick={() => printMonthCalendar()}>Stampa calendario mensile</Button>
    <Button sx={{backgroundColor: 'rgb(191, 163, 73)', fontWeight: 'bold', paddingX: 4, paddingY: 1, borderRadius: 66, fontSize: 12}} onClick={() => printWeekCalendar()}>Stampa calendario settimanale</Button>
    <Button sx={{backgroundColor: 'rgb(191, 163, 73)', fontWeight: 'bold', paddingX: 4, paddingY: 1, borderRadius: 66, fontSize: 12}} onClick={() => downloadReport()}>Scarica report verbali</Button>
    </div>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
        sx={{
          color: '#888'
        }}
        TabIndicatorProps={{
          style:{
            text: '#888'
          }
        }} value={value} onChange={handleChange} aria-label="basic tabs example">
          <AntTab label="Calendario Annuale Corsi" {...a11yProps(0)} />
          <AntTab  label="Database Docenti Accreditati" {...a11yProps(1)} />
          <AntTab  label="Database Aule Accreditate" {...a11yProps(2)} />
          <AntTab  label="Database Direttori corsi" {...a11yProps(3)} />
          <AntTab  label="Database Sedi Accreditate" {...a11yProps(4)} />
          <AntTab  label="Corsi digitali" {...a11yProps(5)} />

        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {loading && <ListSkeleton />}
        {/* {!loading && <DataTable data={certificates} columns={columns} handleClick={handleClick} actions={[
            {type: 'select', name: 'Azioni'}
        ]}/>} */}
        {!loading && <div style={{height: 700}}><DnDCalendar
            localizer={localizer}
            events={certificates.map(certificate => (
              {
                'title': certificate['nome corso'],
                'start': moment(certificate.from).toDate(),
                'end': moment(certificate.to).toDate(),
                'id': certificate.id,
                'course': certificate['course']
              }
            ))}
            onSelectEvent={(e) => navigate(`/dashboard/corso/${e.id}?nome_corso=${encodeURIComponent(e.title)}&id=${e.course}&start=${e.start}&end=${e.end}`)}
            defaultDate={new Date()}
            startAccessor="start"
            endAccessor="end"
            onEventResize={onEventResize}
            onEventDrop={onEventDrop}
            resizable
            popup={false}
            step={40}
            views={['month', 'day', 'agenda']}
            defaultView="month"
            style={{ height: 1080 }}
            /></div>}
        <FloatingActionButtons onClick={() => handleClick([], "Crea corso")}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ListTeachers />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ListAule />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <ListDirettori />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <ListSedi />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <ListDigital />
      </CustomTabPanel>
      
    </Container>);
}

export default ListCertificates;