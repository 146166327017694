import { useEffect } from "react";
import { ListSkeleton } from "../../skeletons/List";
import { useState } from "react";
import { Button, Container, Typography, Box, Tab, Tabs, styled, TextField, Modal, Select, FormControl, InputLabel } from "@mui/material";
import FloatingActionButtons from "../../components/Fab";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import { useBuilder } from "../../../hooks/useBuilder";
import store, { clear, setFieldState, addCourse } from "../../store/formStore";
import { useSelector } from "react-redux";
import ListTeachers from "../teachers/ListTeachers";
import ListAule from "../aule/ListAule";
import ListDirettori from "../direttore/ListDirettori";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/it';
import corsi from "./corsi";
import { useParams } from 'react-router';

import "react-big-calendar/lib/css/react-big-calendar.css";
import { AuthHelper } from "../../controllers/AuthHelper";
import DataTable from "../../components/Table";
import { savePdf } from "./pdfs/libretto";
import { savePdf as saveCertificato } from "./pdfs/certificato";
import { savePdf as saveVerbale } from "./pdfs/verbale_esame";
import { savePdf as saveRegistroEsame } from "./pdfs/registro_presenze_esame";
import { savePdf as saveRegistro } from "./pdfs/registro_presenze_totale";
import { savePdf as saveElenco } from "./pdfs/elenco_partecipanti";
import { savePdf as saveLettera } from "./pdfs/scheda_ammissione_corso";
import { savePdf as saveTest } from "./pdfs/scheda_test";
import { savePdf as saveTestB } from "./pdfs/scheda_test_b";
import { savePdf as saveTestC } from "./pdfs/scheda_test_c";
import { savePdf as saveLibretto } from "./pdfs/libretto";
import { savePdf as saveProgrammazione } from "./pdfs/programmazione_corso";
import { savePdf as saveLetteraLicenza } from "./pdfs/lettera_licenza";
import { savePdf as saveNullaOsta } from "./pdfs/nulla_osta";
import { savePdf as save18 } from "./pdfs/oltre_1830";
import { savePdf as saveCambio } from "./pdfs/cambio_docente";

import { CopyAll, Delete, DeleteForever, MoreHoriz, Print, SettingsPowerRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import { blue } from '@mui/material/colors';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getPDF as ProgrammazionePDF } from "./pdfs/programmazione_corso_base64";
import { getPDF as LetteraPDF } from "./pdfs/lettera_esami_base64";
import { getPdf as ElencoPDF } from "./pdfs/elenco_partecipanti_base64";


moment.locale('it');
const localizer = momentLocalizer(moment)
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const ModalDuplica = (props) => {
  const handleOpen = () => props.setOpen(true);
  const handleClose = () => props.setOpen(false);

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const duplicate = async() => {
    console.log(from, to, props.id)
    const res = fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/course/duplicate/${props.id}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          from: from,
          to: to
        })
      }
    )
    handleClose();
    alert("Corso duplicato con successo");
  }
  return (
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Duplica corso
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Seleziona le date in cui duplicare il corso:
            <LocalizationProvider dateAdapter={AdapterDayjs}  adapterLocale="it">
                <DatePicker minDate={dayjs()} label="Da" value={from} onChange={(e) => setFrom(e)} renderInput={(props) => <TextField {...props} size="small" sx={{width: 150, mr: 2, mt: 2}}/>} />
              </LocalizationProvider>     
              <LocalizationProvider dateAdapter={AdapterDayjs}  adapterLocale="it">
                <DatePicker minDate={dayjs()} label="A" value={to} onChange={(e) => setTo(e)} renderInput={(props) => <TextField {...props} size="small" sx={{width: 150, mr: 2, mt: 2}}/>} />
              </LocalizationProvider>           
          </Typography>
          <div style={{display: 'flex', marginTop: 24, gap: 12}}>
            <Button color="error" onClick={handleClose}>Annulla</Button>
            <Button onClick={() => duplicate()}>Invia</Button>
          </div>
        </Box>
      </Modal>
  );
}
export function SimpleDialog(props) {
  const { onClose, selectedValue, open, params } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = async(value) => {
    
    const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/school/documents/rev/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        document: value
      })
    })
    if(res.status === 200){
      alert("Notifica inviata con successo.");
      onClose(value);
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Notifica di revisione documentazione</DialogTitle>
      <List sx={{ pt: 0 }}>
        {props.files?.map((file) => (
          <ListItem disableGutters key={file.id}>
            <ListItemButton onClick={() => handleListItemClick(file.name)}>
              <ListItemText primary={file.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
function SimpleDialogDates(props) {
  const { onClose, selectedValue, open, params, partecipant } = props;

  const [dates, setDates] = useState([]);

  const getDates = async() => {
    const user = await AuthHelper.getUserFromIdToken();
    const urlParams = new URLSearchParams(window.location.search);
    const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/school/courses/dates/${urlParams.get('id')}`,
    {
      method: 'PUT',
      body: JSON.stringify({
        school: user.Username,
        from: moment(urlParams.get('start')).format('YYYY-MM-DD')
      })
    });
    const json = await res.json();

    console.log('dates', json);
    setDates([...json]);
  }
  useEffect(() => {
    console.log('ciao');
    getDates();
  }, [])
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = async(value) => {
    const user = await AuthHelper.getUserFromIdToken();
  
    const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/school/courses/dates/move/${partecipant}`, {
      method: 'PUT',
      body: JSON.stringify({
        school: value.user,
        course: params.id,
        newCourse: value.id,
      })
    })
    if(res.status === 200){
      alert("Spostamento avvenuto con successo.");
      onClose(value);
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Sposta data prenotazione</DialogTitle>
      <List sx={{ pt: 0 }}>
        {dates.map((date) => (
          <ListItem disableGutters key={date.id}>
            <ListItemButton onClick={() => handleListItemClick(date)}>
              <ListItemText primary={`${date['nome corso']}: ${moment(date.from).format('DD/MM/YYYY')} - ${moment(date.to).format('DD/MM/YYYY')}`} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const AntTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: '#888',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&.Mui-selected': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }));
const CourseDetail = () => {
    const [lessons, setLessons] = useState([]);
    const [loading, setLoading] = useState(false);
    const { pushMobileOpen, defaultOpen, addRouter } = useDashboardDrawer();
    const {builder, form, refs} = useBuilder();
    const [value, setValue] = useState(0);
    const params = useParams()
    const [title, setTitle] = useState("");

    const [course, setCourse] = useState({});
    const [autorita, setAutorita] = useState("");
    const [dataEsame, setDataEsame] = useState("");
    const [oraEsame, setOraEsame] = useState("");
    const [fineEsame, setFineEsame] = useState("");
    const [sedeEsame, setSedeEsame] = useState("");

    const [aule, setAule] = useState([]);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    // const [selectedValue, setSelectedValue] = React.useState();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogDatesOpen, setDialogDatesOpen] = useState(false);
    const handleClickDatesOpen = () => {
      setDialogDatesOpen(true);
    };
    const handleCloseDatesDialog = (value) => {
      setDialogDatesOpen(false);
      getPartecipants();
    };
    const handleClickOpen = () => {
      setDialogOpen(true);
    };
  
    const handleCloseDialog = (value) => {
      setDialogOpen(false);
    };
  
    const documenti = [
      'certificate',
      'verbale',
      'registro',
      'scheda_valutazione',
      'registro_presenze',
      'foglio_riepilogativo'
    ]
    const elements = useSelector(state => state.elements);

    const columns = [
        { field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header' },
        { field: 'teacher', headerName: 'Docente', width: 125, headerClassName: 'data-grid-header' },
        { field: 'room', headerName: 'Aula', width: 125, headerClassName: 'data-grid-header' },
        { field: 'date', headerName: 'Data', width: 125, headerClassName: 'data-grid-header', valueGetter: (params) => moment(params.row.date).format('DD/MM/YYYY')},
        { field: 'hours', headerName: 'Ore', width: 50, headerClassName: 'data-grid-header', valueGetter: (params) => Number(params.row.hours) / 10},
        { field: 'hour_ranges', headerName: 'Orari', width: 200, headerClassName: 'data-grid-header' },
        { field: 'description', headerName: 'Descrizione', width: 200, headerClassName: 'data-grid-header' },
        { field: 'pratica', headerName: 'Pratica', width: 100, headerClassName: 'data-grid-header', valueGetter: (params) => params.row.pratica === true ? 'Sì' : 'No' },
        { field: 'actions', headerName: 'Azioni', width: 100, headerClassName: 'data-grid-header', renderCell: (_params, idx) => (
          <><IconButton size="small" onClick={async() => {
            await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/lesson/${_params.id}`, {
              method: 'DELETE'
            });
            await getLessons();
          }}><DeleteForever htmlColor="#DD0000" /></IconButton>
          <IconButton title="Stampa Registro" onClick={async() => {
            const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${params.id}`);
            const payload = await res.json();
            saveRegistro({lessons: lessons, ...payload, rowNumber: lessons.indexOf(lessons.filter(x => x.id === _params.row.id)[0])});
          }}><Print color="primary"/></IconButton></>
        )}

    ];
    const direttoriColumns = [
      { field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header' },
      { field: 'director', headerName: 'Direttore', width: 125, headerClassName: 'data-grid-header' },
      { field: 'actions', headerName: 'Azioni', width: 225, headerClassName: 'data-grid-header', renderCell: (_params) => (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open2 ? 'long-menu' : undefined}
            aria-expanded={open2 ? 'true' : undefined}
            aria-haspopup="true"
            onClick={_handleClick2}
          >
                <MoreHoriz></MoreHoriz>
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose2}
            PaperProps={{
              style: {
                width: '20ch',
              },
            }}
          >
            <MenuItem onClick={async() => {
              await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/director/${_params.row.id}`);
              await getDirettori()
            }}>Rimuovi Direttore</MenuItem>
          </Menu>
        </div>
      )}
  ];
    const partecipantsColumns = [
        { field: 'unique_id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header' },
        { field: 'avatar', headerName: 'Foto', width: 125, headerClassName: 'data-grid-header', renderCell: (params) => {
          return (
          <img src={params.row.avatar} style={{width: 85, height: 85, objectFit: 'cover', borderRadius: '100%'}} />
        ) }},
        { field: 'first_name', headerName: 'Nome', width: 200, headerClassName: 'data-grid-header' },
        { field: 'last_name', headerName: 'Cognome', width: 200, headerClassName: 'data-grid-header' },
        { field: 'status', headerName: 'Stato iscrizione', width: 200, headerClassName: 'data-grid-header' },
        { field: 'bocciato', headerName: 'Stato promozione', width: 200, headerClassName: 'data-grid-header', renderCell: (params) => params.row.bocciato ? 'Bocciato' : 'Promosso'},
        { field: 'documents', headerName: 'Documentazione', width: 200, headerClassName: 'data-grid-header', renderCell: (params) => (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={_handleClick}
            >
                  <MoreHoriz></MoreHoriz>
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  width: '20ch',
                },
              }}
            >
              {params.row.documents.map(x => (
                <MenuItem onClick={() => {
                  if(!x.file){
                    alert("L'utente deve caricare il PDF");
                    return;
                  }
                  window.open(x.file);
                }}>{x.name}</MenuItem>
              ))}
        
            </Menu>
          </div>
        )},
        { field: 'actions', headerName: 'Azioni', width: 225, headerClassName: 'data-grid-header', renderCell: (_params) => (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open2 ? 'long-menu' : undefined}
              aria-expanded={open2 ? 'true' : undefined}
              aria-haspopup="true"
              onClick={_handleClick2}
            >
                  <MoreHoriz></MoreHoriz>
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl2}
              open={open2}
              onClose={handleClose2}
              PaperProps={{
                style: {
                  width: '20ch',
                },
              }}
            >
              {_params.row.status !== 'CONFERMATO' ? <MenuItem onClick={async () => {
                const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/partecipants/${_params.row.id}/status/CONFERMATO`,
                {
                  method: 'PUT'
                })
                await getPartecipants();
              }}>
                Conferma Iscrizione
              </MenuItem> : null}
              <MenuItem onClick={() => handleClickOpen()}>Rivedi documentazione</MenuItem>
              <MenuItem onClick={() => handleClickDatesOpen()}>Sposta prenotazione</MenuItem>
              <MenuItem onClick={async() => {
                await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/prenotation/${_params.row.id}`);
                await getPartecipants()
              }}>Rimuovi partecipante</MenuItem>
              <MenuItem onClick={async() => {
                await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/partecipant/${_params.row.unique_id}/boccia`, {
                  method: 'PUT'
                });
                await getPartecipants()
              }}>Boccia/Promuovi</MenuItem>
            </Menu>
            <SimpleDialog
              open={dialogOpen}
              files={_params.row.documents}
              onClose={handleCloseDialog}
              params={_params}
            />
            <SimpleDialogDates
              open={dialogDatesOpen}
              onClose={handleCloseDatesDialog}
              partecipant={_params.row.id}
              params={
                {
                  id: params.id,
                }
              }/>
              
          {/* <Button onClick={async() => {
            const urlParams = new URLSearchParams(window.location.search);
            const course = urlParams.get('nome_corso');
            await savePdf(course, lessons, `${params.row.first_name} ${params.row.last_name}`);
          }}>Stampa Libretto</Button> */}
         
          {/* <Button disabled onClick={async() => {
          
          }}>Rivedi documentazione</Button> */}
          </div>
        )}
    ]

    const teacherColumns = [
      {field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header'},
      {field: 'teacher', headerName: 'Nome Docente', width: 125, headerClassName: 'data-grid-header'}
    ];
    const requestColumns = [
      {field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header'},
      {field: 'user', headerName: 'Nome', width: 125, headerClassName: 'data-grid-header', renderCell: (params) => `${partecipants.filter(x => x.id === params.row.user)[0].first_name} ${partecipants.filter(x => x.id === params.row.user)[0].last_name}`},
      {field: 'request', headerName: 'Richiesta', width: 125, headerClassName: 'data-grid-header'}
    ];
    const [partecipants, setPartecipants] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);

    const _handleClick2 = (event) => {
      setAnchorEl2(event.currentTarget);
    }
    const _handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose2 = () => {
      setAnchorEl2(null);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const getLessons = async() => {
        setLoading(true);
        const user = await AuthHelper.getUserFromIdToken();
        const res = await (await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/lessons/${params.id}`)).json();
        setLessons([...res]);
        setLoading(false);
    }
    const getPartecipants = async() => {
      const user = await AuthHelper.getUserFromIdToken();
      const res = await (await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/partecipants/${params.id}`)).json();
      setPartecipants([...res]);
      setLoading(false);
    }

    const [richiesteIscritti, setRichiesteIscritti] = useState([]);

    const getCourse = async() => {
      const course = await (await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/courses/detail/${params.id}`)).json();
    
      setCourse({...course, teachers:  course.teachers.map(x => ({
        teacher: x.teacher,
        id: Math.floor(Math.random() * 10)
      }))});
      setAutorita(course.course[0].autorita_marittima);
      setDataEsame(course.course[0].data_esame);
      setOraEsame(course.course[0].ora_esame);
      setSedeEsame(course.course[0].aula_esame);

      const requestInfo = await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/course/info/list`, {
        method: 'POST',
        body: JSON.stringify({
          courses: [`'${course.course[0]['id']}'`]
        })
      });
      setRichiesteIscritti([...(await requestInfo.json()).payload[0]]);
    }

    const [directors, setDirectors] = useState([]);

    const getDirettori = async() => {
      
      setLoading(true);
      const user = await AuthHelper.getUserFromIdToken();
      const res = await (await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/lessons/direttori/${params.id}`)).json();
      setDirectors([...res]);
      setLoading(false);
    }

    const getAule = async() => {
      const user = await AuthHelper.getUserFromIdToken();
      const urlParams = new URLSearchParams(window.location.search);
      const res = await (await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/aule/${user.Username}?course=${urlParams.get('id')}`)).json();
      setAule([...res]);
    }
    useEffect(() => {
        getLessons();
        getPartecipants();
        getCourse();
        getDirettori();
        getAule();
        store.dispatch(clear())

        const urlParams = new URLSearchParams(window.location.search);
        setTitle(`Corso ${urlParams.get("nome_corso")} | ${moment(urlParams.get('start')).format('DD/MM/YYYY')} - ${moment(urlParams.get('end')).format('DD/MM/YYYY')}`)
        builder('lessons.add');
    }, [])

    const handleClick = (data, name = null) => {
        store.dispatch(clear())
        builder('lessons.add');
        if(data.row){
           
            Object.keys(data.row).forEach((key) => {
                console.log(key)
                store.dispatch(setFieldState({name: key, value: data.row[key]}));
                if(key === 'date'){
                  store.dispatch(setFieldState({name: key, value: moment(data.row[key]).format('DD/MM/YYYY')}));
                }
                if(key === 'hour_ranges'){
                  store.dispatch(setFieldState({name: 'hour_ranges_from', value: data.row[key].split('-')[0]}));
                  store.dispatch(setFieldState({name: 'hour_ranges_to', value: data.row[key].split('-')[1]}));
                }
            });
        }
        const urlParams = new URLSearchParams(window.location.search);
        const course = urlParams.get('id');
        store.dispatch(setFieldState({name : 'course', value: course}))
        store.dispatch(setFieldState({
          name: 'school_course',
          value: params.id
        }))
        pushMobileOpen(true, 500, name ? name : "Modifica Corso", data.row);

    }

    const handleClickDirettore = (data, name = null) => {
      store.dispatch(clear())
      builder('direttori.assign');
      if(data.row){
          Object.keys(data.row).forEach((key) => {
              store.dispatch(setFieldState({name: key, value: data.row[key]}));
          });
      }
      const urlParams = new URLSearchParams(window.location.search);
      const course = urlParams.get('id');
      store.dispatch(setFieldState({name : 'course', value: course}))
      store.dispatch(setFieldState({
        name: 'course_id',
        value: params.id
      }))
      pushMobileOpen(true, 500, name ? name : "Modifica Corso", data.row);
    }
    
    const [loadingDelete, setLoadingDelete] = useState(false);

    const _delete = async() => {
      try{
        setLoadingDelete(true);
        const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/courses/${params.id}`,
        
        {
          method: 'DELETE'
        });
        window.location.href="/dashboard/corsi";
      }catch(ex){
        alert('Errore nel cancellare il corso');
      }
      

      
    }
    const handlePartecipantCreation = (data, name = null) => {
      store.dispatch(clear())
      builder('partecipants.add');
      if(data.row){
          Object.keys(data.row).forEach((key) => {
              store.dispatch(setFieldState({name: key, value: data.row[key]}));
          });
      }
      const urlParams = new URLSearchParams(window.location.search);
      const course = urlParams.get('id');
      console.log('COURSE', course)
      store.dispatch(setFieldState({name : 'course', value: course}))
      store.dispatch(setFieldState({
        name: 'course_id',
        value: params.id
      }))
      pushMobileOpen(true, 500, name ? name : "Modifica Corso", data.row);
    }
    const [selectedRow, setSelectedRows] = useState("")
    const options = {
        onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
          // 'rowsSelected' contiene gli indici delle righe selezionate
          setSelectedRows(rowsSelected);
          console.log('ciao');
        },
        selectableRows: 'single', // Permette la selezione di più righe, puoi usare anche 'single'
      };

    const setSegretario = async(target) => {
      setLoadingDelete(true);
      const res = await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/segretario/${params.id}`, {
        method: 'PUT',
        body: JSON.stringify({
          teacher: selectedRow
        })
      });
      setLoadingDelete(false);
    }
    const [openModal, setOpenModal] = useState(false);
    return (<Container sx={{paddingLeft: 24, paddingRight: 24, minWidth: '100vw', marginTop: '3rem'}}>
      <ModalDuplica open={openModal} setOpen={setOpenModal} id={params.id}/>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <h1 style={{textAlign: 'left'}}>{title}</h1>

      <div style={{width: '100%', display: 'flex', justifyContent: 'start', gap: 24, marginBottom: 24}}>
      <LoadingButton loading={loadingDelete} onClick={_delete} sx={{backgroundColor: '#B22222', color: '#fff', fontWeight: 'bold', paddingX: 4, paddingY: 1, borderRadius: 66, fontSize: 12}}>
        <Delete />
        Elimina corso
      </LoadingButton>
     
      <LoadingButton onClick={() => setOpenModal(true)} color="primary" sx={{backgroundColor: 'rgba(4,14,99,1)', color: '#fff', fontWeight: 'bold', paddingX: 4, paddingY: 1, borderRadius: 66, fontSize: 12}}>
        <CopyAll />
        Duplica corso
      </LoadingButton>
    </div>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <AntTab label="Lista lezioni" {...a11yProps(0)} />
          <AntTab label="Lista partecipanti" {...a11yProps(1)} />
          <AntTab label="Docenti corso" {...a11yProps(2)} />
          <AntTab label="Direttore corso" {...a11yProps(3)} />
          <AntTab label="Documenti corso" {...a11yProps(4)} />
          <AntTab label="Richieste iscritti" {...a11yProps(5)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {loading && <ListSkeleton />}
        {!loading && <DataTable data={lessons} columns={columns} handleClick={handleClick} actions={[
            {type: 'select', name: 'Azioni'}
        ]}/>}
        {!loading && <div style={{height: 700}}></div>}
        <FloatingActionButtons onClick={() => handleClick([], "Crea lezione")}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {loading && <ListSkeleton />}
        {!loading && <DataTable  rowHeight={95} data={partecipants} columns={partecipantsColumns} actions={[
            {type: 'select', name: 'Azioni'}
        ]}/>}
        {!loading && <div style={{height: 700}}></div>}
        {/* <FloatingActionButtons onClick={() => handleClick([], "Crea lezione")}/> */}
        <FloatingActionButtons onClick={() => handlePartecipantCreation([], "Crea partecipante")}/>

      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <LoadingButton loading={loadingDelete} disabled={selectedRow === ""} onClick={setSegretario} sx={{backgroundColor: '#222db2', color: '#fff', fontWeight: 'bold', paddingX: 4, paddingY: 1, borderRadius: 66, fontSize: 12, display: 'flex', mb: 5}}>
        Rendi segretario
      </LoadingButton>
        {loading && <ListSkeleton />}
        {!loading && <DataTable onSelectionChange={(e) => {
          console.log('selected',course?.teachers, e, course?.teachers.filter(x => x.id === e[0])[0]);
          setSelectedRows(course?.teachers.filter(x => x.id === e[0])[0].teacher)
          
        }} options={options} rowHeight={95} data={course?.teachers} columns={teacherColumns} handleClick={handleClick} actions={[
            {type: 'select', name: 'Azioni'}
        ]}/>}
        {!loading && <div style={{height: 700}}></div>}
        {/* <FloatingActionButtons onClick={() => handleClick([], "Crea lezione")}/> */}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <FloatingActionButtons onClick={() => handleClickDirettore([], "Aggiungi direttore corso")}/>
        {loading && <ListSkeleton />}
        {!loading && <DataTable rowHeight={95} data={directors} columns={direttoriColumns} actions={[
            {type: 'select', name: 'Azioni'}
        ]}/>}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>

        <div style={{display: 'flex', width: 800}}>
        <TextField size="small" fullWidth id="outlined-basic" label="Autorità marittima" variant="outlined" value={autorita} onChange={(e) => setAutorita(e.target.value)}/>
        <TextField size="small" fullWidth id="outlined-basic" label="Data esame" variant="outlined" value={dataEsame} onChange={(e) => setDataEsame(e.target.value)}/>
        <TextField size="small" fullWidth id="outlined-basic" label="Ora esame" variant="outlined" value={oraEsame} onChange={(e) => setOraEsame(e.target.value)}/>
        <TextField size="small" fullWidth id="outlined-basic" label="Fine Ora esame" variant="outlined" value={fineEsame} onChange={(e) => setFineEsame(e.target.value)}/>
        <FormControl size="small" fullWidth>
          <InputLabel id="demo-simple-select-label">Sede esame</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={sedeEsame}
            size="small"
            label="Sede Esame"
            onChange={(e) => setSedeEsame(e.target.value)}
          >
            {aule?.map(aula => (
              <MenuItem value={aula.id}>
                {aula.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        
        <Button onClick={async () => {
            await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/lessons/autorita/${params.id}`, {
              method: 'PUT',
              body: JSON.stringify({text: autorita, data_esame: dataEsame, ora_esame: oraEsame, fine_esame: fineEsame, sede: sedeEsame})
            });
            alert('OK');
          }}>Salva</Button>
        </div>

        <h2 style={{textAlign: 'left'}}>Lista documenti corso</h2>
        
        <ul>
          {/* {course?.course?.length > 0 ? documenti.filter(x => course?.course[0][x]).map(x => (
            <li style={{textAlign: 'left'}}><Button className="disabledButton" disabled href="#" sx={[{pointerEvents: 'none', '&:hover': {
              cursor: 'not-allowed'
            }}]}>{x.replaceAll('_', ' ').charAt(0).toUpperCase() + x.replaceAll('_', ' ').slice(1).toLowerCase()}</Button></li>
          )) : null} */}
          <li style={{textAlign: 'left'}}>
            <Button href="#" onClick={async () => {
              try{
                const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${params.id}`);
                const payload = await res.json();
                await saveCertificato(payload);
              }catch(ex){
                alert("Il corso oppure il profilo della scuola non sono completati. Impossibile stampare il documento.")
              }
               
          }}>Attestati corso</Button>
            </li>
            <li style={{textAlign: 'left'}}>
            <Button href="#" onClick={async () => {
              try{
                const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${params.id}`);
                const payload = await res.json();
                const groupedByDate = lessons.reduce((acc, item) => {
                 const { date } = item;
                 acc[date] = acc[date] || [];
                 acc[date].push(item);
                 return acc;
                 }, {});
                 console.log(groupedByDate);
               await saveRegistro({lessons: lessons, groupedByDate,  ...payload});
              }catch(ex){
                alert("Il corso oppure il profilo della scuola non sono completati. Impossibile stampare il documento.")
              }
               

          }}>Registro Presenze</Button>
            </li>
            <li style={{textAlign: 'left'}}>
            <Button href="#" onClick={async () => {
              try{
                const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${params.id}`);
                const payload = await res.json();
                await saveVerbale(payload);
              }catch(ex){
                alert("Il corso oppure il profilo della scuola non sono completati. Impossibile stampare il documento.")
              }
              
          }}>Verbale esame</Button>
            </li>
            <li style={{textAlign: 'left'}}>
            <Button href="#" onClick={async () => {
              try{
                const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${params.id}`);
                const payload = await res.json();
                await saveRegistroEsame(payload);
              }catch(ex){
                alert("Il corso oppure il profilo della scuola non sono completati. Impossibile stampare il documento.")
              }
              
          }}>Registro Presenze Esame</Button>
            </li>
            <li style={{textAlign: 'left'}}>
            <Button href="#" onClick={async () => {
              try{
                const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${params.id}`);
                const payload = await res.json();
                await saveElenco(payload);
              }catch(ex){
                alert("Il corso oppure il profilo della scuola non sono completati. Impossibile stampare il documento.")
              }
              
          }}>Elenco partecipanti</Button>
            </li>
            <li style={{textAlign: 'left'}}>
            <Button href="#" onClick={async () => {
              try{
                const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${params.id}`);
                const payload = await res.json();
                console.log(payload);
                await saveLettera({...payload, number: payload.partecipant.length});
              }catch(ex){
                alert("Il corso oppure il profilo della scuola non sono completati. Impossibile stampare il documento.")
              }
              
          }}>Lettera conferma sessione esami</Button>
            </li>
            <li style={{textAlign: 'left'}}>
            <Button href="#" onClick={async () => {
              try{
                const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${params.id}`);
                const payload = await res.json();
                await saveTest({...payload, number: payload.partecipant.length});
              }catch(ex){
                alert("Il corso oppure il profilo della scuola non sono completati. Impossibile stampare il documento.")
              }
              
          }}>Batteria Test Corso A</Button>
            </li>
            <li style={{textAlign: 'left'}}>
                <Button href="#" onClick={async () => {
                  try{
                    const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${params.id}`);
                    const payload = await res.json();
                    await saveTestB({...payload, number: payload.partecipant.length});
                  }catch(ex){
                    alert("Il corso oppure il profilo della scuola non sono completati. Impossibile stampare il documento.")
                  }
                  
              }}>Batteria Test Corso B</Button>
            </li>
            <li style={{textAlign: 'left'}}>
                <Button href="#" onClick={async () => {
                  try{
                    const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${params.id}`);
                    const payload = await res.json();
                    await saveTestC({...payload, number: payload.partecipant.length});
                  }catch(ex){
                    alert("Il corso oppure il profilo della scuola non sono completati. Impossibile stampare il documento.")
                  }
                  
              }}>Batteria Test Corso C</Button>
            </li>
            <li style={{textAlign: 'left'}}>
            <Button href="#" onClick={async () => {
              try{
                const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${params.id}`);
                const payload = await res.json();
                const groupedByDate = lessons.reduce((acc, item) => {
                  const { date } = item;
                  acc[date] = acc[date] || [];
                  acc[date].push(item);
                  return acc;
                  }, {});
                await saveLibretto({...payload, groupedByDate, lessons: lessons, partecipants});
              }catch(ex){
                alert("Il corso oppure il profilo della scuola non sono completati. Impossibile stampare il documento.")
              }
              
          }}>Libretto personale del corsista</Button>
            </li>
            <li style={{textAlign: 'left'}}>
            <Button href="#" onClick={async () => {
              try{
                const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${params.id}`);
                const payload = await res.json();
                await saveProgrammazione({...payload, lessons: lessons, partecipants});
              }catch(ex){
                alert("Il corso oppure il profilo della scuola non sono completati. Impossibile stampare il documento.")
              }
              
          }}>Programmazione corso</Button>
            </li>
            <li style={{textAlign: 'left'}}>
              <Button href="#" onClick={async () => {
                try{
                  const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${params.id}`);
                  const payload = await res.json();
                  await saveLetteraLicenza({...payload, lessons: lessons, partecipants});
                }catch(ex){
                  alert("Il corso oppure il profilo della scuola non sono completati. Impossibile stampare il documento.")
                }
                
            }}>Lettera licenza</Button>
            </li>
            <li style={{textAlign: 'left'}}>
              <Button href="#" onClick={async () => {
                try{
                  const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${params.id}`);
                  const payload = await res.json();
                  await saveNullaOsta({...payload, lessons: lessons, partecipants});
                }catch(ex){
                  alert("Il corso oppure il profilo della scuola non sono completati. Impossibile stampare il documento.")
                }
                
            }}>Nulla Osta oltre 9 Ore</Button>
            </li>
            <li style={{textAlign: 'left'}}>
              <Button href="#" onClick={async () => {
                try{
                  const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${params.id}`);
                  const payload = await res.json();
                  await save18({...payload, lessons: lessons, partecipants});
                }catch(ex){
                  alert("Il corso oppure il profilo della scuola non sono completati. Impossibile stampare il documento.")
                }
               
            }}>Nulla Osta oltre 18:30</Button>
            </li>
            <li style={{textAlign: 'left'}}>
              <Button href="#" onClick={async () => {
                try{
                  const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${params.id}`);
                  const payload = await res.json();
                  await saveCambio({...payload, lessons: lessons, partecipants});
                }catch(ex){
                  alert("Il corso oppure il profilo della scuola non sono completati. Impossibile stampare il documento.")
                }
                
            }}>Cambio Docente</Button>
            </li>
            <li style={{textAlign: 'left'}}>
              <Button href="#" onClick={async () => {
                try{
                  const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${params.id}`);
                  const payload = await res.json();
                  await saveCambio({...payload, lessons: lessons, partecipants});
                }catch(ex){
                  alert("Il corso oppure il profilo della scuola non sono completati. Impossibile stampare il documento.")
                }
                
            }}>Cambio Docente</Button>
            </li>
            <li style={{textAlign: 'left'}}>
              <Button href="#" onClick={async () => {
                try{
                  const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${params.id}`);
                  const payload = await res.json();
                  const base64Progr = await ProgrammazionePDF({...payload, lessons: lessons, partecipants});
                  const base64Lettr = await LetteraPDF({...payload, lessons, partecipants});
                  const base64Elenco = await ElencoPDF({...payload, lessons, partecipants});
                  
                  const user = await AuthHelper.getUserFromIdToken();
                  await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/email`, {
                    method: 'POST',
                    body: JSON.stringify({
                      base64Progr,
                      base64Lettr,
                      base64Elenco,
                      school: user.Username
                    })
                  })
                }catch(ex){
                  alert("Il corso oppure il profilo della scuola non sono completati. Impossibile stampare il documento.")
                }
                
                
            }}>Invia PEC Test</Button>
            </li>
          </ul>
        
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        {loading && <ListSkeleton />}
        {!loading && <DataTable rowHeight={95} data={richiesteIscritti} columns={requestColumns} actions={[
            {type: 'select', name: 'Azioni'}
        ]}/>}
      </CustomTabPanel>
    </Container>);
}

export default CourseDetail;