import React from 'react';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';

const SingleRow = (partecipant) =>`
        <tr style="border: 1px solid black; border-collapse:collapse">
            <td style="width: 100px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{id_matricola}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{last_name}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{first_name}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birth_place}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birth_province}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birthday}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{matricola}/{compartment}</td>
        </tr>
        `
const Libretto = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
      @import url(https://themes.googleusercontent.com/fonts/css?kit=DtPsxHYDxQWpO8ASIGu_ew0oAUP_eRtG2f1I1nZF09YS0-eybaQSkqpYUibASASI_37GXcCmZhAvVrsFphImmA);

      ol.lst-kix_list_7-0 {
        list-style-type: none
      }

      .lst-kix_list_14-1>li:before {
        content: "o  "
      }

      .lst-kix_list_14-3>li:before {
        content: "\x025cf   "
      }

      ul.lst-kix_list_1-0 {
        list-style-type: none
      }

      ol.lst-kix_list_9-0.start {
        counter-reset: lst-ctn-kix_list_9-0 0
      }

      .lst-kix_list_14-0>li:before {
        content: "\x02022   "
      }

      .lst-kix_list_14-4>li:before {
        content: "o  "
      }

      .lst-kix_list_6-1>li {
        counter-increment: lst-ctn-kix_list_6-1
      }

      .lst-kix_list_8-1>li {
        counter-increment: lst-ctn-kix_list_8-1
      }

      .lst-kix_list_14-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_14-7>li:before {
        content: "o  "
      }

      ol.lst-kix_list_13-4.start {
        counter-reset: lst-ctn-kix_list_13-4 0
      }

      .lst-kix_list_14-6>li:before {
        content: "\x025cf   "
      }

      ul.lst-kix_list_9-3 {
        list-style-type: none
      }

      ul.lst-kix_list_9-4 {
        list-style-type: none
      }

      ul.lst-kix_list_9-2 {
        list-style-type: none
      }

      ul.lst-kix_list_9-7 {
        list-style-type: none
      }

      ul.lst-kix_list_9-8 {
        list-style-type: none
      }

      ul.lst-kix_list_9-5 {
        list-style-type: none
      }

      .lst-kix_list_7-0>li {
        counter-increment: lst-ctn-kix_list_7-0
      }

      .lst-kix_list_9-0>li {
        counter-increment: lst-ctn-kix_list_9-0
      }

      ul.lst-kix_list_9-6 {
        list-style-type: none
      }

      ul.lst-kix_list_1-3 {
        list-style-type: none
      }

      ul.lst-kix_list_1-4 {
        list-style-type: none
      }

      ul.lst-kix_list_1-1 {
        list-style-type: none
      }

      ul.lst-kix_list_1-2 {
        list-style-type: none
      }

      ul.lst-kix_list_1-7 {
        list-style-type: none
      }

      ul.lst-kix_list_1-8 {
        list-style-type: none
      }

      ul.lst-kix_list_1-5 {
        list-style-type: none
      }

      .lst-kix_list_14-2>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_1-6 {
        list-style-type: none
      }

      .lst-kix_list_14-8>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_5-0>li:before {
        content: "\x025a1   "
      }

      ol.lst-kix_list_6-0 {
        list-style-type: none
      }

      ol.lst-kix_list_6-1 {
        list-style-type: none
      }

      .lst-kix_list_5-3>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_13-2>li {
        counter-increment: lst-ctn-kix_list_13-2
      }

      .lst-kix_list_5-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_5-1>li:before {
        content: "o  "
      }

      .lst-kix_list_5-7>li:before {
        content: "o  "
      }

      ul.lst-kix_list_8-4 {
        list-style-type: none
      }

      ul.lst-kix_list_8-5 {
        list-style-type: none
      }

      .lst-kix_list_5-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_5-8>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_8-2 {
        list-style-type: none
      }

      ul.lst-kix_list_8-3 {
        list-style-type: none
      }

      ul.lst-kix_list_8-8 {
        list-style-type: none
      }

      ul.lst-kix_list_8-6 {
        list-style-type: none
      }

      ul.lst-kix_list_8-7 {
        list-style-type: none
      }

      .lst-kix_list_5-4>li:before {
        content: "o  "
      }

      .lst-kix_list_5-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_6-1>li:before {
        content: ""counter(lst-ctn-kix_list_6-1, lower-latin) ") "
      }

      .lst-kix_list_6-3>li:before {
        content: "\x025cf   "
      }

      ol.lst-kix_list_13-3.start {
        counter-reset: lst-ctn-kix_list_13-3 0
      }

      .lst-kix_list_6-0>li:before {
        content: ""counter(lst-ctn-kix_list_6-0, lower-latin) ") "
      }

      .lst-kix_list_6-4>li:before {
        content: "o  "
      }

      .lst-kix_list_3-0>li {
        counter-increment: lst-ctn-kix_list_3-0
      }

      .lst-kix_list_6-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_6-8>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_6-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_6-7>li:before {
        content: "o  "
      }

      .lst-kix_list_6-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_2-7>li:before {
        content: "o  "
      }

      .lst-kix_list_7-4>li:before {
        content: "o  "
      }

      .lst-kix_list_7-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_2-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_7-2>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_3-7 {
        list-style-type: none
      }

      ul.lst-kix_list_3-8 {
        list-style-type: none
      }

      .lst-kix_list_10-1>li:before {
        content: "o  "
      }

      .lst-kix_list_13-7>li:before {
        content: ""counter(lst-ctn-kix_list_13-7, lower-latin) ". "
      }

      ol.lst-kix_list_3-0.start {
        counter-reset: lst-ctn-kix_list_3-0 0
      }

      ul.lst-kix_list_3-1 {
        list-style-type: none
      }

      ul.lst-kix_list_3-2 {
        list-style-type: none
      }

      .lst-kix_list_7-8>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_3-5 {
        list-style-type: none
      }

      ol.lst-kix_list_9-0 {
        list-style-type: none
      }

      ul.lst-kix_list_3-6 {
        list-style-type: none
      }

      ol.lst-kix_list_9-1 {
        list-style-type: none
      }

      ul.lst-kix_list_3-3 {
        list-style-type: none
      }

      ul.lst-kix_list_3-4 {
        list-style-type: none
      }

      .lst-kix_list_10-7>li:before {
        content: "o  "
      }

      .lst-kix_list_10-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_13-4>li {
        counter-increment: lst-ctn-kix_list_13-4
      }

      ol.lst-kix_list_13-5.start {
        counter-reset: lst-ctn-kix_list_13-5 0
      }

      .lst-kix_list_10-3>li:before {
        content: "\x025cf   "
      }

      ul.lst-kix_list_11-7 {
        list-style-type: none
      }

      ul.lst-kix_list_11-6 {
        list-style-type: none
      }

      .lst-kix_list_4-1>li:before {
        content: "o  "
      }

      ul.lst-kix_list_11-5 {
        list-style-type: none
      }

      ul.lst-kix_list_11-4 {
        list-style-type: none
      }

      ul.lst-kix_list_11-3 {
        list-style-type: none
      }

      ol.lst-kix_list_13-8.start {
        counter-reset: lst-ctn-kix_list_13-8 0
      }

      ul.lst-kix_list_11-2 {
        list-style-type: none
      }

      ul.lst-kix_list_11-1 {
        list-style-type: none
      }

      ul.lst-kix_list_11-0 {
        list-style-type: none
      }

      .lst-kix_list_9-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_4-3>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_4-5>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_11-8 {
        list-style-type: none
      }

      .lst-kix_list_9-0>li:before {
        content: ""counter(lst-ctn-kix_list_9-0, lower-latin) ") "
      }

      .lst-kix_list_9-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_9-4>li:before {
        content: "o  "
      }

      .lst-kix_list_11-3>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_2-8 {
        list-style-type: none
      }

      .lst-kix_list_12-3>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_11-5>li:before {
        content: "\x025cf   "
      }

      ol.lst-kix_list_6-1.start {
        counter-reset: lst-ctn-kix_list_6-1 0
      }

      .lst-kix_list_12-1>li:before {
        content: "o  "
      }

      ul.lst-kix_list_2-2 {
        list-style-type: none
      }

      ul.lst-kix_list_2-3 {
        list-style-type: none
      }

      ul.lst-kix_list_2-0 {
        list-style-type: none
      }

      ul.lst-kix_list_2-1 {
        list-style-type: none
      }

      ol.lst-kix_list_8-0 {
        list-style-type: none
      }

      .lst-kix_list_9-8>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_2-6 {
        list-style-type: none
      }

      ol.lst-kix_list_8-1 {
        list-style-type: none
      }

      .lst-kix_list_1-1>li:before {
        content: "o  "
      }

      ul.lst-kix_list_2-7 {
        list-style-type: none
      }

      .lst-kix_list_11-7>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_13-3>li {
        counter-increment: lst-ctn-kix_list_13-3
      }

      ol.lst-kix_list_13-6.start {
        counter-reset: lst-ctn-kix_list_13-6 0
      }

      ul.lst-kix_list_2-4 {
        list-style-type: none
      }

      ul.lst-kix_list_2-5 {
        list-style-type: none
      }

      ul.lst-kix_list_10-0 {
        list-style-type: none
      }

      .lst-kix_list_1-3>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_13-3>li:before {
        content: ""counter(lst-ctn-kix_list_13-3, decimal) ". "
      }

      ul.lst-kix_list_10-8 {
        list-style-type: none
      }

      ul.lst-kix_list_10-7 {
        list-style-type: none
      }

      .lst-kix_list_1-7>li:before {
        content: "o  "
      }

      ul.lst-kix_list_10-6 {
        list-style-type: none
      }

      ul.lst-kix_list_10-5 {
        list-style-type: none
      }

      ul.lst-kix_list_10-4 {
        list-style-type: none
      }

      ul.lst-kix_list_10-3 {
        list-style-type: none
      }

      .lst-kix_list_1-5>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_10-2 {
        list-style-type: none
      }

      ul.lst-kix_list_10-1 {
        list-style-type: none
      }

      ol.lst-kix_list_9-1.start {
        counter-reset: lst-ctn-kix_list_9-1 0
      }

      .lst-kix_list_13-5>li:before {
        content: ""counter(lst-ctn-kix_list_13-5, lower-roman) ". "
      }

      .lst-kix_list_12-5>li:before {
        content: "\x025aa   "
      }

      ol.lst-kix_list_13-7.start {
        counter-reset: lst-ctn-kix_list_13-7 0
      }

      .lst-kix_list_12-7>li:before {
        content: "o  "
      }

      .lst-kix_list_2-1>li:before {
        content: "o  "
      }

      ol.lst-kix_list_6-0.start {
        counter-reset: lst-ctn-kix_list_6-0 0
      }

      .lst-kix_list_2-3>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_13-1>li:before {
        content: ""counter(lst-ctn-kix_list_13-1, lower-latin) ". "
      }

      ol.lst-kix_list_3-0 {
        list-style-type: none
      }

      .lst-kix_list_9-1>li {
        counter-increment: lst-ctn-kix_list_9-1
      }

      .lst-kix_list_3-0>li:before {
        content: ""counter(lst-ctn-kix_list_3-0, lower-latin) ") "
      }

      ul.lst-kix_list_5-7 {
        list-style-type: none
      }

      ol.lst-kix_list_13-1.start {
        counter-reset: lst-ctn-kix_list_13-1 0
      }

      ul.lst-kix_list_5-8 {
        list-style-type: none
      }

      .lst-kix_list_3-1>li:before {
        content: "o  "
      }

      .lst-kix_list_3-2>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_5-5 {
        list-style-type: none
      }

      ul.lst-kix_list_5-6 {
        list-style-type: none
      }

      .lst-kix_list_8-1>li:before {
        content: ""counter(lst-ctn-kix_list_8-1, lower-latin) ") "
      }

      .lst-kix_list_8-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_6-0>li {
        counter-increment: lst-ctn-kix_list_6-0
      }

      .lst-kix_list_8-0>li {
        counter-increment: lst-ctn-kix_list_8-0
      }

      .lst-kix_list_3-5>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_5-0 {
        list-style-type: none
      }

      .lst-kix_list_3-4>li:before {
        content: "o  "
      }

      ul.lst-kix_list_5-3 {
        list-style-type: none
      }

      .lst-kix_list_3-3>li:before {
        content: "\x025cf   "
      }

      ul.lst-kix_list_5-4 {
        list-style-type: none
      }

      ul.lst-kix_list_5-1 {
        list-style-type: none
      }

      .lst-kix_list_8-0>li:before {
        content: ""counter(lst-ctn-kix_list_8-0, lower-latin) ") "
      }

      ul.lst-kix_list_5-2 {
        list-style-type: none
      }

      .lst-kix_list_8-7>li:before {
        content: "o  "
      }

      .lst-kix_list_3-8>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_8-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_8-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_13-1>li {
        counter-increment: lst-ctn-kix_list_13-1
      }

      .lst-kix_list_8-3>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_3-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_3-7>li:before {
        content: "o  "
      }

      ul.lst-kix_list_13-0 {
        list-style-type: none
      }

      .lst-kix_list_8-4>li:before {
        content: "o  "
      }

      .lst-kix_list_11-2>li:before {
        content: "o  "
      }

      .lst-kix_list_11-1>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_11-0>li:before {
        content: "  "
      }

      .lst-kix_list_8-8>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_4-8>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_4-7>li:before {
        content: "o  "
      }

      ul.lst-kix_list_4-8 {
        list-style-type: none
      }

      ul.lst-kix_list_4-6 {
        list-style-type: none
      }

      ul.lst-kix_list_4-7 {
        list-style-type: none
      }

      ul.lst-kix_list_4-0 {
        list-style-type: none
      }

      ul.lst-kix_list_4-1 {
        list-style-type: none
      }

      ul.lst-kix_list_4-4 {
        list-style-type: none
      }

      ul.lst-kix_list_4-5 {
        list-style-type: none
      }

      ul.lst-kix_list_4-2 {
        list-style-type: none
      }

      ul.lst-kix_list_4-3 {
        list-style-type: none
      }

      ul.lst-kix_list_12-6 {
        list-style-type: none
      }

      ul.lst-kix_list_12-5 {
        list-style-type: none
      }

      ul.lst-kix_list_12-4 {
        list-style-type: none
      }

      ul.lst-kix_list_12-3 {
        list-style-type: none
      }

      ul.lst-kix_list_12-2 {
        list-style-type: none
      }

      ul.lst-kix_list_12-1 {
        list-style-type: none
      }

      ul.lst-kix_list_12-0 {
        list-style-type: none
      }

      ul.lst-kix_list_12-8 {
        list-style-type: none
      }

      ul.lst-kix_list_12-7 {
        list-style-type: none
      }

      ol.lst-kix_list_8-0.start {
        counter-reset: lst-ctn-kix_list_8-0 0
      }

      .lst-kix_list_7-0>li:before {
        content: ""counter(lst-ctn-kix_list_7-0, lower-latin) ") "
      }

      .lst-kix_list_13-8>li {
        counter-increment: lst-ctn-kix_list_13-8
      }

      .lst-kix_list_2-6>li:before {
        content: "\x025cf   "
      }

      ol.lst-kix_list_13-8 {
        list-style-type: none
      }

      .lst-kix_list_2-4>li:before {
        content: "o  "
      }

      .lst-kix_list_2-8>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_7-1>li:before {
        content: "\x025a1   "
      }

      .lst-kix_list_7-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_13-5>li {
        counter-increment: lst-ctn-kix_list_13-5
      }

      ol.lst-kix_list_13-4 {
        list-style-type: none
      }

      ol.lst-kix_list_13-5 {
        list-style-type: none
      }

      ol.lst-kix_list_13-6 {
        list-style-type: none
      }

      ol.lst-kix_list_13-7 {
        list-style-type: none
      }

      ol.lst-kix_list_13-1 {
        list-style-type: none
      }

      ol.lst-kix_list_13-2 {
        list-style-type: none
      }

      .lst-kix_list_7-3>li:before {
        content: "\x025cf   "
      }

      ol.lst-kix_list_13-3 {
        list-style-type: none
      }

      ul.lst-kix_list_7-5 {
        list-style-type: none
      }

      .lst-kix_list_10-0>li:before {
        content: "\x02b9a   "
      }

      ul.lst-kix_list_7-6 {
        list-style-type: none
      }

      ul.lst-kix_list_7-3 {
        list-style-type: none
      }

      ul.lst-kix_list_7-4 {
        list-style-type: none
      }

      .lst-kix_list_13-6>li:before {
        content: ""counter(lst-ctn-kix_list_13-6, decimal) ". "
      }

      .lst-kix_list_13-6>li {
        counter-increment: lst-ctn-kix_list_13-6
      }

      .lst-kix_list_13-8>li:before {
        content: ""counter(lst-ctn-kix_list_13-8, lower-roman) ". "
      }

      ul.lst-kix_list_7-7 {
        list-style-type: none
      }

      ul.lst-kix_list_7-8 {
        list-style-type: none
      }

      ul.lst-kix_list_7-1 {
        list-style-type: none
      }

      ul.lst-kix_list_7-2 {
        list-style-type: none
      }

      .lst-kix_list_7-7>li:before {
        content: "o  "
      }

      ol.lst-kix_list_8-1.start {
        counter-reset: lst-ctn-kix_list_8-1 0
      }

      .lst-kix_list_10-4>li:before {
        content: "o  "
      }

      .lst-kix_list_10-8>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_4-0>li:before {
        content: "\x025a1   "
      }

      .lst-kix_list_10-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_13-7>li {
        counter-increment: lst-ctn-kix_list_13-7
      }

      .lst-kix_list_4-4>li:before {
        content: "o  "
      }

      .lst-kix_list_4-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_4-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_9-3>li:before {
        content: "\x025cf   "
      }

      ol.lst-kix_list_7-0.start {
        counter-reset: lst-ctn-kix_list_7-0 0
      }

      ol.lst-kix_list_13-2.start {
        counter-reset: lst-ctn-kix_list_13-2 0
      }

      .lst-kix_list_10-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_9-1>li:before {
        content: ""counter(lst-ctn-kix_list_9-1, lower-latin) ") "
      }

      .lst-kix_list_9-7>li:before {
        content: "o  "
      }

      .lst-kix_list_11-4>li:before {
        content: "\x1f8ad   "
      }

      .lst-kix_list_12-4>li:before {
        content: "o  "
      }

      .lst-kix_list_9-5>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_6-6 {
        list-style-type: none
      }

      ul.lst-kix_list_6-7 {
        list-style-type: none
      }

      ul.lst-kix_list_6-4 {
        list-style-type: none
      }

      ul.lst-kix_list_6-5 {
        list-style-type: none
      }

      ul.lst-kix_list_6-8 {
        list-style-type: none
      }

      .lst-kix_list_12-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_11-6>li:before {
        content: "o  "
      }

      .lst-kix_list_1-0>li:before {
        content: "\x025a1   "
      }

      ul.lst-kix_list_6-2 {
        list-style-type: none
      }

      .lst-kix_list_11-8>li:before {
        content: "\x1f8ad   "
      }

      ul.lst-kix_list_6-3 {
        list-style-type: none
      }

      .lst-kix_list_1-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_12-0>li:before {
        content: "-  "
      }

      .lst-kix_list_1-4>li:before {
        content: "o  "
      }

      .lst-kix_list_13-0>li:before {
        content: "\x02022   "
      }

      ul.lst-kix_list_14-4 {
        list-style-type: none
      }

      ul.lst-kix_list_14-3 {
        list-style-type: none
      }

      ul.lst-kix_list_14-2 {
        list-style-type: none
      }

      .lst-kix_list_13-4>li:before {
        content: ""counter(lst-ctn-kix_list_13-4, lower-latin) ". "
      }

      ul.lst-kix_list_14-1 {
        list-style-type: none
      }

      ul.lst-kix_list_14-0 {
        list-style-type: none
      }

      .lst-kix_list_1-6>li:before {
        content: "\x025cf   "
      }

      ul.lst-kix_list_14-8 {
        list-style-type: none
      }

      ul.lst-kix_list_14-7 {
        list-style-type: none
      }

      .lst-kix_list_2-0>li:before {
        content: "\x025a1   "
      }

      .lst-kix_list_12-6>li:before {
        content: "\x025cf   "
      }

      ul.lst-kix_list_14-6 {
        list-style-type: none
      }

      ul.lst-kix_list_14-5 {
        list-style-type: none
      }

      .lst-kix_list_1-8>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_2-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_13-2>li:before {
        content: ""counter(lst-ctn-kix_list_13-2, lower-roman) ". "
      }

      .lst-kix_list_12-8>li:before {
        content: "\x025aa   "
      }

      ol {
        margin: 0;
        padding: 0
      }

      table td,
      table th {
        padding: 0
      }

      .c26 {
        -webkit-text-decoration-skip: none;
        color: #0000ff;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 12pt;
        font-family: "Arial";
        font-style: normal
      }

      .c38 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 700;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 8pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c1 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 700;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 14pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c6 {
        -webkit-text-decoration-skip: none;
        color: #0000ff;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c24 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 700;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 11pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c2 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 5.5pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c35 {
        -webkit-text-decoration-skip: none;
        color: #03308b;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 8pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c20 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      .c19 {
        padding-top: 0pt;
        padding-bottom: 10pt;
        line-height: 1.1500000000000001;
        orphans: 2;
        widows: 2;
        text-align: justify
      }

      .c17 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      .c9 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      .c7 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 7pt;
        font-family: "Arial";
        font-style: normal
      }

      .c13 {
        padding-top: 0pt;
        padding-bottom: 10pt;
        line-height: 1.1500000000000001;
        orphans: 2;
        widows: 2;
        text-align: justify
      }

      .c30 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c5 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c16 {
        color: #000080;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c25 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c3 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Arial";
        font-style: normal
      }

      .c27 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Arial";
        font-style: italic
      }

      .c11 {
        color: #000080;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c18 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 9pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c21 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 10pt
      }

      .c12 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: center;
        height: 10pt
      }

      .c10 {
        color: #002060;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6pt;
        font-family: "Arial Black";
        font-style: normal
      }

      .c22 {
        color: #002060;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Arial Black";
        font-style: normal
      }

      .c37 {
        color: #0000ff;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c15 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: center
      }

      .c33 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.5;
        text-align: justify
      }

      .c29 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      .c14 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: justify
      }

      .c36 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: right
      }

      .c23 {
      }

      .c8 {
        max-width: 467.8pt;
        padding: 51pt 70.8pt 56.7pt 56.7pt
      }

      .c0 {
        color: inherit;
        text-decoration: inherit
      }

      .c34 {
      }

      .c31 {
        orphans: 2;
        widows: 2
      }

      .c28 {
        height: 10pt
      }

      .c4 {
        background-color: #ffffff
      }

      .c32 {
        margin-right: -1.4pt
      }

      .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1.0;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      li {
        color: #000000;
        font-size: 10pt;
        font-family: "Times New Roman"
      }

      p {
        margin: 0;
        color: #000000;
        font-size: 10pt;
        font-family: "Times New Roman"
      }

      h1 {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 24pt;
        padding-bottom: 6pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h2 {
        padding-top: 18pt;
        color: #000000;
        font-weight: 700;
        font-size: 18pt;
        padding-bottom: 4pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h3 {
        padding-top: 14pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h4 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 2pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h5 {
        padding-top: 11pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 2pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h6 {
        padding-top: 10pt;
        color: #000000;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }
    </style>
  </head>
  <body class="c4 c8 doc-content">
    <div>
      <div style="width: 100%; display: block; margin: 0 auto; text-align:center">
            <img src="{logo}" style="width: 150px" crossorigin="*"/>
        </div>
    </div>
    <div style="float:right; width:100%;text-align: right">
         <strong>{intestazione_capitaneria}
    </strong></div>
   
   
    <p class="c21 c23">
      <span class="c3"></span>
    </p>
    <p class="c21">
      <span class="c3"></span>
    </p>
    <p class="c29 c32">
      <strong class="c3" style="font-weight: bold">Argomento: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Comunicazione sostituzione del corpo istruttori – cambio aula tra quelli/e già Autorizzati/e per il corso “{nome_corso}”</strong>
    </p>
    <p class="c13">
      <span class="c3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </p>
    <p class="c19">
      <span class="c3">Riferimento Decreto Direttoriale n. _______ del ________ (Orario giornaliero delle lezioni)</span>
    </p>
    <p class="c31 c33">
      <span class="c3">Il sottoscritto ____________ in qualità di Legale Rappresentante/Direttore del </span>
      <span class="c27">Corso “{nome_corso}”</span>
      <span class="c3">&nbsp;chiede la sostituzione:</span>
    </p>
    <ul>
    <li><span class="c3">dell’istruttore (_________________) autorizzato per il corso in argomento in qualità di (_________________) giusta decreto di riconoscimento n. ____ del __________ - Allegato “A” -revisione ___ con l’istruttore (__________________) autorizzato per il corso {nome_corso} avente lo stesso profilo professione dell’istruttore ______________________ per la seguente motivazione___________________________________________________
    </span></li>
    <li style="margin-top: 10pt"><span class="c3">dell’aula denominata “_________________________” autorizzata per il corso in argomento giusta decreto di riconoscimento n. ____ del _______ __________ con l’aula denominata “___________________________” già autorizzata per lo stesso corso “{nome_corso}”, per la seguente motivazione_______________________________________________</span>
    </ul>
    <p class="c19">
      <span class="c3">Si precisa, altresì che l’aula è dotata di tutte strutture, equipaggiamento, attrezzature e materiale didattico richiesti per lo svolgimento del corso in argomento.</span>
    </p>
    <p class="c13 c28">
      <span class="c3"></span>
    </p>
    <p class="c14">
      <span class="c3">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Il Legale Rappresentante/Il Direttore del corso</span>
    </p>
    <p class="c14">
      <span class="c3">&nbsp;</span>
    </p>
    <p class="c14">
      <span class="c3">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ___________________________ </span>
    </p>
    <p class="c29">
      <span class="c7">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; (Timbro - firma autografa leggibile e per esteso)</span>
    </p>
    <div style="margin-top: 120px">
        <p style="margin: 0 auto; padding-top: 10px; text-align: center; font-size: 10px">{school_name} SRL, {legal_address} - Tel. {phone} P.IVA: {piva} <br/> Sito web: {website}</p>
    </div>
  </body>
</html>`
function _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
       binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }
const savePdf = async (payload) => {
    const logo = await fetch('https://mase-storage.s3.eu-west-1.amazonaws.com/1716223770927.document-file.jpeg');
    
    // const mappa = lessons.map((x, i) => singleCourseTemplate.replaceAll('{no}', i + 1).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    // const practicalMap = lessons.filter(x => x.pratica).map((x, i) => Practical.replaceAll('{name}', `Lezione nr. ${i + 1}`).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    html2pdf()
    .set({
        filename: 'scheda_ammissione.pdf',
        margin: 20,
        html2canvas: { useCORS: true },
    })
    .from(Libretto.replaceAll('{docente}', payload.teacher)
            .replaceAll('{intestazione_capitaneria}', payload.settings.capitaneria.replaceAll('\\n', '<br/>'))
            .replaceAll('{direttore}', payload.director)
            .replaceAll('{nome_corso}', payload.nome_corso)
            .replaceAll('{logo}', payload.settings?.logo_top ? payload.settings?.logo_top : payload.school.logo)
            .replaceAll('{autorita_marittima}', payload.course.dataValues.autorita_marittima)
            .replaceAll('{row_number}', payload.rowNumber + 1)
            .replaceAll('{ID}', payload.id ? payload.id : `2024/${payload.nome_corso}/1`)
            .replaceAll('{number}', payload.number)
            .replaceAll('{school_name}', `${payload.school.name}`)
            .replaceAll('{legal_address}', payload.school.sede_legale)
            .replaceAll('{phone}', `+39 ${payload.school.telefono}`)
            .replaceAll('{website}', payload.school.url)
            .replaceAll('{piva}', payload.school.piva)
            .replaceAll('{exam_day}', `${moment(payload.course.dataValues.to).add(1, 'days').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').format('DD/MM/YYYY')}`)
            .replaceAll('{docente}', payload.teacher)
                .replaceAll('{direttore}', payload.director)
                .replaceAll('{nome_corso}', payload.nome_corso)
                .replaceAll('{id}', payload.id ? payload.id : `2024/${payload.nome_corso}/1`)
               
                .replaceAll('{exam_day}', `${moment(payload.course.dataValues.to).add(1, 'days').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').format('DD/MM/YYYY')}`)
                .replaceAll('{expiry}', `${moment(payload.course.dataValues.to).add(1, 'days').add(5, 'years').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').add(5, 'years').format('DD/MM/YYYY')}`)
            
                .replaceAll('{decretee}', `${payload.decretee}`)
                .replaceAll('{start_day}', `${moment(payload.course.dataValues.from).format('dddd')}, ${moment(payload.course.dataValues.from).format('DD/MM/YYYY')}`)
                // .replaceAll('{partecipants}', payload.partecipant.map((partecipant, idx) => singlePartecipant(partecipant, idx + 1, payload.id)).join('\n'))
                .replaceAll('{school_address}', payload.school.indirizzo_scuola)  
                .replaceAll('{logo}', payload.school.logo)
            ).toPdf().get('pdf').then(pdf => {
        window.open(pdf.output('bloburl'), '_blank');
    })
}
export { Libretto, savePdf }