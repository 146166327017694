import React from 'react';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';

const SingleRow = (partecipant) =>`
        <tr style="border: 1px solid black; border-collapse:collapse">
            <td style="width: 100px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{id_matricola}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{last_name}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{first_name}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birth_place}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birth_province}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birthday}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{matricola}/{compartment}</td>
        </tr>
        `
const Libretto = `<html><head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
      @import url(https://themes.googleusercontent.com/fonts/css?kit=DtPsxHYDxQWpO8ASIGu_ew0oAUP_eRtG2f1I1nZF09YS0-eybaQSkqpYUibASASI_37GXcCmZhAvVrsFphImmA);

      .lst-kix_list_4-1>li {
        counter-increment: lst-ctn-kix_list_4-1
      }

      .lst-kix_list_1-1>li {
        counter-increment: lst-ctn-kix_list_1-1
      }

      .lst-kix_list_3-0>li:before {
        content: "o  "
      }

      .lst-kix_list_3-1>li:before {
        content: "o  "
      }

      .lst-kix_list_3-2>li:before {
        content: "\x025aa   "
      }

      ol.lst-kix_list_1-8.start {
        counter-reset: lst-ctn-kix_list_1-8 0
      }

      .lst-kix_list_3-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_3-4>li:before {
        content: "o  "
      }

      ol.lst-kix_list_1-5.start {
        counter-reset: lst-ctn-kix_list_1-5 0
      }

      .lst-kix_list_3-3>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_3-8>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_3-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_4-3>li {
        counter-increment: lst-ctn-kix_list_4-3
      }

      .lst-kix_list_3-7>li:before {
        content: "o  "
      }

      ol.lst-kix_list_4-5.start {
        counter-reset: lst-ctn-kix_list_4-5 0
      }

      .lst-kix_list_1-2>li {
        counter-increment: lst-ctn-kix_list_1-2
      }

      ol.lst-kix_list_4-2.start {
        counter-reset: lst-ctn-kix_list_4-2 0
      }

      .lst-kix_list_1-4>li {
        counter-increment: lst-ctn-kix_list_1-4
      }

      .lst-kix_list_4-4>li {
        counter-increment: lst-ctn-kix_list_4-4
      }

      ol.lst-kix_list_1-6.start {
        counter-reset: lst-ctn-kix_list_1-6 0
      }

      .lst-kix_list_4-8>li:before {
        content: ""counter(lst-ctn-kix_list_4-8, lower-roman) ". "
      }

      .lst-kix_list_4-7>li:before {
        content: ""counter(lst-ctn-kix_list_4-7, lower-latin) ". "
      }

      ol.lst-kix_list_4-1.start {
        counter-reset: lst-ctn-kix_list_4-1 0
      }

      ol.lst-kix_list_4-8.start {
        counter-reset: lst-ctn-kix_list_4-8 0
      }

      ul.lst-kix_list_4-0 {
        list-style-type: none
      }

      ol.lst-kix_list_1-0.start {
        counter-reset: lst-ctn-kix_list_1-0 0
      }

      ol.lst-kix_list_4-7.start {
        counter-reset: lst-ctn-kix_list_4-7 0
      }

      ol.lst-kix_list_1-3 {
        list-style-type: none
      }

      ol.lst-kix_list_1-4 {
        list-style-type: none
      }

      .lst-kix_list_2-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_2-7>li:before {
        content: "o  "
      }

      ol.lst-kix_list_1-5 {
        list-style-type: none
      }

      ol.lst-kix_list_1-6 {
        list-style-type: none
      }

      ol.lst-kix_list_1-0 {
        list-style-type: none
      }

      .lst-kix_list_2-4>li:before {
        content: "o  "
      }

      .lst-kix_list_2-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_2-8>li:before {
        content: "\x025aa   "
      }

      ol.lst-kix_list_1-1 {
        list-style-type: none
      }

      ol.lst-kix_list_1-2 {
        list-style-type: none
      }

      ul.lst-kix_list_3-7 {
        list-style-type: none
      }

      ul.lst-kix_list_3-8 {
        list-style-type: none
      }

      ol.lst-kix_list_4-6.start {
        counter-reset: lst-ctn-kix_list_4-6 0
      }

      ul.lst-kix_list_3-1 {
        list-style-type: none
      }

      ul.lst-kix_list_3-2 {
        list-style-type: none
      }

      ul.lst-kix_list_3-0 {
        list-style-type: none
      }

      ol.lst-kix_list_4-3.start {
        counter-reset: lst-ctn-kix_list_4-3 0
      }

      ol.lst-kix_list_1-7 {
        list-style-type: none
      }

      ul.lst-kix_list_3-5 {
        list-style-type: none
      }

      .lst-kix_list_4-7>li {
        counter-increment: lst-ctn-kix_list_4-7
      }

      .lst-kix_list_1-7>li {
        counter-increment: lst-ctn-kix_list_1-7
      }

      ol.lst-kix_list_1-8 {
        list-style-type: none
      }

      ul.lst-kix_list_3-6 {
        list-style-type: none
      }

      ul.lst-kix_list_3-3 {
        list-style-type: none
      }

      ul.lst-kix_list_3-4 {
        list-style-type: none
      }

      .lst-kix_list_4-0>li:before {
        content: "\x02022   "
      }

      .lst-kix_list_4-1>li:before {
        content: ""counter(lst-ctn-kix_list_4-1, lower-latin) ") "
      }

      .lst-kix_list_4-6>li {
        counter-increment: lst-ctn-kix_list_4-6
      }

      ol.lst-kix_list_1-7.start {
        counter-reset: lst-ctn-kix_list_1-7 0
      }

      .lst-kix_list_4-4>li:before {
        content: "("counter(lst-ctn-kix_list_4-4, lower-latin) ") "
      }

      .lst-kix_list_1-5>li {
        counter-increment: lst-ctn-kix_list_1-5
      }

      .lst-kix_list_4-3>li:before {
        content: "("counter(lst-ctn-kix_list_4-3, decimal) ") "
      }

      .lst-kix_list_4-5>li:before {
        content: "("counter(lst-ctn-kix_list_4-5, lower-roman) ") "
      }

      .lst-kix_list_4-2>li:before {
        content: ""counter(lst-ctn-kix_list_4-2, lower-roman) ") "
      }

      .lst-kix_list_4-6>li:before {
        content: ""counter(lst-ctn-kix_list_4-6, decimal) ". "
      }

      .lst-kix_list_1-8>li {
        counter-increment: lst-ctn-kix_list_1-8
      }

      ol.lst-kix_list_1-4.start {
        counter-reset: lst-ctn-kix_list_1-4 0
      }

      ol.lst-kix_list_1-1.start {
        counter-reset: lst-ctn-kix_list_1-1 0
      }

      ol.lst-kix_list_4-1 {
        list-style-type: none
      }

      ol.lst-kix_list_4-4.start {
        counter-reset: lst-ctn-kix_list_4-4 0
      }

      ol.lst-kix_list_4-2 {
        list-style-type: none
      }

      ol.lst-kix_list_4-3 {
        list-style-type: none
      }

      ol.lst-kix_list_1-3.start {
        counter-reset: lst-ctn-kix_list_1-3 0
      }

      ul.lst-kix_list_2-8 {
        list-style-type: none
      }

      ol.lst-kix_list_1-2.start {
        counter-reset: lst-ctn-kix_list_1-2 0
      }

      ol.lst-kix_list_4-8 {
        list-style-type: none
      }

      ul.lst-kix_list_2-2 {
        list-style-type: none
      }

      .lst-kix_list_1-0>li:before {
        content: ""counter(lst-ctn-kix_list_1-0, decimal) ". "
      }

      ul.lst-kix_list_2-3 {
        list-style-type: none
      }

      ul.lst-kix_list_2-0 {
        list-style-type: none
      }

      ul.lst-kix_list_2-1 {
        list-style-type: none
      }

      ol.lst-kix_list_4-4 {
        list-style-type: none
      }

      ul.lst-kix_list_2-6 {
        list-style-type: none
      }

      ol.lst-kix_list_4-5 {
        list-style-type: none
      }

      .lst-kix_list_1-1>li:before {
        content: ""counter(lst-ctn-kix_list_1-1, lower-latin) ". "
      }

      .lst-kix_list_1-2>li:before {
        content: ""counter(lst-ctn-kix_list_1-2, lower-roman) ". "
      }

      ul.lst-kix_list_2-7 {
        list-style-type: none
      }

      ol.lst-kix_list_4-6 {
        list-style-type: none
      }

      ul.lst-kix_list_2-4 {
        list-style-type: none
      }

      ol.lst-kix_list_4-7 {
        list-style-type: none
      }

      ul.lst-kix_list_2-5 {
        list-style-type: none
      }

      .lst-kix_list_1-3>li:before {
        content: ""counter(lst-ctn-kix_list_1-3, decimal) ". "
      }

      .lst-kix_list_1-4>li:before {
        content: ""counter(lst-ctn-kix_list_1-4, lower-latin) ". "
      }

      .lst-kix_list_1-0>li {
        counter-increment: lst-ctn-kix_list_1-0
      }

      .lst-kix_list_4-8>li {
        counter-increment: lst-ctn-kix_list_4-8
      }

      .lst-kix_list_1-6>li {
        counter-increment: lst-ctn-kix_list_1-6
      }

      .lst-kix_list_1-7>li:before {
        content: ""counter(lst-ctn-kix_list_1-7, lower-latin) ". "
      }

      .lst-kix_list_1-3>li {
        counter-increment: lst-ctn-kix_list_1-3
      }

      .lst-kix_list_1-5>li:before {
        content: ""counter(lst-ctn-kix_list_1-5, lower-roman) ". "
      }

      .lst-kix_list_1-6>li:before {
        content: ""counter(lst-ctn-kix_list_1-6, decimal) ". "
      }

      li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
      }

      .lst-kix_list_2-0>li:before {
        content: "o  "
      }

      .lst-kix_list_2-1>li:before {
        content: "o  "
      }

      .lst-kix_list_4-5>li {
        counter-increment: lst-ctn-kix_list_4-5
      }

      .lst-kix_list_1-8>li:before {
        content: ""counter(lst-ctn-kix_list_1-8, lower-roman) ". "
      }

      .lst-kix_list_2-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_2-3>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_4-2>li {
        counter-increment: lst-ctn-kix_list_4-2
      }

      ol {
        margin: 0;
        padding: 0
      }

      table td,
      table th {
        padding: 0
      }

      .c55 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 33.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c79 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 10.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c122 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 0.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c7 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 103.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c52 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c87 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 160.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c76 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 164.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c75 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 103.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c26 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c4 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 54.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c3 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c84 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 33.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c14 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 177.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c13 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c70 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 10.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c69 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 100.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c60 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 164.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c114 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 74.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c110 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c108 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 474.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c93 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 103.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c71 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 474.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c19 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 2.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c36 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 100.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c103 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 1pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c86 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 474.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c51 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 2.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c77 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 39pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c89 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 205.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c12 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c59 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 138.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c99 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 175.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c40 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c9 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c118 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c94 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 119.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c88 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 86pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c115 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 239.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c117 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 10.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c120 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 39pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c44 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 103.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c102 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 205.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c11 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 93.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c81 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c20 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c113 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 0.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c124 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 144.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c6 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 175.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c83 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 111pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c16 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 39pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c57 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 175.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c64 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 93.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c48 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 239.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c98 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c112 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 1.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c107 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 1.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c80 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 444.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c73 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c1 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 175.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c123 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 2.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c74 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 33.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c97 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 100.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c17 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 100.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c10 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 33.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c49 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 86pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c31 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 2.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c35 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 193.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c15 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 10.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c39 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 139.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c46 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c61 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c78 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 175.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c111 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 39pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c27 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 105.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c85 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c58 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 39pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c42 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 700;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 14pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c82 {
        -webkit-text-decoration-skip: none;
        color: #03308b;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 8pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c68 {
        -webkit-text-decoration-skip: none;
        color: #0000ff;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c91 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 700;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 8pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c104 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 5.5pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c22 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 700;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 11pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c25 {
        margin-left: -5pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: center;
        height: 10pt
      }

      .c53 {
        color: #c00000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal
      }

      .c121 {
        color: #002060;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Arial Black";
        font-style: normal
      }

      .c33 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c34 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal
      }

      .c0 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal
      }

      .c96 {
        color: #002060;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6pt;
        font-family: "Arial Black";
        font-style: normal
      }

      .c23 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: center;
        height: 10pt
      }

      .c50 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 9pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c105 {
        color: #c00000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal
      }

      .c106 {
        color: #0000ff;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c24 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c66 {
        color: #000080;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c45 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c101 {
        color: #ff0000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal
      }

      .c43 {
        color: #000080;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c116 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Arial";
        font-style: normal
      }

      .c41 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      .c2 {
        padding-top: 3pt;
        padding-bottom: 3pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      .c5 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        text-align: left;
        height: 10pt
      }

      .c18 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: left;
        height: 10pt
      }

      .c63 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: justify
      }

      .c29 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: center
      }

      .c30 {
        margin-left: -5.4pt;
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto
      }

      .c92 {
        margin-left: -5.8pt;
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto
      }

      .c28 {
        padding-top: 3pt;
        padding-bottom: 3pt;
        line-height: 1.0;
        text-align: left
      }

      .c56 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: center
      }

      .c72 {
        background-color: #ffffff;
        max-width: 467.8pt;
        padding: 51pt 70.8pt 56.7pt 56.7pt
      }

      .c38 {
        margin-left: -5pt;
        orphans: 2;
        widows: 2
      }

      .c54 {
        color: inherit;
        text-decoration: inherit
      }

      .c32 {
        margin-left: 36pt;
        padding-left: 0pt
      }

      .c21 {
        padding: 0;
        margin: 0
      }

      .c47 {
        orphans: 2;
        widows: 2
      }

      .c62 {
        height: 6.7pt
      }

      .c109 {
        height: 33.9pt
      }

      .c37 {
        height: 10pt
      }

      .c8 {
        height: 0pt
      }

      .c119 {
        margin-left: -5pt
      }

      .c67 {
        height: 7.5pt
      }

      .c100 {
        height: 11.5pt
      }

      .c95 {
        height: 23.1pt
      }

      .c90 {
        height: 44.9pt
      }

      .c65 {
        height: 28pt
      }

      .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1.0;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      li {
        color: #000000;
        font-size: 10pt;
        font-family: "Times New Roman"
      }

      p {
        margin: 0;
        color: #000000;
        font-size: 10pt;
        font-family: "Times New Roman"
      }

      h1 {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 24pt;
        padding-bottom: 6pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h2 {
        padding-top: 18pt;
        color: #000000;
        font-weight: 700;
        font-size: 18pt;
        padding-bottom: 4pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h3 {
        padding-top: 14pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h4 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 2pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h5 {
        padding-top: 11pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 2pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h6 {
        padding-top: 10pt;
        color: #000000;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }
    </style>
  </head>
  <body class="c72 doc-content">
    <div style="width: 100%; display: block; margin: 0 auto; text-align:center">
                    <img src="{logo}" crossorigin="*" style="height: 125px; object-fit: contain;">

    </div>
    <p class="c5">
      <span class="c116"></span>
    </p>
    <table class="c30" style="margin: 0 auto">
      <tbody>
        <tr class="c8">
          <td class="c89" colspan="2" rowspan="1">
            <p class="c29">
              <span class="c34">{school_name}</span>
            </p>
            <p class="c29">
              <span class="c105">{nome_corso}</span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c115" colspan="2" rowspan="1">
            <p class="c28">
              <span class="c34">Verbale di esame n</span>
              <span class="c0">.{id}/{number}</span>
            </p>
            <p class="c28">
              <span class="c34">Stampato alle ore</span>
              <span class="c0">&nbsp;{date} </span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c102" colspan="2" rowspan="1">
            <p class="c29 c37">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c48" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c95">
          <td class="c94" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c0">Cognome ___________________</span>
            </p>
          </td>
          <td class="c87" colspan="2" rowspan="1">
            <p class="c28">
              <span class="c0">Nome _____________________</span>
            </p>
          </td>
          <td class="c60" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c0">Data Esame _________</span>
            </p>
          </td>
        </tr>
        <tr class="c65">
          <td class="c80" colspan="4" rowspan="1">
            <p class="c29 c37">
              <span class="c0"></span>
            </p>
            <p class="c29">
              <span class="c0">Firma Discente _________________</span>
            </p>
            <p class="c29">
              <span class="c0">(firma autografa leggibile e per esteso)</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="c2 c37">
      <span class="c0"></span>
    </p>
    <br><br><table class="c92" style="margin: 0 auto">
      <tbody>
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="31">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Cosa tratta il IV annesso della Convenzione Internazionale MARPOL 73/78 ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c39" colspan="2" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Prevenzione dell'inquinqmento da liquami delle navi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Prevenzione dell'inquinamento atmosferico</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Prevenzione dell'inquinamento da sostanze tossiche nocive trasportate alla rinfusa</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Prevenzione dell'inquinamento da idrocarburi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Prevenzione dell'inquinamento da sostanze tossiche nocive trasportate in colli</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="32">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">In quale aree possono essere gettate in mare le interiori di pesce fresco ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Ovunque</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Solo in aree protette</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Solo in aree non protette</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Entro le aree protette a 12 miglia dalla costa</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Fuori le aree protette a 12 miglia dalla costa</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="33">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">In quale aree può essere gettata in mare la plastica ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">In aree non speciali ma solo a 50 miglia dalla costa</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Mai - Da nessuna parte</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Anche in aree speciali ma a 12 miglia dalla costa</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d)</span>
              <span class="c24">&nbsp;ovunque</span>
              <span class="c0">&nbsp;</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Solo nell'oceano Pacifico</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="34">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">A bordo si può bere l'alcool ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c2 c37">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c39" colspan="2" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Solo la sera</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Mai</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">1 bicchiere di vino solo durante i pasti principali</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Solo se la nave sosta in porto</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Solo per il personale non in servizio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="35">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Come viene segnalato l'abbandono nave ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Solo dalla viva voce del Comandante</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b)</span>
              <span class="c24">&nbsp;Un fischio lungo</span>
              <span class="c0">&nbsp;</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Due fischi lunghi seguiti dal suono di campane e campanelli</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Sette fischi brevi ed uno lungo seguito dal suono di campane e campanelli</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Sette fischi lunghi ed uno breve seguito dal suono di campane e campanelli</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="36">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Quali emergenze possono essere incluse a seguito della chiamata di emergenza generale?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Incendio grave, collisione, incaglio, affondamento</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Incendio, Incaglio, Falla, Inquinamento</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Falla, Collisione, Incaglio, Inquinamento</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Abbandono nave, Incendio grave, Incaglio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Tutte le risposte sono corrette</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="37">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Cosa è la check List ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c39" colspan="2" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c33">E' una lista in cui ci sono elencate tutte le persone adatte ad eseguire un lavoro pericoloso</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c33">E' una procedura da seguire per una determinata operazione, e funge sia come aiuto alla memoria e sia come lista precauzione per ottenere permessi di lavoro che possono compromettere la vita umana</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c33">E' una lista in cui ci sono elencati tutti i pezzi di ricambio necessari per un determinato lavoro</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c33">E' una normativa della convenzione COLREG</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c33">E' una lista per il controllo dell'inquinamento da idrocarburi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="38">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Cosa significa galleggiabilità ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a)</span>
              <span class="c33">&nbsp;E' una forza orizzontale della corrente a favore che aiuta la nave durante la navigazione </span>
              <span class="c0">&nbsp;</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c33">Attitudine della nave a galleggiare, anche in determinate condizioni di carico</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c33">E' una forza che la nave esercita contro le correnti marine</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d)</span>
              <span class="c33">&nbsp;Qualcosa che fuoriesce dalla linea di galleggiamento</span>
              <span class="c0">&nbsp;</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e)</span>
              <span class="c33">&nbsp;Tutte le risposte sono sbagliate</span>
              <span class="c0">&nbsp;</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="39">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">La STCW 78/95 è una convenzione della :</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) DPI</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) ILO</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) WHO</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) IMO</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) SOLAS</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="40">
              <li class="c28 c32 li-bullet-0">
              <br>
                <span class="c0">Cosa è lo scafo ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c2 c37">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c39" colspan="2" rowspan="1">
            <p class="c2 c37">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c33">La parte posteriore della nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c33">E' un complesso di strutture longitudinali, trasversali e del fasciame, che compongono il corpo della nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c33">Sovrastruttura centrale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c33">Parte anteriore della nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c33">Sovrastruttura Poppiera</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="41">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Cosa è il bordo libero ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c33">E' la lunghezza massima tra le punte estreme PruaPoppa</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c33">E' la distanza verticale tra la linea di galleggiamento e la parte più bassa della nave(sotto chiglia)</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c33">E' il bordo circoscritto della nave libero da ringhiere</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c33">E' la distanza verticale tra la linea di galleggiamento e il 1°ponte stagno della nave (ponte di coperta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c33">Indica di quanti metri è immersa la nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="42">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Cosa è l'occhio di Plimsoll ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c33">Occhio affiancato a quello di cubia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c33">Segno circolare indelebile a centro scafo che stabilisce la massima immersione media che può assumere la nave.</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c33">Alloggio per un riflettore da illuminare il percorso di notte durante il passaggio del Canale di Suez</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c33">Apertura per incanalare il cavo di rimorchio in caso di immobilità della nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c33">Un passaggio per i cavi di ormeggio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="43">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Quali sono i corsi da possedere per quel personale che vuole svolgere qualsiasi attività a bordo di una nave?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c39" colspan="2" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Antincendio avanzato,Sopravvivenza,Pssr,Primo soccorso</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">PSSR, Sopravvivenza, Antincendio Base</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
            <br><br>
              <span class="c0">c) </span>
              <span class="c24">PPSR, Sopravvivenza, Antincendio Base, Primo Soccorso,Awareness</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Pssr, Antincendio avanzato, Primo soccorso,Awarness</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Tutte le risposte sono sbagliate</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="44">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Quale Convenzione definisce l'addestramento da effettuare per il marittimo?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">La Capitaneria di Porto</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) STCW</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Il Ministero delle Infrastrutture</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">La Società di Navigazione dove dovreste imbarcare</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Il Governo Italiano</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="45">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Per facilitare la fuoriuscita della scarpa antinfortunistica dal piede dopo lo sgancio rapido, cosa bisogna fare ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Aiutarsi con l'altro piede</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Chiedere una misura di un numero superiore a quella del vostro piede</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Tagliare i lacci delle scarpe</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Tutte le risposte sono giuste</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Tutte le risposte sono sbagliate</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="46">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Perché l'Antartide pur non essendo un mare chiuso è protetto dalla MARPOL?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c39" colspan="2" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Perché ci sono i pinguini</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Perché il troppo freddo congelerebbe un eventuale inquinamento</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Perché è ricca di ghiacciai che rappresentano una grande riserva di acqua dolce</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Perché è chiuso da ghiacciai e non da terra</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Un inquinamento nell'Antartide comporterebbe grandi difficoltà di pulizia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="47">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Cosa è il salpancora ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">E' la staffa che tiene bloccata l'ancora di rispetto</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">E' un macchinario che recupera la catena dell'ancora da mare dopo l'ancoraggio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">E' l'ancora di poppa</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Macchinario per manovre di ormeggio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e)</span>
              <span class="c24">&nbsp;Dispositivo per eventuale traino della nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="48">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Dove si trova usualmente il salpancore ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">A prua</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">A poppa</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">A centro nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">In sala macchina</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Tutte le risposte sono sbagliate</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="49">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Se vi obbligano a lavorare senza i DPI lavorate lo stesso ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29 c37">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Si, non posso rifiutarmi di lavorare</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Devo rifiutare e denunciare all' Autorità Marittima</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Si, solo se non sono lavori pericolosi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Si, ma affiancato con un altro membro dell'equipaggio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Attenteremo istruzioni dalla compagnia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="50">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">L'acqua decantata della SLOP TANK dove può essere scaricata ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">In mare a 12 miglia dalla costa fuori e dentro le aree speciali</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">In mare a 50 miglia dalla costa fuori delle aree speciali</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Mai</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">In mare a 50 miglia dalla costa entro le aree speciali</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Solo negli oceani</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="51">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Cosa è il cassero ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29 c37">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a)</span>
              <span class="c24">&nbsp;E' una sovrastruttura della nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">E' un locale ove è permesso fumare</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c)</span>
              <span class="c24">&nbsp;E' un cassone ove sono custoditi i salvagenti individuali</span>
              <span class="c0">&nbsp;</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Sovrastruttura di centro</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Deposito degli attrezzi e materiali di consumo della sezione coperta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="52">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">sa è un gruppo?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Per gruppo intendiamo due o più persone che lavorano insieme</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Per gruppo intendiamo tre o più persone che lavorano per lo stesso fine aiutandosi fra di loro</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Per gruppo intendiamo tre o più persone che fumano insieme nella saletta fumatori</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d)</span>
              <span class="c24">&nbsp;Per gruppo intendiamo un certo numero di persone destinati a lavori particolari</span>
              <span class="c0">&nbsp;</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">più di 2/3 persone che stanno insieme</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="53">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Perché viene chiamato il Ponte della Normale</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Per la presenza del generatore di emergenza</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Per la presenza della bussola magnetica</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Perché si vede normalmente tutto ciò che èp intorno alla nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Perché ci sono le antenne del segnale satellitare</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Perché ci sono le antenne del Radar</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="54">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Che cosa è il Ponte di Bordo Libero? </span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">E' il ponte più elevato della nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">E' il ponte libero da ostacoli ed è situato in coperta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">E' il ponte della nave libero da ringhiere</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">E' il ponte completo più elevato al di sotto del quale le aperture praticate sullo scafo della nave hanno mezzi di chiusura stagni</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">E' il ponte libero dove si trova la bussola normale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c62">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="55">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Oltre ai DPI che altro dobbiamo indossare alla segnalazione dell' Emergenza Generale?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">La mascherina antipolvere</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Le cuffie antirumore</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Il salvagente individuale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">La maschera antigas</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">L'autorespiratore</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="56">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Se cade in mare un collo contenente sostanza tossica nociva, come dobbiamo comportarci ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29 c37">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c33">Continuare normalmente la navigazione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c33">Avvertire l'Autorità Marittima più vicina</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c33">Cercare di recuperarlo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Cercare di mandarlo a fondo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Avvertiamo l'armatore e attentiamo sue disposizioni</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="57">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Entro le 3 miglia dalla costa, possiamo scaricare in mare il prodotto raccolto nella Sewage tank ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a)</span>
              <span class="c24">&nbsp;Si, sempre</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">No, mai</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Si, solo se è trattato ecologicamente</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Solo nelle aree non protette</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Nelle aree protette e non protette non meno di 100 miglia dalla costa</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="58">
              <li class="c28 c32 li-bullet-0">
                <span class="c33">Ove è affisso Il Ruolo d'Appello ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c33">Solo sul Ponte di Comando</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c33">Solo sul Ponte di Comando e Sala Macchina</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c33">Nei punti più frequentati della nave<br><br> ed in particolare ponte comando, locale macchine, locali equipaggio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c33">Nell'alloggio personale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c33">In controplancia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="59">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">In cabina avete il Ruolo d'Appello ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Si</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b)</span>
              <span class="c24">&nbsp;Si,ma con la mia mansione evidenziata</span>
              <span class="c0">&nbsp;</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">No</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Si,solo per quanto riguarda le segnalazioni di riconoscimento</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Si,solo per quanto riguarda l'abbandono nave.</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="60">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Di quanti capitoli è composto la Convenzione SOLAS ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Internazionale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b)15</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) 10</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) 11</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) 13</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="61">
              <li class="c28 c32 li-bullet-0">
                <span class="c33">Qual è il capitolo della SOLAS che tratta i mezzi di salvataggio ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) quinto</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) ottavo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) quarto</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) sesto</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) terzo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
      </tbody>
    </table>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <table class="c30" style="margin: 0 auto">
      <tbody>
        <tr class="c90">
          <td class="c124" colspan="1" rowspan="1">
            <p class="c56 c47">
              <span class="c0">IL DIRETTORE DEL CORSO</span>
            </p>
            <p class="c56 c47 c37">
              <span class="c0"></span>
            </p>
            <p class="c56 c47 c37">
              <span class="c0"></span>
            </p>
            <p class="c41">
              <span class="c0">________________________</span>
            </p>
            <p class="c37 c41">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c59" colspan="1" rowspan="1">
            <p class="c56 c47">
              <span class="c0">MEMBRO</span>
            </p>
            <p class="c41 c37">
              <span class="c0"></span>
            </p>
            <p class="c41 c37">
              <span class="c0"></span>
            </p>
            <p class="c56 c47">
              <span class="c0">_______________________</span>
            </p>
            <p class="c56 c47 c37">
              <span class="c101"></span>
            </p>
          </td>
          <td class="c83" colspan="1" rowspan="1">
            <p class="c56 c47">
              <span class="c0">MEMBRO E SEGRETARIO</span>
            </p>
            <p class="c47 c37 c56">
              <span class="c0"></span>
            </p>
            <p class="c56 c47">
              <span class="c0">__________________</span>
            </p>
          </td>
          <td class="c59" colspan="1" rowspan="1">
            <p class="c56 c47">
              <span class="c0">IL RAPPRESENTANTE DELL’AUTORITA’ MARITTIMA</span>
            </p>
            <p class="c56 c47">
              <span class="c0">_______________________</span>
            </p>
            <p class="c56 c47 c37">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c28">
      <span class="c0">&nbsp;IL DIRETTORE DEL CORSO ___________________________________</span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c28">
      <span class="c0">&nbsp; MEMBRO _______________________________________</span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c28">
      <span class="c0">&nbsp; MEMBRO E SEGRETARIO_____________________________________</span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c28">
      <span class="c0">&nbsp;IL RAPPRESENTANTE DELL’AUTORITA’ MARITTIMA ___________________________________</span>
    </p>
    <div>
      <p class="c29 c47">
        <span class="c66">Palermo</span>
        <span class="c43">&nbsp;– Via Francesco Crispi, 155 – Porto Varco S. Lucia – CAP 90133 – </span>
        <span class="c66">Tel. +39 091 320.353</span>
        <span class="c43">&nbsp;– Fax +39 091 612.36.70</span>
      </p>
      <p class="c29 c47">
        <span class="c43">Campo Antincendio S.P. di Bellolampo, 8401-Palermo-email: </span>
        <span class="c68">
          <a class="c54" href="mailto:desantistudio@alice.it">sviluppo.desantiscorsidiformazione.it</a>
        </span>
        <span class="c43">&nbsp;-web:</span>
        <span class="c68">
          <a class="c54" href="https://www.google.com/url?q=http://www.studiodesantissrl.it&amp;sa=D&amp;source=editors&amp;ust=1737694822731745&amp;usg=AOvVaw3L0xSlwCnaytim9GhVoCo0">www.studiodesantissrl.it</a>
        </span>
        <span class="c43">&nbsp; &nbsp; &nbsp; </span>
      </p>
      <p class="c29 c47">
        <span class="c43">Pe</span>
        <span class="c45">c:</span>
        <span class="c68">
          <a class="c54" href="mailto:centro.studio.desantis@pec.it">centro.studio.desantis@pec.it</a>
        </span>
        <span class="c45">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
        <span class="c106">IBAN:ITI7P032680460705295143829</span>
      </p>
      <p class="c29 c47 c37">
        <span class="c82"></span>
      </p>
    </div>
  
</body></html>`
function _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
       binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }
const savePdf = async (payload) => {
    const logo = await fetch('https://mase-storage.s3.eu-west-1.amazonaws.com/1716223770927.document-file.jpeg');
    
    // const mappa = lessons.map((x, i) => singleCourseTemplate.replaceAll('{no}', i + 1).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    // const practicalMap = lessons.filter(x => x.pratica).map((x, i) => Practical.replaceAll('{name}', `Lezione nr. ${i + 1}`).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    html2pdf()
    .set({
        filename: 'scheda_ammissione.pdf',
        margin: 20,
        html2canvas: { useCORS: true },
    })
    .from(Libretto.replaceAll('{docente}', payload.teacher)
            .replaceAll('{direttore}', payload.director)
            .replaceAll('{nome_corso}', payload.nome_corso)
            .replaceAll('{logo}', payload.settings?.logo_top ? payload.settings?.logo_top : payload.school.logo)
            .replaceAll('{autorita_marittima}', payload.course.dataValues.autorita_marittima)
            .replaceAll('{row_number}', payload.rowNumber + 1)
            .replaceAll('{ID}', payload.id ? payload.id : `2024/${payload.nome_corso}/1`)
            .replaceAll('{number}', payload.number)
            .replaceAll('{school_name}', `${payload.school.name}`)
            .replaceAll('{legal_address}', payload.school.sede_legale)
            .replaceAll('{phone}', `+39 ${payload.school.telefono}`)
            .replaceAll('{website}', payload.school.url)
            .replaceAll('{piva}', payload.school.piva)
            .replaceAll('{exam_day}', `${moment(payload.course.dataValues.to).add(1, 'days').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').format('DD/MM/YYYY')}`)
            .replaceAll('{docente}', payload.teacher)
            .replaceAll('{date}', moment().format('DD/MM/YYYY HH:mm'))
                .replaceAll('{direttore}', payload.director)
                .replaceAll('{nome_corso}', payload.nome_corso)
                .replaceAll('{id}', payload.id ? payload.id : `2024/${payload.nome_corso}/1`)
                .replaceAll('{birth_place}', `${payload.partecipant[0].birthplace}`)
                .replaceAll('{birth_province}', `${payload.partecipant[0].birth_province}`)
                .replaceAll('{birthday}', `${moment(payload.partecipant[0].birthday).format('DD/MM/YYYY')}`)
                .replaceAll('{matricola}', `${payload.partecipant[0].matricola}`)
                .replaceAll('{compartment}', `${payload.partecipant[0].compartimento_marittimo}`)
                .replaceAll('{exam_day}', `${moment(payload.course.dataValues.to).add(1, 'days').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').format('DD/MM/YYYY')}`)
                .replaceAll('{expiry}', `${moment(payload.course.dataValues.to).add(1, 'days').add(5, 'years').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').add(5, 'years').format('DD/MM/YYYY')}`)
            
                .replaceAll('{first_name}', `${payload.partecipant[0].first_name}`)
                .replaceAll('{last_name}', `${payload.partecipant[0].last_name}`)
                .replaceAll('{birth_place}', `${payload.partecipant[0].birthplace}`)
                .replaceAll('{birth_province}', `${payload.partecipant[0].birth_province}`)
                .replaceAll('{birth_province}', `${payload.partecipant[0].birth_province}`)
                .replaceAll('{matricola}', `${payload.partecipant[0].matricola}`)
                .replaceAll('{compartment}', `${payload.partecipant[0].compartimento_marittimo}`)
                .replaceAll('{fiscal_code}', `${payload.partecipant[0].fiscal_code}`)
                .replaceAll('{address}', `${payload.partecipant[0].address}`)
                .replaceAll('{civic_number}', `${payload.partecipant[0].civic_number}`)
                .replaceAll('{city_residence}', `${payload.partecipant[0].city_residence}`)
                .replaceAll('{postal_code}', `${payload.partecipant[0].postal_code}`)
                .replaceAll('{compartimento_marittimo}', `${payload.partecipant[0].compartimento_marittimo}`)
                .replaceAll('{phone_number}', `${payload.partecipant[0].phone_number}`)
                .replaceAll('{passport}', `${payload.partecipant[0].passport}`)
                .replaceAll('{decretee}', `${payload.decretee}`)
                .replaceAll('{birthday}', `${moment(payload.partecipant[0].birthday).format('DD/MM/YYYY')}`)
                .replaceAll('{start_day}', `${moment(payload.course.dataValues.from).format('dddd')}, ${moment(payload.course.dataValues.from).format('DD/MM/YYYY')}`)
                // .replaceAll('{partecipants}', payload.partecipant.map((partecipant, idx) => singlePartecipant(partecipant, idx + 1, payload.id)).join('\n'))
                .replaceAll('{school_address}', payload.school.indirizzo_scuola)  
                .replaceAll('{logo}', payload.school.logo)
            ).toPdf().get('pdf').then(pdf => {
                const totalPages = pdf.internal.getNumberOfPages(); // Numero totale di pagine

                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);

                    // Aggiungi Header
                    pdf.setFontSize(10);
                    pdf.text(`${payload.school.name} | Batteria Corso`, 10, 10); // Posizione dell'header (10mm dall'alto)

                    // Aggiungi Footer
                    pdf.text(
                        `Pagina ${i} di ${totalPages}`, // Numerazione delle pagine
                        pdf.internal.pageSize.getWidth() / 2, // Centro della pagina
                        pdf.internal.pageSize.getHeight() - 10, // 10mm dal fondo
                        { align: 'center' }
                    );
                }

                // Apri il PDF
                    window.open(pdf.output('bloburl'), '_blank');
                })
}
export { Libretto, savePdf }