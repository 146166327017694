import React from 'react';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';

const SingleRow = (partecipant) =>`
        <tr style="border: 1px solid black; border-collapse:collapse">
            <td style="width: 100px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{id_matricola}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{last_name}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{first_name}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birth_place}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birth_province}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birthday}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{matricola}/{compartment}</td>
        </tr>
        `
const Libretto = `<html>
    <body style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; border: 5px solid #99ff; padding: 12px 24px; width: 800px">
    <div style="padding-left: 2rem; padding-right: 2rem;height: 100%" class="page">    
    <div style="width: 100%; display: block; margin: 0 auto; text-align:center">
                        <img src="{logo}" crossorigin="*" style="height: 125px; object-fit: contain;"/>

        </div>
     <div style="margin-top: 24px; text-align: center"><strong style="font-size: 18px">Lettera conferma sessione Esami</strong></div>
        <div style="display: block; width: 100%; margin-top: 60px; float: right; font-size: 12px; text-align: right; margin-bottom: 60px"><span style="width: 200px; display: block; float: right">Spett.le Capitaneria di Porto c/att Ufficio Gente di Mare</span></div>
        <div style="width: 100%; margin-top: 48px"><strong style="">OGGETTO: Conferma Commissione d'esame per corsi</strong></div>
        <div style="margin-top: 24px">Con la presente siamo a confermare la commissione d'esame per il corso:</div>
        <div style="margin: 0 auto; margin-top: 48px; width: 90%; padding: 24px; border: 1px solid #000">
            <strong style="width: 100%; display: block;">{ID}</strong>
            <strong style="font-size: 12px; width: 100%; display: block;">{exam_day}  dalle ore  08:30 alle ore 10:30</strong>
            <strong>La commissione per nr. {number} corsisti.</strong>
        </div>
        <p>Inviamo elenco dei corsisti per questa sessione.</p>
        <p>Cogliamo l'occasione per porgere i nostri saluti.</p>
                <p style="margin: 0 auto;padding-top: 400px; text-align: center; font-size: 10px">{school_name} SRL, {legal_address} - Tel. {phone} P.IVA: {piva} <br/> Sito web: {website}</p>

        </div>
    </body>
</html>`
function _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
       binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }
  async function blobUrlToBase64(blobUrl) {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.readAsDataURL(blob);
    });
}
const getPDF = async (payload) => {
    const logo = await fetch('https://mase-storage.s3.eu-west-1.amazonaws.com/1716223770927.document-file.jpeg');
    
    // const mappa = lessons.map((x, i) => singleCourseTemplate.replaceAll('{no}', i + 1).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    // const practicalMap = lessons.filter(x => x.pratica).map((x, i) => Practical.replaceAll('{name}', `Lezione nr. ${i + 1}`).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    return await blobUrlToBase64( await html2pdf()
    .set({
        filename: 'verbale_esame.pdf',
        html2canvas: { useCORS: true },
    })
    .from(Libretto
        .replaceAll('{docente}', payload.teacher)
        .replaceAll('{direttore}', payload.director)
        .replaceAll('{nome_corso}', payload.nome_corso)
        .replaceAll('{logo}', payload.settings?.logo_top ? payload.settings?.logo_top : payload.school.logo)
        .replaceAll('{autorita_marittima}', payload.course.dataValues.autorita_marittima)
        .replaceAll('{row_number}', payload.rowNumber + 1)
        .replaceAll('{ID}', payload.id ? payload.id : `2024/${payload.nome_corso}/1`)
        .replaceAll('{number}', payload.number)
        .replaceAll('{school_name}', `${payload.school.name}`)
        .replaceAll('{legal_address}', payload.school.sede_legale)
        .replaceAll('{phone}', `+39 ${payload.school.telefono}`)
        .replaceAll('{website}', payload.school.url)
        .replaceAll('{piva}', payload.school.piva)
        .replaceAll('{exam_day}', `${moment(payload.course.dataValues.to).add(1, 'days').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').format('DD/MM/YYYY')}`)
        .replaceAll('{rows}', payload.partecipant.map((partecipant, idx) => SingleRow(partecipant).replaceAll('{autorita_marittima}', payload.course.dataValues.autorita_marittima)
            .replaceAll('{docente}', payload.teacher)
            .replaceAll('{direttore}', payload.director)
            .replaceAll('{nome_corso}', payload.nome_corso)
            .replaceAll('{id}', payload.id ? payload.id : `2024/${payload.nome_corso}/1`)
            .replaceAll('{birth_place}', `${partecipant.birthplace}`)
            .replaceAll('{birth_province}', `${partecipant.birth_province}`)
            .replaceAll('{birthday}', `${moment(partecipant.birthday).format('DD/MM/YYYY')}`)
            .replaceAll('{matricola}', `${partecipant.matricola}`)
            .replaceAll('{compartment}', `${partecipant.compartimento_marittimo}`)
            .replaceAll('{exam_day}', `${moment(payload.course.dataValues.to).add(1, 'days').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').format('DD/MM/YYYY')}`)
            .replaceAll('{expiry}', `${moment(payload.course.dataValues.to).add(1, 'days').add(5, 'years').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').add(5, 'years').format('DD/MM/YYYY')}`)
        
            .replaceAll('{first_name}', `${partecipant.first_name}`)
            .replaceAll('{last_name}', `${partecipant.last_name}`)
            .replaceAll('{birth_place}', `${partecipant.birthplace}`)
            .replaceAll('{birth_province}', `${partecipant.birth_province}`)
            .replaceAll('{birth_province}', `${partecipant.birth_province}`)
            .replaceAll('{matricola}', `${partecipant.matricola}`)
            .replaceAll('{compartment}', `${partecipant.compartimento_marittimo}`)
            .replaceAll('{id_matricola}', `${(idx+1).toString().padStart(3, '0')}/${payload.id ? payload.id : `2024/${payload.nome_corso}/1`}`)
            .replaceAll('{fiscal_code}', `${partecipant.fiscal_code}`)
            .replaceAll('{decretee}', `${payload.decretee}`)
            .replaceAll('{birthday}', `${moment(partecipant.birthday).format('DD/MM/YYYY')}`)
            .replaceAll('{start_day}', `${moment(payload.course.dataValues.from).format('dddd')}, ${moment(payload.course.dataValues.from).format('DD/MM/YYYY')}`)
            // .replaceAll('{partecipants}', payload.partecipant.map((partecipant, idx) => singlePartecipant(partecipant, idx + 1, payload.id)).join('\n'))
            .replaceAll('{school_address}', payload.school.indirizzo_scuola)  
            .replaceAll('{logo}', payload.school.logo)  ).join(''))
        
    ).toPdf().get('pdf').output('bloburl'));
}
export { Libretto, getPDF }