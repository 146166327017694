import React from 'react';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';

const SingleRow = (partecipant) =>`
        <tr style="border: 1px solid black; border-collapse:collapse">
            <td style="width: 100px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{id_matricola}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{last_name}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{first_name}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birth_place}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birth_province}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birthday}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{matricola}/{compartment}</td>
        </tr>
        `
const Libretto = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
     <style type="text/css">
      @import url(https://themes.googleusercontent.com/fonts/css?kit=DtPsxHYDxQWpO8ASIGu_ew0oAUP_eRtG2f1I1nZF09YS0-eybaQSkqpYUibASASI_37GXcCmZhAvVrsFphImmA);

      ol.lst-kix_list_7-0 {
        list-style-type: none
      }

      .lst-kix_list_14-1>li:before {
        content: "o  "
      }

      .lst-kix_list_14-3>li:before {
        content: "\x025cf   "
      }

      ul.lst-kix_list_1-0 {
        list-style-type: none
      }

      ol.lst-kix_list_9-0.start {
        counter-reset: lst-ctn-kix_list_9-0 0
      }

      .lst-kix_list_14-0>li:before {
        content: "\x02022   "
      }

      .lst-kix_list_14-4>li:before {
        content: "o  "
      }

      .lst-kix_list_6-1>li {
        counter-increment: lst-ctn-kix_list_6-1
      }

      .lst-kix_list_8-1>li {
        counter-increment: lst-ctn-kix_list_8-1
      }

      .lst-kix_list_14-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_14-7>li:before {
        content: "o  "
      }

      ol.lst-kix_list_13-4.start {
        counter-reset: lst-ctn-kix_list_13-4 0
      }

      .lst-kix_list_14-6>li:before {
        content: "\x025cf   "
      }

      ul.lst-kix_list_9-3 {
        list-style-type: none
      }

      ul.lst-kix_list_9-4 {
        list-style-type: none
      }

      ul.lst-kix_list_9-2 {
        list-style-type: none
      }

      ul.lst-kix_list_9-7 {
        list-style-type: none
      }

      ul.lst-kix_list_9-8 {
        list-style-type: none
      }

      ul.lst-kix_list_9-5 {
        list-style-type: none
      }

      .lst-kix_list_7-0>li {
        counter-increment: lst-ctn-kix_list_7-0
      }

      .lst-kix_list_9-0>li {
        counter-increment: lst-ctn-kix_list_9-0
      }

      ul.lst-kix_list_9-6 {
        list-style-type: none
      }

      ul.lst-kix_list_1-3 {
        list-style-type: none
      }

      ul.lst-kix_list_1-4 {
        list-style-type: none
      }

      ul.lst-kix_list_1-1 {
        list-style-type: none
      }

      ul.lst-kix_list_1-2 {
        list-style-type: none
      }

      ul.lst-kix_list_1-7 {
        list-style-type: none
      }

      ul.lst-kix_list_1-8 {
        list-style-type: none
      }

      ul.lst-kix_list_1-5 {
        list-style-type: none
      }

      .lst-kix_list_14-2>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_1-6 {
        list-style-type: none
      }

      .lst-kix_list_14-8>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_5-0>li:before {
        content: "\x025a1   "
      }

      ol.lst-kix_list_6-0 {
        list-style-type: none
      }

      ol.lst-kix_list_6-1 {
        list-style-type: none
      }

      .lst-kix_list_5-3>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_13-2>li {
        counter-increment: lst-ctn-kix_list_13-2
      }

      .lst-kix_list_5-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_5-1>li:before {
        content: "o  "
      }

      .lst-kix_list_5-7>li:before {
        content: "o  "
      }

      ul.lst-kix_list_8-4 {
        list-style-type: none
      }

      ul.lst-kix_list_8-5 {
        list-style-type: none
      }

      .lst-kix_list_5-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_5-8>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_8-2 {
        list-style-type: none
      }

      ul.lst-kix_list_8-3 {
        list-style-type: none
      }

      ul.lst-kix_list_8-8 {
        list-style-type: none
      }

      ul.lst-kix_list_8-6 {
        list-style-type: none
      }

      ul.lst-kix_list_8-7 {
        list-style-type: none
      }

      .lst-kix_list_5-4>li:before {
        content: "o  "
      }

      .lst-kix_list_5-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_6-1>li:before {
        content: ""counter(lst-ctn-kix_list_6-1, lower-latin) ") "
      }

      .lst-kix_list_6-3>li:before {
        content: "\x025cf   "
      }

      ol.lst-kix_list_13-3.start {
        counter-reset: lst-ctn-kix_list_13-3 0
      }

      .lst-kix_list_6-0>li:before {
        content: ""counter(lst-ctn-kix_list_6-0, lower-latin) ") "
      }

      .lst-kix_list_6-4>li:before {
        content: "o  "
      }

      .lst-kix_list_3-0>li {
        counter-increment: lst-ctn-kix_list_3-0
      }

      .lst-kix_list_6-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_6-8>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_6-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_6-7>li:before {
        content: "o  "
      }

      .lst-kix_list_6-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_2-7>li:before {
        content: "o  "
      }

      .lst-kix_list_7-4>li:before {
        content: "o  "
      }

      .lst-kix_list_7-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_2-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_7-2>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_3-7 {
        list-style-type: none
      }

      ul.lst-kix_list_3-8 {
        list-style-type: none
      }

      .lst-kix_list_10-1>li:before {
        content: "o  "
      }

      .lst-kix_list_13-7>li:before {
        content: ""counter(lst-ctn-kix_list_13-7, lower-latin) ". "
      }

      ol.lst-kix_list_3-0.start {
        counter-reset: lst-ctn-kix_list_3-0 0
      }

      ul.lst-kix_list_3-1 {
        list-style-type: none
      }

      ul.lst-kix_list_3-2 {
        list-style-type: none
      }

      .lst-kix_list_7-8>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_3-5 {
        list-style-type: none
      }

      ol.lst-kix_list_9-0 {
        list-style-type: none
      }

      ul.lst-kix_list_3-6 {
        list-style-type: none
      }

      ol.lst-kix_list_9-1 {
        list-style-type: none
      }

      ul.lst-kix_list_3-3 {
        list-style-type: none
      }

      ul.lst-kix_list_3-4 {
        list-style-type: none
      }

      .lst-kix_list_10-7>li:before {
        content: "o  "
      }

      .lst-kix_list_15-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_10-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_13-4>li {
        counter-increment: lst-ctn-kix_list_13-4
      }

      ol.lst-kix_list_13-5.start {
        counter-reset: lst-ctn-kix_list_13-5 0
      }

      .lst-kix_list_10-3>li:before {
        content: "\x025cf   "
      }

      ul.lst-kix_list_11-7 {
        list-style-type: none
      }

      ul.lst-kix_list_11-6 {
        list-style-type: none
      }

      .lst-kix_list_4-1>li:before {
        content: "o  "
      }

      ul.lst-kix_list_11-5 {
        list-style-type: none
      }

      ul.lst-kix_list_11-4 {
        list-style-type: none
      }

      ul.lst-kix_list_11-3 {
        list-style-type: none
      }

      ol.lst-kix_list_13-8.start {
        counter-reset: lst-ctn-kix_list_13-8 0
      }

      .lst-kix_list_15-7>li:before {
        content: "o  "
      }

      ul.lst-kix_list_11-2 {
        list-style-type: none
      }

      ul.lst-kix_list_11-1 {
        list-style-type: none
      }

      ul.lst-kix_list_11-0 {
        list-style-type: none
      }

      .lst-kix_list_9-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_4-3>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_4-5>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_11-8 {
        list-style-type: none
      }

      .lst-kix_list_15-1>li:before {
        content: "o  "
      }

      .lst-kix_list_9-0>li:before {
        content: ""counter(lst-ctn-kix_list_9-0, lower-latin) ") "
      }

      .lst-kix_list_15-3>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_9-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_9-4>li:before {
        content: "o  "
      }

      .lst-kix_list_11-3>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_2-8 {
        list-style-type: none
      }

      .lst-kix_list_12-3>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_11-5>li:before {
        content: "\x025cf   "
      }

      ol.lst-kix_list_6-1.start {
        counter-reset: lst-ctn-kix_list_6-1 0
      }

      .lst-kix_list_12-1>li:before {
        content: "o  "
      }

      ul.lst-kix_list_2-2 {
        list-style-type: none
      }

      ul.lst-kix_list_2-3 {
        list-style-type: none
      }

      ul.lst-kix_list_2-0 {
        list-style-type: none
      }

      ul.lst-kix_list_2-1 {
        list-style-type: none
      }

      ol.lst-kix_list_8-0 {
        list-style-type: none
      }

      .lst-kix_list_9-8>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_2-6 {
        list-style-type: none
      }

      ol.lst-kix_list_8-1 {
        list-style-type: none
      }

      .lst-kix_list_1-1>li:before {
        content: "o  "
      }

      ul.lst-kix_list_2-7 {
        list-style-type: none
      }

      .lst-kix_list_11-7>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_13-3>li {
        counter-increment: lst-ctn-kix_list_13-3
      }

      ol.lst-kix_list_13-6.start {
        counter-reset: lst-ctn-kix_list_13-6 0
      }

      ul.lst-kix_list_2-4 {
        list-style-type: none
      }

      ul.lst-kix_list_2-5 {
        list-style-type: none
      }

      ul.lst-kix_list_10-0 {
        list-style-type: none
      }

      .lst-kix_list_1-3>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_13-3>li:before {
        content: ""counter(lst-ctn-kix_list_13-3, decimal) ". "
      }

      ul.lst-kix_list_10-8 {
        list-style-type: none
      }

      ul.lst-kix_list_10-7 {
        list-style-type: none
      }

      .lst-kix_list_1-7>li:before {
        content: "o  "
      }

      ul.lst-kix_list_10-6 {
        list-style-type: none
      }

      ul.lst-kix_list_10-5 {
        list-style-type: none
      }

      ul.lst-kix_list_10-4 {
        list-style-type: none
      }

      ul.lst-kix_list_10-3 {
        list-style-type: none
      }

      .lst-kix_list_1-5>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_10-2 {
        list-style-type: none
      }

      ul.lst-kix_list_10-1 {
        list-style-type: none
      }

      ol.lst-kix_list_9-1.start {
        counter-reset: lst-ctn-kix_list_9-1 0
      }

      .lst-kix_list_13-5>li:before {
        content: ""counter(lst-ctn-kix_list_13-5, lower-roman) ". "
      }

      .lst-kix_list_12-5>li:before {
        content: "\x025aa   "
      }

      ol.lst-kix_list_13-7.start {
        counter-reset: lst-ctn-kix_list_13-7 0
      }

      .lst-kix_list_12-7>li:before {
        content: "o  "
      }

      .lst-kix_list_2-1>li:before {
        content: "o  "
      }

      ol.lst-kix_list_6-0.start {
        counter-reset: lst-ctn-kix_list_6-0 0
      }

      .lst-kix_list_2-3>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_13-1>li:before {
        content: ""counter(lst-ctn-kix_list_13-1, lower-latin) ". "
      }

      ol.lst-kix_list_3-0 {
        list-style-type: none
      }

      .lst-kix_list_9-1>li {
        counter-increment: lst-ctn-kix_list_9-1
      }

      .lst-kix_list_3-0>li:before {
        content: ""counter(lst-ctn-kix_list_3-0, lower-latin) ") "
      }

      ul.lst-kix_list_5-7 {
        list-style-type: none
      }

      ol.lst-kix_list_13-1.start {
        counter-reset: lst-ctn-kix_list_13-1 0
      }

      ul.lst-kix_list_5-8 {
        list-style-type: none
      }

      .lst-kix_list_3-1>li:before {
        content: "o  "
      }

      .lst-kix_list_3-2>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_5-5 {
        list-style-type: none
      }

      ul.lst-kix_list_5-6 {
        list-style-type: none
      }

      .lst-kix_list_8-1>li:before {
        content: ""counter(lst-ctn-kix_list_8-1, lower-latin) ") "
      }

      .lst-kix_list_8-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_6-0>li {
        counter-increment: lst-ctn-kix_list_6-0
      }

      .lst-kix_list_8-0>li {
        counter-increment: lst-ctn-kix_list_8-0
      }

      .lst-kix_list_3-5>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_5-0 {
        list-style-type: none
      }

      .lst-kix_list_3-4>li:before {
        content: "o  "
      }

      ul.lst-kix_list_5-3 {
        list-style-type: none
      }

      .lst-kix_list_3-3>li:before {
        content: "\x025cf   "
      }

      ul.lst-kix_list_5-4 {
        list-style-type: none
      }

      ul.lst-kix_list_5-1 {
        list-style-type: none
      }

      .lst-kix_list_8-0>li:before {
        content: ""counter(lst-ctn-kix_list_8-0, lower-latin) ") "
      }

      ul.lst-kix_list_5-2 {
        list-style-type: none
      }

      .lst-kix_list_8-7>li:before {
        content: "o  "
      }

      .lst-kix_list_3-8>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_8-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_8-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_13-1>li {
        counter-increment: lst-ctn-kix_list_13-1
      }

      .lst-kix_list_8-3>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_3-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_3-7>li:before {
        content: "o  "
      }

      ul.lst-kix_list_13-0 {
        list-style-type: none
      }

      .lst-kix_list_8-4>li:before {
        content: "o  "
      }

      .lst-kix_list_11-2>li:before {
        content: "o  "
      }

      .lst-kix_list_11-1>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_11-0>li:before {
        content: "  "
      }

      .lst-kix_list_8-8>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_4-8>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_4-7>li:before {
        content: "o  "
      }

      ul.lst-kix_list_4-8 {
        list-style-type: none
      }

      ul.lst-kix_list_4-6 {
        list-style-type: none
      }

      ul.lst-kix_list_4-7 {
        list-style-type: none
      }

      ul.lst-kix_list_4-0 {
        list-style-type: none
      }

      ul.lst-kix_list_4-1 {
        list-style-type: none
      }

      ul.lst-kix_list_4-4 {
        list-style-type: none
      }

      ul.lst-kix_list_4-5 {
        list-style-type: none
      }

      ul.lst-kix_list_4-2 {
        list-style-type: none
      }

      ul.lst-kix_list_4-3 {
        list-style-type: none
      }

      ul.lst-kix_list_12-6 {
        list-style-type: none
      }

      ul.lst-kix_list_12-5 {
        list-style-type: none
      }

      ul.lst-kix_list_12-4 {
        list-style-type: none
      }

      ul.lst-kix_list_12-3 {
        list-style-type: none
      }

      ul.lst-kix_list_12-2 {
        list-style-type: none
      }

      ul.lst-kix_list_12-1 {
        list-style-type: none
      }

      ul.lst-kix_list_12-0 {
        list-style-type: none
      }

      ul.lst-kix_list_12-8 {
        list-style-type: none
      }

      ul.lst-kix_list_12-7 {
        list-style-type: none
      }

      ol.lst-kix_list_8-0.start {
        counter-reset: lst-ctn-kix_list_8-0 0
      }

      .lst-kix_list_7-0>li:before {
        content: ""counter(lst-ctn-kix_list_7-0, lower-latin) ") "
      }

      .lst-kix_list_13-8>li {
        counter-increment: lst-ctn-kix_list_13-8
      }

      .lst-kix_list_2-6>li:before {
        content: "\x025cf   "
      }

      ol.lst-kix_list_13-8 {
        list-style-type: none
      }

      .lst-kix_list_2-4>li:before {
        content: "o  "
      }

      .lst-kix_list_2-8>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_7-1>li:before {
        content: "\x025a1   "
      }

      .lst-kix_list_7-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_13-5>li {
        counter-increment: lst-ctn-kix_list_13-5
      }

      ol.lst-kix_list_13-4 {
        list-style-type: none
      }

      ol.lst-kix_list_13-5 {
        list-style-type: none
      }

      ol.lst-kix_list_13-6 {
        list-style-type: none
      }

      ol.lst-kix_list_13-7 {
        list-style-type: none
      }

      ol.lst-kix_list_13-1 {
        list-style-type: none
      }

      ol.lst-kix_list_13-2 {
        list-style-type: none
      }

      .lst-kix_list_7-3>li:before {
        content: "\x025cf   "
      }

      ol.lst-kix_list_13-3 {
        list-style-type: none
      }

      ul.lst-kix_list_7-5 {
        list-style-type: none
      }

      .lst-kix_list_10-0>li:before {
        content: "\x02b9a   "
      }

      ul.lst-kix_list_7-6 {
        list-style-type: none
      }

      ul.lst-kix_list_7-3 {
        list-style-type: none
      }

      ul.lst-kix_list_7-4 {
        list-style-type: none
      }

      .lst-kix_list_13-6>li:before {
        content: ""counter(lst-ctn-kix_list_13-6, decimal) ". "
      }

      .lst-kix_list_13-6>li {
        counter-increment: lst-ctn-kix_list_13-6
      }

      .lst-kix_list_13-8>li:before {
        content: ""counter(lst-ctn-kix_list_13-8, lower-roman) ". "
      }

      ul.lst-kix_list_7-7 {
        list-style-type: none
      }

      ul.lst-kix_list_7-8 {
        list-style-type: none
      }

      ul.lst-kix_list_7-1 {
        list-style-type: none
      }

      ul.lst-kix_list_7-2 {
        list-style-type: none
      }

      .lst-kix_list_7-7>li:before {
        content: "o  "
      }

      ol.lst-kix_list_8-1.start {
        counter-reset: lst-ctn-kix_list_8-1 0
      }

      .lst-kix_list_15-4>li:before {
        content: "o  "
      }

      .lst-kix_list_15-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_10-4>li:before {
        content: "o  "
      }

      .lst-kix_list_10-8>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_4-0>li:before {
        content: "\x025a1   "
      }

      ul.lst-kix_list_15-3 {
        list-style-type: none
      }

      ul.lst-kix_list_15-2 {
        list-style-type: none
      }

      .lst-kix_list_15-0>li:before {
        content: "\x02022   "
      }

      ul.lst-kix_list_15-1 {
        list-style-type: none
      }

      .lst-kix_list_15-8>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_15-0 {
        list-style-type: none
      }

      .lst-kix_list_10-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_13-7>li {
        counter-increment: lst-ctn-kix_list_13-7
      }

      .lst-kix_list_4-4>li:before {
        content: "o  "
      }

      ul.lst-kix_list_15-8 {
        list-style-type: none
      }

      .lst-kix_list_4-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_4-6>li:before {
        content: "\x025cf   "
      }

      ul.lst-kix_list_15-7 {
        list-style-type: none
      }

      ul.lst-kix_list_15-6 {
        list-style-type: none
      }

      .lst-kix_list_9-3>li:before {
        content: "\x025cf   "
      }

      ul.lst-kix_list_15-5 {
        list-style-type: none
      }

      ul.lst-kix_list_15-4 {
        list-style-type: none
      }

      ol.lst-kix_list_7-0.start {
        counter-reset: lst-ctn-kix_list_7-0 0
      }

      .lst-kix_list_15-2>li:before {
        content: "\x025aa   "
      }

      ol.lst-kix_list_13-2.start {
        counter-reset: lst-ctn-kix_list_13-2 0
      }

      .lst-kix_list_10-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_9-1>li:before {
        content: ""counter(lst-ctn-kix_list_9-1, lower-latin) ") "
      }

      .lst-kix_list_9-7>li:before {
        content: "o  "
      }

      .lst-kix_list_11-4>li:before {
        content: "\x1f8ad   "
      }

      .lst-kix_list_12-4>li:before {
        content: "o  "
      }

      .lst-kix_list_9-5>li:before {
        content: "\x025aa   "
      }

      ul.lst-kix_list_6-6 {
        list-style-type: none
      }

      ul.lst-kix_list_6-7 {
        list-style-type: none
      }

      ul.lst-kix_list_6-4 {
        list-style-type: none
      }

      ul.lst-kix_list_6-5 {
        list-style-type: none
      }

      ul.lst-kix_list_6-8 {
        list-style-type: none
      }

      .lst-kix_list_12-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_11-6>li:before {
        content: "o  "
      }

      .lst-kix_list_1-0>li:before {
        content: "\x025a1   "
      }

      ul.lst-kix_list_6-2 {
        list-style-type: none
      }

      .lst-kix_list_11-8>li:before {
        content: "\x1f8ad   "
      }

      ul.lst-kix_list_6-3 {
        list-style-type: none
      }

      .lst-kix_list_1-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_12-0>li:before {
        content: "-  "
      }

      .lst-kix_list_1-4>li:before {
        content: "o  "
      }

      .lst-kix_list_13-0>li:before {
        content: "\x02022   "
      }

      ul.lst-kix_list_14-4 {
        list-style-type: none
      }

      ul.lst-kix_list_14-3 {
        list-style-type: none
      }

      ul.lst-kix_list_14-2 {
        list-style-type: none
      }

      .lst-kix_list_13-4>li:before {
        content: ""counter(lst-ctn-kix_list_13-4, lower-latin) ". "
      }

      ul.lst-kix_list_14-1 {
        list-style-type: none
      }

      ul.lst-kix_list_14-0 {
        list-style-type: none
      }

      .lst-kix_list_1-6>li:before {
        content: "\x025cf   "
      }

      ul.lst-kix_list_14-8 {
        list-style-type: none
      }

      ul.lst-kix_list_14-7 {
        list-style-type: none
      }

      .lst-kix_list_2-0>li:before {
        content: "\x025a1   "
      }

      .lst-kix_list_12-6>li:before {
        content: "\x025cf   "
      }

      ul.lst-kix_list_14-6 {
        list-style-type: none
      }

      ul.lst-kix_list_14-5 {
        list-style-type: none
      }

      .lst-kix_list_1-8>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_2-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_13-2>li:before {
        content: ""counter(lst-ctn-kix_list_13-2, lower-roman) ". "
      }

      .lst-kix_list_12-8>li:before {
        content: "\x025aa   "
      }

      ol {
        margin: 0;
        padding: 0
      }

      table td,
      table th {
        padding: 0
      }

      .c10 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 174.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c30 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 174.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c52 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 66.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c13 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 94.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c8 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 66.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c15 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 94.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c50 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 5.5pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c53 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 700;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 14pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c35 {
        -webkit-text-decoration-skip: none;
        color: #0000ff;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c29 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 700;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 11pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c32 {
        -webkit-text-decoration-skip: none;
        color: #000000;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 12pt;
        font-family: "Arial";
        font-style: normal
      }

      .c7 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 700;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 8pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c20 {
        -webkit-text-decoration-skip: none;
        color: #03308b;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 8pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c56 {
        -webkit-text-decoration-skip: none;
        color: #0000ff;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 12pt;
        font-family: "Arial";
        font-style: normal
      }

      .c18 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify;
        margin-right: -7.2pt;
        height: 10pt
      }

      .c2 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      .c14 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 9pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c3 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 10pt
      }

      .c12 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Arial";
        font-style: normal
      }

      .c31 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c4 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c16 {
        color: #000080;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c58 {
        color: #002060;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6pt;
        font-family: "Arial Black";
        font-style: normal
      }

      .c37 {
        color: #0000ff;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c38 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c0 {
        color: #002060;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Arial Black";
        font-style: normal
      }

      .c5 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Arial";
        font-style: normal
      }

      .c39 {
        color: #000080;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c54 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 7pt;
        font-family: "Arial";
        font-style: normal
      }

      .c9 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: justify
      }

      .c26 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      .c17 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: left;
        height: 10pt
      }

      .c1 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: center
      }

      .c42 {
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto
      }

      .c55 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: right
      }

      .c44 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.5;
        text-align: justify
      }

      .c21 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: justify
      }

      .c19 {
        orphans: 2;
        widows: 2;
        height: 10pt
      }

      .c11 {
        height: 10pt
      }

      .c23 {
        height: 10pt
      }

      .c48 {
      }

      .c36 {
        color: inherit;
        text-decoration: inherit
      }

      .c33 {
      }

      .c28 {
        orphans: 2;
        widows: 2
      }

      .c46 {
      }

      .c27 {
        margin-right: -5.4pt;
        height: 10pt
      }

      .c34 {
        height: 10pt
      }

      .c45 {
      }

      .c40 {
      }

      .c41 {
        max-width: 467.8pt;
        padding: 51pt 70.8pt 56.7pt 56.7pt
      }

      .c43 {
        height: 13.8pt
      }

      .c57 {
      }

      .c22 {
      }

      .c49 {
        margin-right: 14.2pt
      }

      .c25 {
      }

      .c24 {
        background-color: #ffffff
      }

      .c47 {
        height: 10pt
      }

      .c51 {
      }

      .c6 {
        height: 42pt
      }

      .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1.0;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      li {
        color: #000000;
        font-size: 10pt;
        font-family: "Times New Roman"
      }

      p {
        margin: 0;
        color: #000000;
        font-size: 10pt;
        font-family: "Times New Roman"
      }

      h1 {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 24pt;
        padding-bottom: 6pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h2 {
        padding-top: 18pt;
        color: #000000;
        font-weight: 700;
        font-size: 18pt;
        padding-bottom: 4pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h3 {
        padding-top: 14pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h4 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 2pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h5 {
        padding-top: 11pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 2pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h6 {
        padding-top: 10pt;
        color: #000000;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }
    </style>
  </head>
  <body class="c4 c8 doc-content">
  <div style="border:1px solid transparent; margin-top: 20px; height: 750px; padding-left: 35px; padding-right: 35px;" class="page html2pdf__page-break">
    <div>
      <div style="width: 100%; display: block; margin: 0 auto; text-align:center">
            <img src="{logo}" style="width: 150px" crossorigin="*"/>
        </div>
    </div>
    <div style="float:right; width:100%;text-align: right; padding-top: 30px; padding-bottom: 30px">
         <strong>{intestazione_capitaneria}
    </strong></div>
   
   
    <p class="c21 c23">
      <span class="c5"></span>
    </p>
    <p class="c21">
      <span class="c5"></span>
    </p>
    <p class="c29 c32">
      <strong class="c5" style="font-weight: bold; margin-top: 80px">Argomento: &nbsp;Invio Calendario settimanale relativo per esami relativi al periodo dal {from} al {to}.</strong>
    </p>
    
    <p class="c21 c25">
      <span class="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </p>
    <p class="c21 c47 c48">
      <span class="c5"></span>
    </p>
    <p class="c44 c33 c47">
      <span class="c5"></span>
    </p>
    <p class="c44 c33">
      <span class="c5">Si trasmette, in allegato, il calendario settimanale suddiviso per ogni singolo corso (comprensivo dei refresh), autorizzato da Codesto Comando generale riferito al periodo dal {from} al {to}</span>
    </p>
    <p class="c33 c44">
      <span class="c5">Si precisa, altresì che il calendario mensile qualora dovesse subire eventuali modifiche/integrazioni, si procederà alle successive comunicazioni in merito. </span>
    </p>
    <p class="c21 c34">
      <span class="c5"></span>
    </p>
     <p class="c21">
      <span class="c5">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Il Legale Rappresentante</span>
    </p>
    <p class="c21 c34">
      <span class="c5"></span>
    </p>
    <p class="c21" style="text-align: right"><span class="c5">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; _____________________________ </span></p>
    <p class="c21 c22" style="text-align: right">
      <span class="c54">(Timbro - firma autografa leggibile e per esteso)</span>
      <span class="c5"></span>
    </p>
    
    <div style="margin-top: 120px">
        <p style="margin: 0 auto; padding-top: 10px; text-align: center; font-size: 10px">{school_name} SRL, {legal_address} - Tel. {phone} P.IVA: {piva} <br/> Sito web: {website}</p>
    </div>
    
    </div>
    <div class="page html2pdf__page-break">
          <div>
            <div style="width: 100%; display: block; margin: 0 auto; text-align:center">
                  <img src="{logo}" style="width: 150px" crossorigin="*"/>
              </div>
          </div>
          <p class="c1" style="padding-top: 24px; padding-bottom: 24px"><span class="c12">CALENDARIO ESAMI SETTIMANALI MESE DI “{month}” <br/> SETTIMANA DAL {from} AL {to}</span></p>
          <table class="c42">
          <tbody>
              <tr class="c43">
                <td class="c15" colspan="1" rowspan="1">
                    <p class="c1"><span class="c12">GIORNO E DATA</span></p>
                </td>
                <td class="c15" colspan="1" rowspan="1">
                    <p class="c1"><span class="c12">CORSO</span></p>
                </td>
                <td class="c15" colspan="1" rowspan="1">
                    <p class="c1"><span class="c12">ORARIO</span></p>
                </td>
                <td class="c52" colspan="1" rowspan="1">
                    <p class="c1"><span class="c12">SEDE D'ESAME</span></p>
                </td>
                <td class="c52" colspan="1" rowspan="1">
                    <p class="c1"><span class="c12">CAPACITA AULA</span></p>
                </td>
                <td class="c30" colspan="1" rowspan="1">
                    <p class="c1"><span class="c12">DATA ESAME</span></p>
                </td>
              </tr>
              {righe}
            </tbody>
          </table>
          <div style="margin-top: 120px">
              <p style="margin: 0 auto; padding-top: 10px; text-align: center; font-size: 10px">{school_name} SRL, {legal_address} - Tel. {phone} P.IVA: {piva} <br/> Sito web: {website}</p>
          </div>
    </div>
  </body>
</html>`

const singleCourse = () => (`
    <tr class="c6">
      <td class="c13" colspan="1" rowspan="1">
          <p class="c1 c27"><span class="c5">{day_date}</span></p>
      </td>
      <td class="c13" colspan="1" rowspan="1">
          <p class="c1 c27"><span class="c5">{course_type}</span></p>
      </td>
      <td class="c13" colspan="1" rowspan="1">
          <p class="c1 c27"><span class="c5">{hour}</span></p>
      </td>
       <td class="c10" colspan="1" rowspan="1">
          <p class="c1 c11"><span class="c5">{aula_esame}</span></p>
      </td>
      <td class="c8" colspan="1" rowspan="1">
          <p class="c1 c23"><span class="c5">22 PP</span></p>
      </td>
      <td class="c10" colspan="1" rowspan="1">
          <p class="c1 c11"><span class="c5">{day}</span></p>
      </td>
    </tr>
`)
function _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
       binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }
const savePdf = async (payload) => {
    
    // const mappa = lessons.map((x, i) => singleCourseTemplate.replaceAll('{no}', i + 1).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    // const practicalMap = lessons.filter(x => x.pratica).map((x, i) => Practical.replaceAll('{name}', `Lezione nr. ${i + 1}`).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    html2pdf()
    .set({
        filename: 'scheda_ammissione.pdf',
        margin: 20,
        html2canvas: { useCORS: true },
    })
    .from(Libretto.replaceAll('{docente}', payload.teacher)
            .replaceAll('{intestazione_capitaneria}', payload.settings.capitaneria.replaceAll('\\n', '<br/>'))
            .replaceAll('{direttore}', payload.director)
            .replaceAll('{nome_corso}', payload.nome_corso)
            .replaceAll('{logo}', payload.settings?.logo_top ? payload.settings?.logo_top : payload.school.logo)
            .replaceAll('{autorita_marittima}', payload.course.dataValues.autorita_marittima)
            .replaceAll('{row_number}', payload.rowNumber + 1)
            .replaceAll('{ID}', payload.id ? payload.id : `2024/${payload.nome_corso}/1`)
            .replaceAll('{number}', payload.number)
            .replaceAll('{school_name}', `${payload.school.name}`)
            .replaceAll('{legal_address}', payload.school.sede_legale)
            .replaceAll('{phone}', `+39 ${payload.school.telefono}`)
            .replaceAll('{website}', payload.school.url)
            .replaceAll('{piva}', payload.school.piva)
            .replaceAll('{month}', payload.month)
            .replaceAll('{exam_day}', `${moment(payload.course.dataValues.to).add(1, 'days').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').format('DD/MM/YYYY')}`)
            .replaceAll('{docente}', payload.teacher)
            .replaceAll('{from}', moment(payload.from).format('DD/MM/YYYY'))
            .replaceAll('{to}', moment(payload.end).format('DD/MM/YYYY'))
            .replace('{righe}', payload.courses.map(x => singleCourse()
                                .replaceAll('{day_date}', moment(x['data_esame'], 'DD/MM/YYYY').format('dddd DD/MM/YYYY').toUpperCase())
                                .replaceAll('{course_type}', x['nome corso'])
                                .replaceAll('{hour}', x['ora_esame'])
                                .replaceAll('{day}', moment(x['data_esame'], 'DD/MM/YYYY').format('DD/MM/YYYY'))
                                .replaceAll('{aula_esame}', `${x['aula_esame']}`)
                              ))
            ).toPdf().get('pdf').then(pdf => {
        window.open(pdf.output('bloburl'), '_blank');
    })
}
export { Libretto, savePdf }