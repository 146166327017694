import React from 'react';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';

const SingleRow = (partecipant) =>`
        <tr style="border: 1px solid black; border-collapse:collapse">
            <td style="width: 100px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{id_matricola}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{last_name}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{first_name}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birth_place}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birth_province}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birthday}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{matricola}/{compartment}</td>
        </tr>
        `
const Libretto = `<html><head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
      @import url(https://themes.googleusercontent.com/fonts/css?kit=DtPsxHYDxQWpO8ASIGu_ew0oAUP_eRtG2f1I1nZF09YS0-eybaQSkqpYUibASASI_37GXcCmZhAvVrsFphImmA);

      .lst-kix_list_4-1>li {
        counter-increment: lst-ctn-kix_list_4-1
      }

      .lst-kix_list_1-1>li {
        counter-increment: lst-ctn-kix_list_1-1
      }

      .lst-kix_list_3-0>li:before {
        content: "o  "
      }

      .lst-kix_list_3-1>li:before {
        content: "o  "
      }

      .lst-kix_list_3-2>li:before {
        content: "\x025aa   "
      }

      ol.lst-kix_list_1-8.start {
        counter-reset: lst-ctn-kix_list_1-8 0
      }

      .lst-kix_list_3-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_3-4>li:before {
        content: "o  "
      }

      ol.lst-kix_list_1-5.start {
        counter-reset: lst-ctn-kix_list_1-5 0
      }

      .lst-kix_list_3-3>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_3-8>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_3-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_4-3>li {
        counter-increment: lst-ctn-kix_list_4-3
      }

      .lst-kix_list_3-7>li:before {
        content: "o  "
      }

      ol.lst-kix_list_4-5.start {
        counter-reset: lst-ctn-kix_list_4-5 0
      }

      .lst-kix_list_1-2>li {
        counter-increment: lst-ctn-kix_list_1-2
      }

      ol.lst-kix_list_4-2.start {
        counter-reset: lst-ctn-kix_list_4-2 0
      }

      .lst-kix_list_1-4>li {
        counter-increment: lst-ctn-kix_list_1-4
      }

      .lst-kix_list_4-4>li {
        counter-increment: lst-ctn-kix_list_4-4
      }

      ol.lst-kix_list_1-6.start {
        counter-reset: lst-ctn-kix_list_1-6 0
      }

      .lst-kix_list_4-8>li:before {
        content: ""counter(lst-ctn-kix_list_4-8, lower-roman) ". "
      }

      .lst-kix_list_4-7>li:before {
        content: ""counter(lst-ctn-kix_list_4-7, lower-latin) ". "
      }

      ol.lst-kix_list_4-1.start {
        counter-reset: lst-ctn-kix_list_4-1 0
      }

      ol.lst-kix_list_4-8.start {
        counter-reset: lst-ctn-kix_list_4-8 0
      }

      ul.lst-kix_list_4-0 {
        list-style-type: none
      }

      ol.lst-kix_list_1-0.start {
        counter-reset: lst-ctn-kix_list_1-0 0
      }

      ol.lst-kix_list_4-7.start {
        counter-reset: lst-ctn-kix_list_4-7 0
      }

      ol.lst-kix_list_1-3 {
        list-style-type: none
      }

      ol.lst-kix_list_1-4 {
        list-style-type: none
      }

      .lst-kix_list_2-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_2-7>li:before {
        content: "o  "
      }

      ol.lst-kix_list_1-5 {
        list-style-type: none
      }

      ol.lst-kix_list_1-6 {
        list-style-type: none
      }

      ol.lst-kix_list_1-0 {
        list-style-type: none
      }

      .lst-kix_list_2-4>li:before {
        content: "o  "
      }

      .lst-kix_list_2-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_2-8>li:before {
        content: "\x025aa   "
      }

      ol.lst-kix_list_1-1 {
        list-style-type: none
      }

      ol.lst-kix_list_1-2 {
        list-style-type: none
      }

      ul.lst-kix_list_3-7 {
        list-style-type: none
      }

      ul.lst-kix_list_3-8 {
        list-style-type: none
      }

      ol.lst-kix_list_4-6.start {
        counter-reset: lst-ctn-kix_list_4-6 0
      }

      ul.lst-kix_list_3-1 {
        list-style-type: none
      }

      ul.lst-kix_list_3-2 {
        list-style-type: none
      }

      ul.lst-kix_list_3-0 {
        list-style-type: none
      }

      ol.lst-kix_list_4-3.start {
        counter-reset: lst-ctn-kix_list_4-3 0
      }

      ol.lst-kix_list_1-7 {
        list-style-type: none
      }

      ul.lst-kix_list_3-5 {
        list-style-type: none
      }

      .lst-kix_list_4-7>li {
        counter-increment: lst-ctn-kix_list_4-7
      }

      .lst-kix_list_1-7>li {
        counter-increment: lst-ctn-kix_list_1-7
      }

      ol.lst-kix_list_1-8 {
        list-style-type: none
      }

      ul.lst-kix_list_3-6 {
        list-style-type: none
      }

      ul.lst-kix_list_3-3 {
        list-style-type: none
      }

      ul.lst-kix_list_3-4 {
        list-style-type: none
      }

      .lst-kix_list_4-0>li:before {
        content: "\x02022   "
      }

      .lst-kix_list_4-1>li:before {
        content: ""counter(lst-ctn-kix_list_4-1, lower-latin) ") "
      }

      .lst-kix_list_4-6>li {
        counter-increment: lst-ctn-kix_list_4-6
      }

      ol.lst-kix_list_1-7.start {
        counter-reset: lst-ctn-kix_list_1-7 0
      }

      .lst-kix_list_4-4>li:before {
        content: "("counter(lst-ctn-kix_list_4-4, lower-latin) ") "
      }

      .lst-kix_list_1-5>li {
        counter-increment: lst-ctn-kix_list_1-5
      }

      .lst-kix_list_4-3>li:before {
        content: "("counter(lst-ctn-kix_list_4-3, decimal) ") "
      }

      .lst-kix_list_4-5>li:before {
        content: "("counter(lst-ctn-kix_list_4-5, lower-roman) ") "
      }

      .lst-kix_list_4-2>li:before {
        content: ""counter(lst-ctn-kix_list_4-2, lower-roman) ") "
      }

      .lst-kix_list_4-6>li:before {
        content: ""counter(lst-ctn-kix_list_4-6, decimal) ". "
      }

      .lst-kix_list_1-8>li {
        counter-increment: lst-ctn-kix_list_1-8
      }

      ol.lst-kix_list_1-4.start {
        counter-reset: lst-ctn-kix_list_1-4 0
      }

      ol.lst-kix_list_1-1.start {
        counter-reset: lst-ctn-kix_list_1-1 0
      }

      ol.lst-kix_list_4-1 {
        list-style-type: none
      }

      ol.lst-kix_list_4-4.start {
        counter-reset: lst-ctn-kix_list_4-4 0
      }

      ol.lst-kix_list_4-2 {
        list-style-type: none
      }

      ol.lst-kix_list_4-3 {
        list-style-type: none
      }

      ol.lst-kix_list_1-3.start {
        counter-reset: lst-ctn-kix_list_1-3 0
      }

      ul.lst-kix_list_2-8 {
        list-style-type: none
      }

      ol.lst-kix_list_1-2.start {
        counter-reset: lst-ctn-kix_list_1-2 0
      }

      ol.lst-kix_list_4-8 {
        list-style-type: none
      }

      ul.lst-kix_list_2-2 {
        list-style-type: none
      }

      .lst-kix_list_1-0>li:before {
        content: ""counter(lst-ctn-kix_list_1-0, decimal) ". "
      }

      ul.lst-kix_list_2-3 {
        list-style-type: none
      }

      ul.lst-kix_list_2-0 {
        list-style-type: none
      }

      ul.lst-kix_list_2-1 {
        list-style-type: none
      }

      ol.lst-kix_list_4-4 {
        list-style-type: none
      }

      ul.lst-kix_list_2-6 {
        list-style-type: none
      }

      ol.lst-kix_list_4-5 {
        list-style-type: none
      }

      .lst-kix_list_1-1>li:before {
        content: ""counter(lst-ctn-kix_list_1-1, lower-latin) ". "
      }

      .lst-kix_list_1-2>li:before {
        content: ""counter(lst-ctn-kix_list_1-2, lower-roman) ". "
      }

      ul.lst-kix_list_2-7 {
        list-style-type: none
      }

      ol.lst-kix_list_4-6 {
        list-style-type: none
      }

      ul.lst-kix_list_2-4 {
        list-style-type: none
      }

      ol.lst-kix_list_4-7 {
        list-style-type: none
      }

      ul.lst-kix_list_2-5 {
        list-style-type: none
      }

      .lst-kix_list_1-3>li:before {
        content: ""counter(lst-ctn-kix_list_1-3, decimal) ". "
      }

      .lst-kix_list_1-4>li:before {
        content: ""counter(lst-ctn-kix_list_1-4, lower-latin) ". "
      }

      .lst-kix_list_1-0>li {
        counter-increment: lst-ctn-kix_list_1-0
      }

      .lst-kix_list_4-8>li {
        counter-increment: lst-ctn-kix_list_4-8
      }

      .lst-kix_list_1-6>li {
        counter-increment: lst-ctn-kix_list_1-6
      }

      .lst-kix_list_1-7>li:before {
        content: ""counter(lst-ctn-kix_list_1-7, lower-latin) ". "
      }

      .lst-kix_list_1-3>li {
        counter-increment: lst-ctn-kix_list_1-3
      }

      .lst-kix_list_1-5>li:before {
        content: ""counter(lst-ctn-kix_list_1-5, lower-roman) ". "
      }

      .lst-kix_list_1-6>li:before {
        content: ""counter(lst-ctn-kix_list_1-6, decimal) ". "
      }

      li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
      }

      .lst-kix_list_2-0>li:before {
        content: "o  "
      }

      .lst-kix_list_2-1>li:before {
        content: "o  "
      }

      .lst-kix_list_4-5>li {
        counter-increment: lst-ctn-kix_list_4-5
      }

      .lst-kix_list_1-8>li:before {
        content: ""counter(lst-ctn-kix_list_1-8, lower-roman) ". "
      }

      .lst-kix_list_2-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_2-3>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_4-2>li {
        counter-increment: lst-ctn-kix_list_4-2
      }

      ol {
        margin: 0;
        padding: 0
      }

      table td,
      table th {
        padding: 0
      }

      .c55 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 33.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c79 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 10.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c122 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 0.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c7 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 103.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c52 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c87 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 160.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c76 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 164.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c75 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 103.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c26 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c4 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 54.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c3 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c84 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 33.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c14 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 177.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c13 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c70 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 10.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c69 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 100.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c60 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 164.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c114 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 74.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c110 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c108 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 474.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c93 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 103.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c71 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 474.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c19 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 2.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c36 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 100.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c103 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 1pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c86 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 474.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c51 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 2.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c77 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 39pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c89 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 205.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c12 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c59 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 138.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c99 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 175.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c40 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c9 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c118 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c94 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 119.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c88 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 86pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c115 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 239.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c117 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 10.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c120 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 39pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c44 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 103.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c102 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 205.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c11 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 93.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c81 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c20 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c113 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 0.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c124 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 144.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c6 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 175.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c83 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 111pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c16 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 39pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c57 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 175.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c64 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 93.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c48 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 239.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c98 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c112 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 1.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c107 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 1.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c80 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 444.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c73 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c1 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 175.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c123 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 2.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c74 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 33.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c97 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 100.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c17 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 100.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c10 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 33.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c49 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 86pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c31 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 2.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c35 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 193.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c15 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 10.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c39 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 139.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c46 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c61 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c78 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 175.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c111 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 39pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c27 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 105.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c85 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c58 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 39pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c42 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 700;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 14pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c82 {
        -webkit-text-decoration-skip: none;
        color: #03308b;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 8pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c68 {
        -webkit-text-decoration-skip: none;
        color: #0000ff;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c91 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 700;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 8pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c104 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 5.5pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c22 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 700;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 11pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c25 {
        margin-left: -5pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: center;
        height: 10pt
      }

      .c53 {
        color: #c00000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal
      }

      .c121 {
        color: #002060;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Arial Black";
        font-style: normal
      }

      .c33 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c34 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal
      }

      .c0 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal
      }

      .c96 {
        color: #002060;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6pt;
        font-family: "Arial Black";
        font-style: normal
      }

      .c23 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: center;
        height: 10pt
      }

      .c50 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 9pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c105 {
        color: #c00000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal
      }

      .c106 {
        color: #0000ff;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c24 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c66 {
        color: #000080;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c45 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c101 {
        color: #ff0000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal
      }

      .c43 {
        color: #000080;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c116 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Arial";
        font-style: normal
      }

      .c41 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      .c2 {
        padding-top: 3pt;
        padding-bottom: 3pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      .c5 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        text-align: left;
        height: 10pt
      }

      .c18 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: left;
        height: 10pt
      }

      .c63 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: justify
      }

      .c29 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: center
      }

      .c30 {
        margin-left: -5.4pt;
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto
      }

      .c92 {
        margin-left: -5.8pt;
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto
      }

      .c28 {
        padding-top: 3pt;
        padding-bottom: 3pt;
        line-height: 1.0;
        text-align: left
      }

      .c56 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: center
      }

      .c72 {
        background-color: #ffffff;
        max-width: 467.8pt;
        padding: 51pt 70.8pt 56.7pt 56.7pt
      }

      .c38 {
        margin-left: -5pt;
        orphans: 2;
        widows: 2
      }

      .c54 {
        color: inherit;
        text-decoration: inherit
      }

      .c32 {
        margin-left: 36pt;
        padding-left: 0pt
      }

      .c21 {
        padding: 0;
        margin: 0
      }

      .c47 {
        orphans: 2;
        widows: 2
      }

      .c62 {
        height: 6.7pt
      }

      .c109 {
        height: 33.9pt
      }

      .c37 {
        height: 10pt
      }

      .c8 {
        height: 0pt
      }

      .c119 {
        margin-left: -5pt
      }

      .c67 {
        height: 7.5pt
      }

      .c100 {
        height: 11.5pt
      }

      .c95 {
        height: 23.1pt
      }

      .c90 {
        height: 44.9pt
      }

      .c65 {
        height: 28pt
      }

      .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1.0;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      li {
        color: #000000;
        font-size: 10pt;
        font-family: "Times New Roman"
      }

      p {
        margin: 0;
        color: #000000;
        font-size: 10pt;
        font-family: "Times New Roman"
      }

      h1 {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 24pt;
        padding-bottom: 6pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h2 {
        padding-top: 18pt;
        color: #000000;
        font-weight: 700;
        font-size: 18pt;
        padding-bottom: 4pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h3 {
        padding-top: 14pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h4 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 2pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h5 {
        padding-top: 11pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 2pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h6 {
        padding-top: 10pt;
        color: #000000;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }
    </style>
  </head>
  <body class="c72 doc-content">
    <div style="width: 100%; display: block; margin: 0 auto; text-align:center">
                    <img src="{logo}" crossorigin="*" style="height: 125px; object-fit: contain;">

    </div>
    <p class="c5">
      <span class="c116"></span>
    </p>
    <table class="c30" style="margin: 0 auto">
      <tbody>
        <tr class="c8">
          <td class="c89" colspan="2" rowspan="1">
            <p class="c29">
              <span class="c34">{school_name}</span>
            </p>
            <p class="c29">
              <span class="c105">{nome_corso}</span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c115" colspan="2" rowspan="1">
            <p class="c28">
              <span class="c34">Verbale di esame n</span>
              <span class="c0">.{id}/{number}</span>
            </p>
            <p class="c28">
              <span class="c34">Stampato alle ore</span>
              <span class="c0">&nbsp;{date} </span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c102" colspan="2" rowspan="1">
            <p class="c29 c37">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c48" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c95">
          <td class="c94" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c0">Cognome ___________________</span>
            </p>
          </td>
          <td class="c87" colspan="2" rowspan="1">
            <p class="c28">
              <span class="c0">Nome _____________________</span>
            </p>
          </td>
          <td class="c60" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c0">Data Esame _________</span>
            </p>
          </td>
        </tr>
        <tr class="c65">
          <td class="c80" colspan="4" rowspan="1">
            <p class="c29 c37">
              <span class="c0"></span>
            </p>
            <p class="c29">
              <span class="c0">Firma Discente _________________</span>
            </p>
            <p class="c29">
              <span class="c0">(firma autografa leggibile e per esteso)</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="c2 c37">
      <span class="c0"></span>
    </p>
    <br><br><table class="c92" style="margin: 0 auto">
      <tbody>
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        <tr class="c8 here">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="62">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">La cocaina può essere assunta saltuariamente ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Solo se lo prescrive il medico</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Dipende dalle circostanze</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">No, mai</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Solo se lo decide il Com.te</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="63">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Come viene chiamato il luogo dove l'equipaggio preposto si dovrà recare dopo la segnalazione dell'Emergenza Generale</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Sala riunione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Punto di raccolta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Punto di incontro</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Punto di riunione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Centro collettivo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="64">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Per quanto tempo si dovranno tenere a bordo, sigillati e siglati, i campioni del bunker imbarcato?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) 6 mesi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) 8 mesi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) 1 anno</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) 2 anni</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) 3 anni</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="65">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Di che grandezza devono essere le maglie del setaccio di cui si dovranno filtrare i rifiuti solidi alimentari triturati ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) 1cm</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) 2,5cm</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) 3cm</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) 3,5cm</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) le risposte sono sbagliate</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="66">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Di quanto dovrà essere la percentuale di zolfo presente nel combustibile da bruciare per i motori della propulsione delle navi ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) 0.5</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) 2,5</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) 3,5</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) 5</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) 20</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="67">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Con che lettera vengono classificate le sostanze tossiche nocive altamente pericolose ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) z</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) k</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) y</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) x</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) j</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="68">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">A quanti ppm è concesso lo scarico dell'acqua decantata nella SLOP TK di una nave petroliera ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a)</span>
              <span class="c24">&nbsp;inferiore a 10 ppm</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">inferiore a 20 ppm</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">inferiore a 15 ppm</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Superiore a 15 ppm</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Tutte le risposte sono sbagliate</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="69">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Cosa è l' ODMCS ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Una convenzione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Un dispositivo analizzatore</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Una articolo della SOLAS</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Una organizzazione internazionale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Un diritto del marittimo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="70">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quale deve essere la velocità da mantenere durante lo scarico dell'acqua decantata dalla SLOP TANK ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a)</span>
              <span class="c24">&nbsp;Inferiore a 7 nodi</span>
              <span class="c0">&nbsp;</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Superiore a 7 nodi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Tra 7 nodi e 8 nodi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Superiore a 5 nodi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">nessun limite</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="71">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Cosa è necessario verificare prima di entrare in uno spazio chiuso ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c34">Risposte </span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Presenza di elevata percentuale di umitida e/o calore al fine di evitare malori all'interno del locale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Presenza di valori tossici inferiori al 3% o miscele esplosive superiori al 21%,persona in assistenza,pronta disponibilita' cassetta pronto soccorso e che il comandante sia prontamente reperibile al numero di emergenza previsto dal sistema SMS</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Presenza di eccessivi livelli di rumorosita'</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Controllare l'atmosfera nel locale con apposito rilevatore, assicurarsi che il locale sia stato opportunamente ventilato, e di essere in possesso di permesso di entrata come previsto dal sistema SMS</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Presenza di maschera antigas in prossimita' del locale e di un estintore prontameante disponibile unitamente al permesso di entrata come previsto dal sistema SMS</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="72">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Che cosa si intende per lavoro a caldo ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Lavorazioni effettuati durante le ore di massima esposizione solare</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Uso di lampade a raggi infrarossi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Uso di lampade alogene con il rischio di surriscaldamento</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Qualsiasi lavoro che generi calore e scintille in grado di provocare l’accensione di una miscela d’aria od altro materiale infiammabile.</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Lavori solo con saldatrici elettriche</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="73">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Che cosa significa "Basic Training"</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Training fisico da compiere per non aver disturbi a bordo le navi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Training psicologico per sopportare il mal di mare</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Esercizi basilari che tutti I naviganti debbono compiere quotidianamente</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Addestramento basico e certificazione di idoneita' alla navigazione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Insieme dei cinque corsi base e della relativa certificazione che ogni marittimo deve avere prima di poter imbarcare per la prima volta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="74">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quali gravi effetti dannosi provoca l'uso dell'alcool e della droga per la sicurezza della nave ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) i</span>
              <span class="c24">l rischio di una continua esigenza di alimentarsi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Il rischio di disidratazione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Il rischio di avere necessita' di mangiare molti dolci</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Alito cattivo e intensa sudorazione con impatto negativo sull'atmosfera di bordo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Nessuno dei precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="75">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quali sono i dati da registrare sull"Oil Record Book Parte I?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Posizione nave, data e ora di avvio scarico attraverso il separatore di acque oleose</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Posizione nave, data e ora durante il lavaggio delle cisterne</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Posizione nave,data e ora dell'utilizzo dei depuratori di olio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Il registro dove sono marcati gli oli della cucina</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Il registro dove sono marcati le vernici che sono imbarcate con componenti oleose</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="76">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Che tipo di scarpe devono essere indossate a bordo durante i servizi in coperta e macchina ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Qualsiasi tipo di scarpa con suola in gomma antisdrucciolo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Scarpe confortevoli tipo ginnastica</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Scarpe di sicurezza (antinfortunistica)</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Ciabatte infradito o comunque scarpe aperte che possono essere rimosse facilmente in caso di necessita'</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Per I servizi di coperta non sono previste scarpe particolari,per I servizi in macchina scarpe resistenti all'olio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="77">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Che cosa si intende per "Muster Station" o Punto di Riunione a bordo di una nave?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">La zona dove si riunisce normalmente l'equipaggio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b)</span>
              <span class="c24">&nbsp;Il punto dove viene fatto l'appello prima di partire</span>
              <span class="c0">&nbsp;</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Il punto che l'equipaggio e I passeggeri devono raggiungere solo in casi di incendio grave a bordo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Un luogo dove i passeggeri e l'equipaggio devono radunarsi in caso di emergenza generale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e)</span>
              <span class="c24">&nbsp;La zona dove si riunisce il comandante con gli ufficiali</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="78">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quali tra le seguenti affermazioni è vera ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Nessun tipo di rifiuto puo' essere conferito a mare</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Solo I rifiuti oleosi possono essere conferiti a mare al di fuori delle acque territoriali</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Possono essere conferiti a mare I rifiuti alimentari non triturati ad una distanza superiore alle 12 miglia nelle aree speciali e non, e ad una distanza superiore alle 3 miglia all'esterno delle aree speciali triturate e passate a setaccio, avente maglie di 2,5 cm</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Possono essere conferiti a mare I rifiuti plastici ad una distanza superiore alle 200 miglia dalla costa fuori delle aree speciali e di 400 miglia all'interno delle aree speciali e comunque fuori del mar Mediterraneo secondo quanto previsto dalla MARPOL</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Possono essere conferiti a mare I rifiuti chimici non nocivi alla salute umana ad una distanza superiore alle 200 miglia al di fuori delle aree speciali</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="79">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Cosa si intende per comunicazione non verbale ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c33">La comunicazione scritta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c33">La comunicazione che non prevede l'utilizzo di verbi per rendere la comunicazione piu' rapida e efficace</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c33">Tutto quel complesso di segnali, gesti, movimenti del corpo, posture, espressioni del volto, direzione dello sguardo, vicinanza e posizione spaziale, contatto corporeo, orientazioni ed altri aspetti non verbali del discorso, abiti e ornamenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c33">La comunicazione tramite le apparecchiature radio di bordo della stazione GMDSS</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c33">La comunicazione prevista in situazioni di emergenza</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="80">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quali precauzioni devono essere adottate se si lavora in un ambiente rumoroso per evitare danni all'udito ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Ascoltare musica ad alto volume per coprire il rumore</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Indossare un paraorecchie di lana</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Se per un pericolo limitato non e' necessaria alcuna precauzione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Usare cuffie o tappi auricolari</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Usare un elmetto protettivo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="81">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quali rischi sono connessi all'atmosfera in uno spazio chiuso</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Che essa può essere povera o troppo ricca di ossigeno, ossica e/o infiammabile</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Che aumenti il tasso di azoto a parita' di ossigeno respirabile</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Che la percentuale di anidride carbonica sia prossima allo zero</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Che sia tossica a causa di una percentuale di ossigeno prossima al 21%</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Che possa essere asfittica e quindi potenzialmente esplosiva</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="82">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quale tra i seguenti comportamenti deve essere adottato nella fase iniziale della caduta di un uomo a mare?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">1 - Annotare posizione e ora; 2 - Sganciare il MoB sul lato dell'accaduto; - 3 - Suonare tre fischi prolungati; 4 - Iniziare la manovra di recupero</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">1 - Urlare uomo a mare a dritta o sinistra; 2 - Chaimare il nostromo; - 3 - Suonare tre fischi prolungati; 4 - Informare il Com.te</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">1 - Informare il Com.te; 2 - Iniziare la manovra di recupero; - 3 - Suonare un fischio prolungato; 4 - Sganciare MoB</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">1 - Informare il Com.te; 2 - Chaimare il nostromo; - 3 - Suonare un fischio prolungato; 4 - Iniziare la manovra di recupero</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Nessuna delle precedenti risposte è quella giusta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="83">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quali precauzioni adottare prima di effettuare lavori a caldo come l'uso di fiamma ossidrica ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Evitare di effettuare lavorazioni a caldo durante le ore di massima esposizione solare per impedire un eccessivo riscaldamento nella fase di lavorazione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">1 - Permesso di lavoro con relativo piano; 2 - Designare un responsabile che non partecipi al lavoro; 3 - Utilizzo di indumenti protettivi idonei; 4 - presenza della protezione incendio; 5 - Isolamento dell' area di lavoro</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c)</span>
              <span class="c24">&nbsp;Devono essere sempre preventivamente autorizzati dai Vigili del Fuoco</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Indossare sempre la cintura di salvataggio durante la fase di esecuzione dei lavori ai fini di poter prontamente imbarcare sui mezzi collettivi di salvataggio in caso di emergenza</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e)</span>
              <span class="c24">&nbsp;Verificare che le via di fuga sia opportunamente segnalate prima dell'inizio della lavorazione</span>
              <span class="c0">&nbsp;</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="84">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Che cosa e' il pozzo della catena ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Locale dove viene raccolta la catena dell'ancora</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">locale dove alloggia l'ancora con tutta la sua catena</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Locale dove si raccolgono le catene e cavi di acciaio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Locale dove si raccolgono cavi e catene di ormeggio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Locale dove si deposita tutto tranne che la catena</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="85">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Che cosa e' la chiglia ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c29 c38">
              <span class="c34">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">E' la spina dorsale della nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">E' la linea di riferimento del ponte di coperta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">E' la struttura di supporto del ponte di coperta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">E' la linea di riferimento della marca di bordo libero</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">E' la linea perimetrale dello scafo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="86">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quale è la convenzione internazionale che tratta della salvaguardia della vita umana in mare?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) marpol</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) load line</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) imo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) solas</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) stcw</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="87">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quali tra i seguenti non è un mezzo collettivo di salvataggio</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Cintura di salvataggio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Rescue boat</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Imbarcazione di salvataggio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Zattera di salvataggio ammainabile</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Zattere di salvataggio lanciabili</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="88">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Che cosa è una cintura di salvataggio o giubbotto di salvataggio ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">E' una cintura che si gonfia automaticamente a contatto con l'acqua e sostiene in galleggiamento il naufrago in caso di emergenza</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">E' un dispositivo che si utilizza per salvarsi in caso di incendio e deve essere indossato solo in caso di incendio grave a bordo su richiesta del comandante</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">E' una cintura di colore arancione che si utilizza a bordo per rendersi facilmente visibili in particolare per lavori nelle ore notturne nei garage delle navi traghetto</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">E' un mezzo individuale di salvataggio che deve essere presente a bordo per ogni membro dell'equipaggio e passeggero</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">E' una cintura o un giubbotto che deve essere sempre utilizzato per consentire di lavorare in sicurezza</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="89">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Tranne che per le interiore dei pesci a qualsiasi distanza dalla costa e per i rifiuti alimentari ad una distanza superiore alle 12 miglie è fatto divieto di scaricare qualsiasi materiale: RESOLUTION MEPC.295(71) (adopted on 7 July 2017) (MARPOL ANNEX V)</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Dappertutto</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Solo nel Mar Mediterraneo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Nelle aree speciali</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Solo nelle acque polari</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Nessuna delle precedenti risposte è quella giusta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="90">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Perché a 3 miglia dalla costa i rifiuti solidi alimentari oltre ad essere triturati devono anche essere passati per un setaccio con maglie larghe 2,5 cm ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Per trattenere eventuali residui di plastica più grossi del normale.</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Così da accelerare l'assimilazione nell'ambiente marino.</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Perché essendo il fondale più basso si evidenzia di più l'inquinamento</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Per non sporcare coste e spiagge</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Nessuna delle precedenti risposte è quella giusta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="91">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Cosa significa head line foreward ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">cavo alla lunga di poppa</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">cavo alla lunga di prora</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">cavo che esce dall'occhio di panama</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d)</span>
              <span class="c24">&nbsp;cavo posizionato a atravesrino</span>
              <span class="c0">&nbsp;</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">cavo posizionato a spring</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="92">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Ogni Amministrazione deve fissare, ai fini della prevenzione dalla droga e dall'abuso di alcool, un limite non superiore a ...........</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c33">0,05% di livello alcolico nel sangue o 0,25 mg/alcool nell’alito</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b)</span>
              <span class="c33">&nbsp;5% di livello alcolico nel sangue o 2 mg/alcool nell’alito</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c33">0,15% di livello alcolico nel sangue o 1,50 mg/alcool nell’alito</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c33">1,5% di livello alcolico nel sangue o 1,50 mg/alcool nell’alito</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c33">nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="93">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quali tipologie di droghe possono essere utilizzate durante i periodi di riposo?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">solo droghe leggere derivanti da canapa indiana</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">solo quelle prescritte dal medico di bordo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">la cocaina, ma in quantità non superiori ad un grammo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">a discrezione del Comando di bordo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="94">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Per P. Watzlawick, quale è il primo assioma della comunicazione ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">che gli scambi di comunicazione sono simmetrici e complementari.</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">che la comunicazione può esser analogica e digitale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">che non si può non comunicare</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">che ogni comunicazione ha un aspetto di contenuto e uno di relazione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">che la natura di una relazione dipende dalla punteggiatura delle sequenze di comunicazioni</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="95">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">La distanza tra gli interlocutori e l’orientamento nello spazio, fanno parte:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Della comunicazione non verbale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Della comunicazione verbale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Della comunicazione inefficace</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Dell’assunzione di droghe</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="96">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Il quinto assioma della comunicazione, afferma che:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29 c37">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a)</span>
              <span class="c24">&nbsp;“non si può non comunicare”</span>
              <span class="c0">&nbsp;</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">“all’interno di ogni comunicazione vanno distinti il contenuto e la relazione”</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">“esistono la comunicazione analogica e quella numerica”</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">“le interazioni tra i comunicanti possono essere simmetriche o complementari”</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="97">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Gli assiomi della comunicazione furono elaborati da:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">John Lennon</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Sigmund Freud</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Paul Watzlawick</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Charles Darwin</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="98">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quali sono le cause più comuni che possono creare barriere?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">personali da pregiudizi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">semantiche</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">fisiche</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">personali da prevenzioni</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="99">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Con il termine “droghe leggere” intendiamo?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Droghe che possono essere assunte</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Droghe che hanno un peso specifico leggero</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">sostanze che si presume non siano in grado di indurre dipendenza fisica o quadri clinici di avvelenamento acuto a seguito di sovradosaggio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Droghe che si possono trasportare</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Se assunte non è prevista nessuna sanzione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="100">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">La visita e le analisi delle urine e del sangue prima dell’imbarco sono misure atte a ….......</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">scoraggiare l’uso e l’abuso di sostanze vietate</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">prevenzioni per eventuali malattie infettive</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">assicurarsi dello stato ottimale di salute del marittimo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d)</span>
              <span class="c24">&nbsp;per soddisfare i requisiti d'idoneità all'imbarco</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="101">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Se un membro dell’equipaggio fa uso di sostanze stupefacenti rischia lo sbarco?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) no</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Dipende dalla quantità assunta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Solo se lo decide il commissario</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Si</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="102">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quali sostanze possono alterare in modo netto le percezioni?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">il cibo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">allucinogeni</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">ghiaccio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">le spezie</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="103">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Un risultato positivo al test della droga o il rifiuto alla sua esecuzione:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Non può provvedere allo sbarco immediato</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Non può provvedere ad azioni disciplinari</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Può provvedere ad azioni disciplinari incluso lo sbarco immediato</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Non ci sono responsabili del procedimento</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="104">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Esiste una procedura da seguire in caso di inquinamento da idrocarburi? (MARPOL Annex I - Chapter V - Regulation 37 )</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Si, utilizzare il manuale SOPEP</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">la procedura è riportata nel Garbage Management Manual</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Si, solo in acque territoriali</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Si, ma solo se in possesso di solventi adatti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">No, a discrezione del Comando nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="105">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Che cosa è l' I.M.O?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">ente che organizza i corsi di formazione per tutti i marittimi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">è l'organismo che raggruppa tutti gli Stati bagnati dal mare</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">è l'associazione non profit che si occupa dell'assistenza sanitaria per tutto il personale navigante</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">è l'organismo sovrannazionale che raggruppa tutti gli armatori e le compagnie di navigazione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Un’agenzia specializzata delle Nazioni Unite volta a promuovere la cooperazione marittima tra i paesi membri e a garantire la sicurezza della navigazione e la protezione dell’ambiente marino</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="106">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Che cosa è la MARPOL 73/78?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">è un regolamento europeo che disciplina le norme di inquinamento</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">è una norma nazionale per la difesa del mare</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">è una convenzione internazionale che disciplina i diritto dei marittimi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">è una convenzione internazionale che disciplina le Norme per prevenire l'inquinamento dell'ambiente marino e atmosferico</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="107">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Come è composta la MARPOL 73/78?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">3 protocolli e 6 annessi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">2 protocolli e 6 annessi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">2 protocolli e 5 annessi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">6 articoli e 2 annessi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="108">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">La MARPOL 73/78 obbliga…...</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">tutte le navi ad evitare la navigazione all'interno delle aree marine protette</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">tutte le navi superiori a GT 400 e tutte i tanker da 150 GT alla tenuta della Parte I del registro degli idrocarburi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">tutte le navi alla tenuta del registro degli idrocarburi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">tutte le navi extracee ad effettuare la sanificazione dei locali alloggi almeno una volta al mese</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">tutte le navi ad eseguire visite Rina almeno una volta ogni due anni</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="109">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Cosa si intende per Sentina?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">La Sentina è la parete ignifuga lungo l'asse verticale della nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">La Sentina è l'allarme fumo all'interno di ogni locale della nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c)</span>
              <span class="c24">&nbsp;La sentina è, all’interno della nave, la parte bassa dove si raccolgono tutti i residui liquidi che possono accumularsi durante la navigazione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d)</span>
              <span class="c24">&nbsp;La Sentina è il membro dell'equipaggio di vedetta durante la navigazione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">La Sentina è il campanello di allarme in sala macchina</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="110">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Cos'è la Plancia?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">è un locale adibito alla mensa dell'equipaggio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">è un locale di stivaggio e conservazione delle scorte alimentari</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">è l'alloggio del Comandante della nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">è la zona del piano principale di una nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">è la zona da dove si governa la nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="111">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Cosa si intende per Ruolo D'Appello o Muster List?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Il Ruolo d’Appello è il documento redatto dal Comandante che stabilisce le consegne di ogni persona dell’equipaggio nei casi di emergenza</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Il Ruolo d’Appello è la tabella che regola la tenuta della guardia in macchina</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Il Ruolo d’ Appello è la tabella su cui sono annotati gli imbarchi e sbarchi dell’equipaggio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Il Ruolo d’Appello è il registro sul quale vengono annotati i provvedimenti disciplinari causati da negligenza in materia di sicurezza sul lavoro </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Il Ruolo d’Appello è il registro dell’adunata giornaliera sul ponte principale, per il controllo delle presenze</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="112">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">È obbligatorio riportare all’Autorità Marittima gli incidenti che causano inquinamento ambientale?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) No</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Solo con autorizzazione del DPA</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) A giudizio del Comandante</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Si</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="113">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Come si effettua la raccolta dei rifiuti a bordo</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Utilizzando i sacchi in dotazione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Effettuando una buona raccolta differenziata</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Mettendo tutto in un contenitore</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Gettandoli in mare, tranne quelli oleosi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Bruciando tutto con l’inceneritore</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="114">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Come viene segnalato un caduta di una persona a mare ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Un fischio lungo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Due fischi seguiti dal suono di campane e campanelle </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Sette fischi lunghi di sirena seguiti da uno corto e dal suono di campane e campanelli</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Con la viva voce di chi lo ha visto cadere gridando “Uomo a Mare” </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tre fischi lunghi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="115">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">In quale delle fasi impatta la nave sull'ambiente ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Durante la costruzione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Durante la navigazione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Durante la demolizione </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Durante la permanenza in bacino </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le risposte sono esatte</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="116">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Di cosa tratta la Convenzione di Hong Kong che entrerà a pieno regime il 26/06/2025?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Delle condizioni dignitose del marittimo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Di come proteggerci da eventuali problematiche di security</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Del riciclaggio delle navi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Della salvaguardia della vita umana in mare</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Del traffico marittimo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="117">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Come può essere causato l'inquinamento intenzionale?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Discarica a mare di zavorra proveniente da cisterne del carico o combustibile</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Discarica a mare di acqua di lavaggio cisterne del carico</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Discarica a mare di sedimenti di carico </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Discarica in mare di liquami in aree non consentite </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="118">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Come può essere causato l'inquinamento accidentale?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c100">
          <td class="c17" colspan="1" rowspan="6">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c64" colspan="10" rowspan="6">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Fuoriuscita di carico per troppo pieno</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Sinistri quali incaglio, collisione e esplosione </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Errore nella manovra di valvole del sistema di carico</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Avarie alle apparecchiature di controllo </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="119">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Se la nave che dirige le operazioni di soccorso emette un fischio breve, l’imbarcazione di soccorso a cui è diretto il segnale cosa deve fare?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Continuare a seguire la stessa rotta </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Accostare a dritta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Rispondere con un fischio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Accostare a sinistra</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tornare indietro</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="120">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Se la nave che dirige le operazioni di soccorso emette due fischi breve, l’imbarcazione di soccorso a cui è diretto il segnale cosa deve fare?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Continuare a seguire la stessa rotta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Accostare a dritta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Rispondere con un fischio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Accostare a sinistra</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tornare indietro</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="121">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Se la nave che dirige le operazioni di soccorso emette tre fischi breve, l’imbarcazione di soccorso a cui è diretto il segnale cosa deve fare? </span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Continuare a seguire la stessa rotta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Accostare a dritta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Rispondere con un fischio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Accostare a sinistra</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tornare indietro</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="122">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quale è l'arco di orizzonte ricoperto dal fanale di testa d'albero?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) 200°</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) 112,5°</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) 225°</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) 135°</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) 360°</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="123">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quale è l'arco di orizzonte ricoperto dal fanale di coronomaneto</span>
                <span class="c33">?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) 201°</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) 112,5°</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) 225°</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) 135°</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) 360°</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="124">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quale è l'arco di orizzonte ricoperto dal fanale di navigazione ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) 202°</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) 112,5°</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) 225°</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) 135°</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) 360°</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="125">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quale è il colore del fanale di testa d'albero?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Bianco</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Rosso</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Verde</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Giallo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Arancione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="126">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">quale è il colore del fanale dicoronomaneto?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Bianco</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Rosso</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Verde</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Giallo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Arancione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="127">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quale è il colore del fanale di di dritta?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Bianco</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Rosso</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Verde</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Giallo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Arancione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="128">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quale è il colore del fanale di sinistra?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Bianco</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Rosso</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Verde</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Giallo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Arancione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="129">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Come si comunica fra la nave e le imbarcazioni di salvataggio?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Col megafono</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Alla voce</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Con le bandiere e le braccia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Con VHF e segnali acustici</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) A gesti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="130">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Cosa fare in caso di Emergenza Generale?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Recarsi alle imbarcazioni di salvataggio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Dirigersi al punto di riunione previsto</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Organizzare gli interventi necessari</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Indossare il giubbotto di salvataggio e attendere nuovi ordini</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Correre sul ponte e mettersi a disposizione </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="131">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quali sono i sintomi psicologici dello stress lavoro correlato?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Aumento dell’ansia </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Aumento della motivazione </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Diminuzione dell’energia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Aumento della felicità</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Aumento della concentrazione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="132">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quali sono i sintomi fisici dello stress lavoro correlato?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Diminuzione della sudorazione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Mal di testa</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Aumento della pressione sanguigna</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Aumento dell’appettito</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Dolori articolari</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="133">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quali sono le principali fonti di stress lavoro correlato?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Ambiente di lavoro positivo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Scarso carico di lavoro</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Rapporti interpersonali positivi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Pausa pranzo troppo lunga </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Eccessivo carico di lavoro</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="134">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quali sono le conseguenze a lungo termine dello stress lavoro correlato?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Miglioramento delle relazioni interpersonali</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Miglioramento della salute mentale </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Aumento della soddisfazione lavorativa</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Diminuzione della qualità del lavoro</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Aumento della produttività</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="135">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Come si può prevenire lo stress lavoro correlato?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Aumentando le responsabilità lavorative </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Migliorando la comunicazione con i colleghi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Migliorando l’organizzazione del lavoro</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Riducendo le pause durante la giornata</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Aumentando il carico di lavoro</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="136">
              <li class="c28 c32 li-bullet-0">
                <span class="c24">Quali sono i sintomi dello stress lavoro correlato</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c29 c38">
              <span class="c34">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Insonnia </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Diminuzione dell’ansia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Aumento dell’appetito</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Aumento della concentrazione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Diminuzione della pressione sanguigna</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="137">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">L’OMS è?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Un DPI</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Un settore logistico</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) L’organizzazione mondiale della sanità</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Organization Maritime Security</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Un sedativo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="138">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Le sostanze e i prodotti tossici o a rischio:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Devono essere conservati nel loro imballaggio originale </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Devono essere conservate in un luogo chiuso e ben ventilato</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Devono essere maneggiati con estrema cura </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Bisogna indossare protezioni per maneggiare tali prodotti tossici</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
            <p class="c2 c37">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="139">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Le misure deterrenti atte a scoraggiare l’uso e l’abuso di sostanze sono</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Gli esami prima dell’imbarco e durante senza preavviso</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Gli esami delle urine solo con preavviso </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Gli esami del sangue solo con preavviso</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Gli esami agli organi interni</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="140">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">In sostituzione del termine alcolismo l’OMS ha proposto l’uso di:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Sindrome del bere </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Sindrome dell’alcool</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Sindrome da dipendenza da alcool</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Sindrome di alcolismo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="141">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">I barbiturici sono:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Psicostimolanti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Allucinogeni</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Materiale marittimo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Ipnotici-sedativi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="142">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Gli oppiacei, gli ipnotici e gli psicostimolanti vengono definiti:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Alcool</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Droghe</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Metanfetamine</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Sedativi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="143">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Una condizione di intossicazione cronica, caratterizzata da un irrefrenabile pulsione ad assumere una sostanza stupefacente o un farmaco viene definita:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Alcolismo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Ipotermia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Tossicodipendenza</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Astenia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="144">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Quale dei seguenti infortuni è improbabile che possa accadere in sala macchine?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Bruciature da contatto con superfici bollenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Shock elettrico</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Ferite alla testa per caduta di oggetti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Scivolare su paglioli sporchi di olio o nafta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Assideramento</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="145">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Quali sono le procedure corrette di manutenzione in macchina, per evitare incidenti?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Eliminare prontamente colaggi di olio, nafta, acqua</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Pulire immediatamente i residui di perdite d’olio, nafta, etc.</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Assicurare e rizzare le attrezzature, rispetti, i macchinari aperti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Riunione con il personale addetto alla manutenzione, spiegando il lavoro da fare e la peculiarità</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Sono tutte risposte esatte</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="146">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Quali dei seguenti macchinari non sono installati nella sala macchine? </span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Salpancore</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Motore principale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Generatori e alternatori</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Caldaie e compressori</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Separatore acque oleose</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="147">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Per definire l’organizzazione internazionale del lavoro viene usato l’acronimo:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) EMSA</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) O.M.S.</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) I.M.O.</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) I.L.O.</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="148">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Bisogna lanciare un salvagente anulare quando:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Si assiste alla caduta in mare di corpi non riconosciuti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Si assiste alla caduta in mare di oggetti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Si presenta un’emergenza</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Si assiste alla caduta in mare di un compagno o di un passeggero</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="149">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">La rete telefonica, la rete interfonica e il VHF di emergenza sono essenziali per:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Un sistema di comunicazione escluso dalla nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Un sistema di comunicazione interno inefficiente e incapace di far fronte all’emergenza</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="150">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Il Manuale di Gestione della sicurezza viene indicato con l’acronimo:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) I.S.M.</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) S.M.M.</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) D.P.I.</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) S.M.S.</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="151">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">La contestualità, la comunicatività, le gestualità e la credibilità sono fattori di:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Efficacia della comunicazione interpersonale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Inefficacia della comunicazione interpersonale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Inconsapevolezza della comunicazione interpersonale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="152">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Paul Watzlawick ha elaborato:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Gli assiomi della formazione </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Gli assiomi della comunicazione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Gli assiomi dell’informazione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Gli assiomi della relazione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="153">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">La postura e il contatto visivo fanno parte:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Della comunicazione verbale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Della comunicazione non verbale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Dei gesti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Della comunicazione inefficace</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="154">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Il quarto assioma della comunicazione differenzia:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) La comunicazione formale da quella informale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) La comunicazione analogica da quella numerica</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) La comunicazione efficace da quella inefficace</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) La comunicazione pratica da quella teorica</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Risposta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="155">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Le capacità di guidare e dirigere gli altri durante le situazioni di emergenza si definiscono</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Leadership skills</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Home skills</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Main skills</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Other skills</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="156">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Nella “Teoria della Leadership Situazionale” vengono identificati come stili di leadership:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Lo stile direttivo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Lo stile partecipativo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Lo stile coaching</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Lo stile delegante</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="157">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Nel processo di decisione, distinguiamo tra:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Obiettivi primari e secondari</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Obiettivi semplici e complessi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Obiettivi critici e obiettivi migliorativi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Obiettivi e semiobiettivi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="158">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Il Burnout è</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Una bibita</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Un modo di essere</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Un festival di musica rock</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Una condizione di stress lavoro-correlato</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="159">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Tra le reazioni alla paura, troviamo:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Fight</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Flight</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Freeze</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Faint</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="160">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">La fuga afinalistica è:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Una fuga senza un obiettivo o una meta, solo dettata dalla paura</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Un modo di reagire alla tristezza</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Un modo di reagire alla rabbia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Un’emozione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="161">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Il conflitto può essere:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Aperto e chiuso</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Decisivo o inutile</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Costruttivo o distruttivo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Etnico o bellico</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="162">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Le variabili che possono generare un conflitto sono:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Obiettivi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Percezioni</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Risorse e ricompense </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Potere</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="163">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Nella Piramide di Maslow troviamo indicati:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Bisogni primari o fisiologici</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Bisogni di sicurezza</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Bisogni sociali</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Bisogni di autorealizzazione </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="164">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Passivo, assertivo e aggressivo sono</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Modi di essere</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Stili di emozioni</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Stili di comportamento</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Stili di leadership</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="165">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">I termini inglesi “Human Resources” indicano:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) I marinai</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) I comandanti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) I direttori di macchina</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) La forza lavoro</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="166">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Gli stili di comportamento sono:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Passivo, aggressivo e assertivo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Attivo e passivo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Medio e Anulare</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Passivo e aggressivo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="167">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Le leadership skills sono:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Le capacità del personale di cucina</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Le capacità di guidare e dirigere gli altri durante le situazioni di emergenza</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Le incapacità dei comandanti </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="168">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Lo stress lavoro-correlato si chiama:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Acting out</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Go out</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Burnout</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Close out</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="169">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Le emozioni primarie sono:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Gioia, rabbia, tristezza, disgusto, paura</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Vergogna e imbarazzo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Gelosia e paura</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Noia, felicità e invidia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="170">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Le emozioni primarie sono:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) 7</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) 5</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) 11</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) 9</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="171">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Quali fra i seguenti mezzi di comunicazione, nelle emergenze, può essere più efficace, in presenza di barriere linguistiche?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Parlare comunque</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Leggere ad alta voce</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Scrivere le parole</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Gestualità e simboli pittorici</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Dare cenni di assenso anche quando non si capiscono gli ordini</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="172">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Le divergenze a bordo della nave come vanno gestite?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Basta sfogarsi emotivamente</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Vanno gestite con moderazione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Non vanno gestite</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Ognuno può fare come crede</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Devono essere possibilmente </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="173">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">La qualità di Leadership cosa significa per voi? &nbsp;</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Gli altri si devono temere</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Essere intransigenti con i subalterni</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Imporre il proprio grado</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Far capire i subalterni la necessità di dover eseguire correttamente i compiti assegnati</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Imporre gli ordini senza spiegazioni</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="174">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Per rendere più corrette le relazioni interpersonali a bordo, quali comportamenti sono da evitare?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Ignorare la disciplina a bordo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Tenere presente che la conflittualità interpersonale aumenta il rischio di infortuni</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Ognuno deve rispetto agli altri</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Valutare le differenze individuali, piuttosto che sottolineare le debolezze</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenze</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="175">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Nei confronti di chi, ogni membro dell’equipaggio deve assumersi la propria responsabilità sociale?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
            <p class="c29 c37">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Verso sé stesso mantenendo un comportamento corretto</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Verso tutti gli altri membri dell’equipaggio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Verso la nave e l’ambiente</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Verso i diretti superiori o subalterni</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="176">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Che cosa s’intende per responsabilità sociale?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Il rispetto verso le persone ed ogni tipo di lavoro</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Essere responsabili verso la nave, le leggi e regolamenti che disciplinano il lavoro sulla nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Aderire alla politica di sicurezza, in ogni momento, assistere i compagni di lavoro in pericolo, prevenire ogni tipo di incidente con diligenza</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Eseguire i propri compiti con diligenza</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="177">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Che dice il secondo assioma della comunicazione?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Ogni comunicazione ha un aspetto di contenuto e uno di relazione </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) La comunicazione analogica da quella numerica</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) La comunicazione efficace da quella inefficace</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) La comunicazione pratica da quella teorica</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="178">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Che dice terzo assioma della comunicazione?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) La comunicazione formale da quella informale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) La comunicazione analogica da quella numerica</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) La natura di una relazione dipende dalla punteggiatura della sequenza degli eventi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) La comunicazione pratica da quella teorica</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="179">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Quanti assiomi della comunicazione conosci?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) 1</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) 5</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) 4</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) 3</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) 2</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="180">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">La comunicazione analogica sfrutta……..</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) La gestualità</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Le intonazioni della voce </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) La comunicazione non verbale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Il linguaggio del corpo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="181">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">L’empatia?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Sfrutta la comunicazione aggressiva </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Mostra disinteresse nelle cose </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Non bisogna immedesimarsi nelle problematiche altrui</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Considera i bisogni propri e dell’interlocutore durante le comunicazioni</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="182">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Quali sono le barriere della comunicazione</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Personali</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Semantiche</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Fisiche</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Non fisiche </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="183">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Le leggi nazionali di molti Stati prevedono pene molto severe per l’uso o lo spaccio di alcool e droga?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Non è vero</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Si può sempre conciliare con una pena pecuniaria </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Si viene puniti con le pene previste dalle leggi dello stato del condannato</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Si, fino alla condanna a morte, in alcuni Stati</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Sono i reati verso i quali c’è molta tolleranza in tutti gli Stati</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="184">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Le sostanze sedative, ipnotiche o ansiolitiche includono:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Le benzodiazepine </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) I barbiturici</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) I carbammati</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Gli ipnotici simili ai barbiturici</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="185">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Gli allucinogeni sono:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Sedativi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Sostanze capaci di rimanere inalterate le percezioni, i pensieri e le sensazioni</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Sostanze capaci di alterare in modo netto le percezioni, i pensieri e le sensazioni</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="186">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">La droga può alterare:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29 c37">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) La percezione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) L’attenzione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) La coscienza critica </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Il comportamento</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="187">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Con “Tossicodipendenza” intendiamo:</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Una condizione di intossicazione cronica, caratterizzata dalla irrefrenabile pulsione ad assumere una sostanza stupefacente o un farmaco</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Una tossina</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Una condizione dovuta all’ ipossia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Una condizione dovuta all’ipotermia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Tutte le precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="188">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Quali delle seguenti sostanze sono considerate droghe leggere?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Cocaina</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Oppio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Eroina</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Marijuana</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Morfina</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="189">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Come viene denominata l’ancora di rispetto?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Ammiragliato</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Speranza</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Di servizio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Hall</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Nessuna delle precedenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="190">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Come viene chiamato il passacavo in inglese?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c2 c37 c119">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Bitt</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Rope</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Winch</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Rudder</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Fairlead</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="191">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Come viene chiamato il verricello in inglese?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Bitt</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Rope</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Winch</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Rudder</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Fairlead</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="192">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Come viene chiamato il timone in inglese?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Bitt</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Rope</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Winch</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Rudder</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Fairlead</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="193">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Quali sono quelle navi che devono avere Oil Record Book Parte II?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Petroliere</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Passeggeri</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Portacontainer</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Traghetti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Servizi speciali</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="194">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Quanti classi ci sono del codice IMDG?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) 1</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) 9</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) 8</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) 7</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) 15</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="195">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Il materiale esplosivo a quale classe appartiene del codice IMDG?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) 1</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) 9</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) 8</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) 7</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) 15</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="196">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Il materiale radioattivo a quale classe appartiene del codice IMDG?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) 1</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) 9</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) 8</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) 7</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) 15</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="197">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Come viene chiamato in inglese un permesso di lavoro a caldo?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Work aloft</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Work permit</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Hot work permit</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) General work permit</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Special work permit</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="198">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Come viene chiamato in inglese un permesso di lavoro sospeso?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Work aloft</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Work permit</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Hot work permit</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) General work permit</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Special work permit</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="199">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Dopo aver richiesto il permesso di lavoro, cosa fare prima di ogni lavoro?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c17" colspan="1" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Avvisare il cuoco</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Check list</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Il segno della croce </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) Suonare allarme emergenza generale </span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Avvisare il 1° ufficiale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="200">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Cosa sono i Personal Protection Equipment? </span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c17" colspan="1" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) Giubbotto di salvataggio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c17" colspan="1" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c11" colspan="10" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) Tuta d’immersione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) Gli indumenti da lavoro</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) L’autorespiratore</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) Dispositivi di sicurezza personali</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <table class="c30" style="margin: 0 auto">
      <tbody>
        <tr class="c90">
          <td class="c124" colspan="1" rowspan="1">
            <p class="c56 c47">
              <span class="c0">IL DIRETTORE DEL CORSO</span>
            </p>
            <p class="c56 c47 c37">
              <span class="c0"></span>
            </p>
            <p class="c56 c47 c37">
              <span class="c0"></span>
            </p>
            <p class="c41">
              <span class="c0">________________________</span>
            </p>
            <p class="c37 c41">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c59" colspan="1" rowspan="1">
            <p class="c56 c47">
              <span class="c0">MEMBRO</span>
            </p>
            <p class="c41 c37">
              <span class="c0"></span>
            </p>
            <p class="c41 c37">
              <span class="c0"></span>
            </p>
            <p class="c56 c47">
              <span class="c0">_______________________</span>
            </p>
            <p class="c56 c47 c37">
              <span class="c101"></span>
            </p>
          </td>
          <td class="c83" colspan="1" rowspan="1">
            <p class="c56 c47">
              <span class="c0">MEMBRO E SEGRETARIO</span>
            </p>
            <p class="c47 c37 c56">
              <span class="c0"></span>
            </p>
            <p class="c56 c47">
              <span class="c0">__________________</span>
            </p>
          </td>
          <td class="c59" colspan="1" rowspan="1">
            <p class="c56 c47">
              <span class="c0">IL RAPPRESENTANTE DELL’AUTORITA’ MARITTIMA</span>
            </p>
            <p class="c56 c47">
              <span class="c0">_______________________</span>
            </p>
            <p class="c56 c47 c37">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c28">
      <span class="c0">&nbsp;IL DIRETTORE DEL CORSO ___________________________________</span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c28">
      <span class="c0">&nbsp; MEMBRO _______________________________________</span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c28">
      <span class="c0">&nbsp; MEMBRO E SEGRETARIO_____________________________________</span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c28">
      <span class="c0">&nbsp;IL RAPPRESENTANTE DELL’AUTORITA’ MARITTIMA ___________________________________</span>
    </p>
    <div>
      <p class="c29 c47">
        <span class="c66">Palermo</span>
        <span class="c43">&nbsp;– Via Francesco Crispi, 155 – Porto Varco S. Lucia – CAP 90133 – </span>
        <span class="c66">Tel. +39 091 320.353</span>
        <span class="c43">&nbsp;– Fax +39 091 612.36.70</span>
      </p>
      <p class="c29 c47">
        <span class="c43">Campo Antincendio S.P. di Bellolampo, 8401-Palermo-email: </span>
        <span class="c68">
          <a class="c54" href="mailto:desantistudio@alice.it">sviluppo.desantiscorsidiformazione.it</a>
        </span>
        <span class="c43">&nbsp;-web:</span>
        <span class="c68">
          <a class="c54" href="https://www.google.com/url?q=http://www.studiodesantissrl.it&amp;sa=D&amp;source=editors&amp;ust=1737694822731745&amp;usg=AOvVaw3L0xSlwCnaytim9GhVoCo0">www.studiodesantissrl.it</a>
        </span>
        <span class="c43">&nbsp; &nbsp; &nbsp; </span>
      </p>
      <p class="c29 c47">
        <span class="c43">Pe</span>
        <span class="c45">c:</span>
        <span class="c68">
          <a class="c54" href="mailto:centro.studio.desantis@pec.it">centro.studio.desantis@pec.it</a>
        </span>
        <span class="c45">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
        <span class="c106">IBAN:ITI7P032680460705295143829</span>
      </p>
      <p class="c29 c47 c37">
        <span class="c82"></span>
      </p>
    </div>
  
</body></html>`
function _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
       binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }
const savePdf = async (payload) => {
    const logo = await fetch('https://mase-storage.s3.eu-west-1.amazonaws.com/1716223770927.document-file.jpeg');
    
    // const mappa = lessons.map((x, i) => singleCourseTemplate.replaceAll('{no}', i + 1).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    // const practicalMap = lessons.filter(x => x.pratica).map((x, i) => Practical.replaceAll('{name}', `Lezione nr. ${i + 1}`).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    html2pdf()
    .set({
        filename: 'scheda_ammissione.pdf',
        margin: 20,
        html2canvas: { useCORS: true },
    })
    .from(Libretto.replaceAll('{docente}', payload.teacher)
            .replaceAll('{direttore}', payload.director)
            .replaceAll('{nome_corso}', payload.nome_corso)
            .replaceAll('{logo}', payload.settings?.logo_top ? payload.settings?.logo_top : payload.school.logo)
            .replaceAll('{autorita_marittima}', payload.course.dataValues.autorita_marittima)
            .replaceAll('{row_number}', payload.rowNumber + 1)
            .replaceAll('{ID}', payload.id ? payload.id : `2024/${payload.nome_corso}/1`)
            .replaceAll('{number}', payload.number)
            .replaceAll('{school_name}', `${payload.school.name}`)
            .replaceAll('{legal_address}', payload.school.sede_legale)
            .replaceAll('{phone}', `+39 ${payload.school.telefono}`)
            .replaceAll('{website}', payload.school.url)
            .replaceAll('{piva}', payload.school.piva)
            .replaceAll('{exam_day}', `${moment(payload.course.dataValues.to).add(1, 'days').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').format('DD/MM/YYYY')}`)
            .replaceAll('{docente}', payload.teacher)
            .replaceAll('{date}', moment().format('DD/MM/YYYY HH:mm'))
                .replaceAll('{direttore}', payload.director)
                .replaceAll('{nome_corso}', payload.nome_corso)
                .replaceAll('{id}', payload.id ? payload.id : `2024/${payload.nome_corso}/1`)
                .replaceAll('{birth_place}', `${payload.partecipant[0].birthplace}`)
                .replaceAll('{birth_province}', `${payload.partecipant[0].birth_province}`)
                .replaceAll('{birthday}', `${moment(payload.partecipant[0].birthday).format('DD/MM/YYYY')}`)
                .replaceAll('{matricola}', `${payload.partecipant[0].matricola}`)
                .replaceAll('{compartment}', `${payload.partecipant[0].compartimento_marittimo}`)
                .replaceAll('{exam_day}', `${moment(payload.course.dataValues.to).add(1, 'days').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').format('DD/MM/YYYY')}`)
                .replaceAll('{expiry}', `${moment(payload.course.dataValues.to).add(1, 'days').add(5, 'years').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').add(5, 'years').format('DD/MM/YYYY')}`)
            
                .replaceAll('{first_name}', `${payload.partecipant[0].first_name}`)
                .replaceAll('{last_name}', `${payload.partecipant[0].last_name}`)
                .replaceAll('{birth_place}', `${payload.partecipant[0].birthplace}`)
                .replaceAll('{birth_province}', `${payload.partecipant[0].birth_province}`)
                .replaceAll('{birth_province}', `${payload.partecipant[0].birth_province}`)
                .replaceAll('{matricola}', `${payload.partecipant[0].matricola}`)
                .replaceAll('{compartment}', `${payload.partecipant[0].compartimento_marittimo}`)
                .replaceAll('{fiscal_code}', `${payload.partecipant[0].fiscal_code}`)
                .replaceAll('{address}', `${payload.partecipant[0].address}`)
                .replaceAll('{civic_number}', `${payload.partecipant[0].civic_number}`)
                .replaceAll('{city_residence}', `${payload.partecipant[0].city_residence}`)
                .replaceAll('{postal_code}', `${payload.partecipant[0].postal_code}`)
                .replaceAll('{compartimento_marittimo}', `${payload.partecipant[0].compartimento_marittimo}`)
                .replaceAll('{phone_number}', `${payload.partecipant[0].phone_number}`)
                .replaceAll('{passport}', `${payload.partecipant[0].passport}`)
                .replaceAll('{decretee}', `${payload.decretee}`)
                .replaceAll('{birthday}', `${moment(payload.partecipant[0].birthday).format('DD/MM/YYYY')}`)
                .replaceAll('{start_day}', `${moment(payload.course.dataValues.from).format('dddd')}, ${moment(payload.course.dataValues.from).format('DD/MM/YYYY')}`)
                // .replaceAll('{partecipants}', payload.partecipant.map((partecipant, idx) => singlePartecipant(partecipant, idx + 1, payload.id)).join('\n'))
                .replaceAll('{school_address}', payload.school.indirizzo_scuola)  
                .replaceAll('{logo}', payload.school.logo)
            ).toPdf().get('pdf').then(pdf => {
                const totalPages = pdf.internal.getNumberOfPages(); // Numero totale di pagine

                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);

                    // Aggiungi Header
                    pdf.setFontSize(10);
                    pdf.text(`${payload.school.name} | Batteria Corso`, 10, 10); // Posizione dell'header (10mm dall'alto)

                    // Aggiungi Footer
                    pdf.text(
                        `Pagina ${i} di ${totalPages}`, // Numerazione delle pagine
                        pdf.internal.pageSize.getWidth() / 2, // Centro della pagina
                        pdf.internal.pageSize.getHeight() - 10, // 10mm dal fondo
                        { align: 'center' }
                    );
                }

                // Apri il PDF
                    window.open(pdf.output('bloburl'), '_blank');
                })
}
export { Libretto, savePdf }