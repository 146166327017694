import React from 'react';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';

const SingleRow = (partecipant) =>`
        <tr style="border: 1px solid black; border-collapse:collapse">
            <td style="width: 100px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{id_matricola}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{last_name}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{first_name}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birth_place}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birth_province}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birthday}</td>
            <td style="width: 130px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{matricola}/{compartment}</td>
        </tr>
        `
const Libretto = `<html><head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
      @import url(https://themes.googleusercontent.com/fonts/css?kit=DtPsxHYDxQWpO8ASIGu_ew0oAUP_eRtG2f1I1nZF09YS0-eybaQSkqpYUibASASI_37GXcCmZhAvVrsFphImmA);

      .lst-kix_list_4-1>li {
        counter-increment: lst-ctn-kix_list_4-1
      }

      .lst-kix_list_1-1>li {
        counter-increment: lst-ctn-kix_list_1-1
      }

      .lst-kix_list_3-0>li:before {
        content: "o  "
      }

      .lst-kix_list_3-1>li:before {
        content: "o  "
      }

      .lst-kix_list_3-2>li:before {
        content: "\x025aa   "
      }

      ol.lst-kix_list_1-8.start {
        counter-reset: lst-ctn-kix_list_1-8 0
      }

      .lst-kix_list_3-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_3-4>li:before {
        content: "o  "
      }

      ol.lst-kix_list_1-5.start {
        counter-reset: lst-ctn-kix_list_1-5 0
      }

      .lst-kix_list_3-3>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_3-8>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_3-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_4-3>li {
        counter-increment: lst-ctn-kix_list_4-3
      }

      .lst-kix_list_3-7>li:before {
        content: "o  "
      }

      ol.lst-kix_list_4-5.start {
        counter-reset: lst-ctn-kix_list_4-5 0
      }

      .lst-kix_list_1-2>li {
        counter-increment: lst-ctn-kix_list_1-2
      }

      ol.lst-kix_list_4-2.start {
        counter-reset: lst-ctn-kix_list_4-2 0
      }

      .lst-kix_list_1-4>li {
        counter-increment: lst-ctn-kix_list_1-4
      }

      .lst-kix_list_4-4>li {
        counter-increment: lst-ctn-kix_list_4-4
      }

      ol.lst-kix_list_1-6.start {
        counter-reset: lst-ctn-kix_list_1-6 0
      }

      .lst-kix_list_4-8>li:before {
        content: ""counter(lst-ctn-kix_list_4-8, lower-roman) ". "
      }

      .lst-kix_list_4-7>li:before {
        content: ""counter(lst-ctn-kix_list_4-7, lower-latin) ". "
      }

      ol.lst-kix_list_4-1.start {
        counter-reset: lst-ctn-kix_list_4-1 0
      }

      ol.lst-kix_list_4-8.start {
        counter-reset: lst-ctn-kix_list_4-8 0
      }

      ul.lst-kix_list_4-0 {
        list-style-type: none
      }

      ol.lst-kix_list_1-0.start {
        counter-reset: lst-ctn-kix_list_1-0 0
      }

      ol.lst-kix_list_4-7.start {
        counter-reset: lst-ctn-kix_list_4-7 0
      }

      ol.lst-kix_list_1-3 {
        list-style-type: none
      }

      ol.lst-kix_list_1-4 {
        list-style-type: none
      }

      .lst-kix_list_2-6>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_2-7>li:before {
        content: "o  "
      }

      ol.lst-kix_list_1-5 {
        list-style-type: none
      }

      ol.lst-kix_list_1-6 {
        list-style-type: none
      }

      ol.lst-kix_list_1-0 {
        list-style-type: none
      }

      .lst-kix_list_2-4>li:before {
        content: "o  "
      }

      .lst-kix_list_2-5>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_2-8>li:before {
        content: "\x025aa   "
      }

      ol.lst-kix_list_1-1 {
        list-style-type: none
      }

      ol.lst-kix_list_1-2 {
        list-style-type: none
      }

      ul.lst-kix_list_3-7 {
        list-style-type: none
      }

      ul.lst-kix_list_3-8 {
        list-style-type: none
      }

      ol.lst-kix_list_4-6.start {
        counter-reset: lst-ctn-kix_list_4-6 0
      }

      ul.lst-kix_list_3-1 {
        list-style-type: none
      }

      ul.lst-kix_list_3-2 {
        list-style-type: none
      }

      ul.lst-kix_list_3-0 {
        list-style-type: none
      }

      ol.lst-kix_list_4-3.start {
        counter-reset: lst-ctn-kix_list_4-3 0
      }

      ol.lst-kix_list_1-7 {
        list-style-type: none
      }

      ul.lst-kix_list_3-5 {
        list-style-type: none
      }

      .lst-kix_list_4-7>li {
        counter-increment: lst-ctn-kix_list_4-7
      }

      .lst-kix_list_1-7>li {
        counter-increment: lst-ctn-kix_list_1-7
      }

      ol.lst-kix_list_1-8 {
        list-style-type: none
      }

      ul.lst-kix_list_3-6 {
        list-style-type: none
      }

      ul.lst-kix_list_3-3 {
        list-style-type: none
      }

      ul.lst-kix_list_3-4 {
        list-style-type: none
      }

      .lst-kix_list_4-0>li:before {
        content: "\x02022   "
      }

      .lst-kix_list_4-1>li:before {
        content: ""counter(lst-ctn-kix_list_4-1, lower-latin) ") "
      }

      .lst-kix_list_4-6>li {
        counter-increment: lst-ctn-kix_list_4-6
      }

      ol.lst-kix_list_1-7.start {
        counter-reset: lst-ctn-kix_list_1-7 0
      }

      .lst-kix_list_4-4>li:before {
        content: "("counter(lst-ctn-kix_list_4-4, lower-latin) ") "
      }

      .lst-kix_list_1-5>li {
        counter-increment: lst-ctn-kix_list_1-5
      }

      .lst-kix_list_4-3>li:before {
        content: "("counter(lst-ctn-kix_list_4-3, decimal) ") "
      }

      .lst-kix_list_4-5>li:before {
        content: "("counter(lst-ctn-kix_list_4-5, lower-roman) ") "
      }

      .lst-kix_list_4-2>li:before {
        content: ""counter(lst-ctn-kix_list_4-2, lower-roman) ") "
      }

      .lst-kix_list_4-6>li:before {
        content: ""counter(lst-ctn-kix_list_4-6, decimal) ". "
      }

      .lst-kix_list_1-8>li {
        counter-increment: lst-ctn-kix_list_1-8
      }

      ol.lst-kix_list_1-4.start {
        counter-reset: lst-ctn-kix_list_1-4 0
      }

      ol.lst-kix_list_1-1.start {
        counter-reset: lst-ctn-kix_list_1-1 0
      }

      ol.lst-kix_list_4-1 {
        list-style-type: none
      }

      ol.lst-kix_list_4-4.start {
        counter-reset: lst-ctn-kix_list_4-4 0
      }

      ol.lst-kix_list_4-2 {
        list-style-type: none
      }

      ol.lst-kix_list_4-3 {
        list-style-type: none
      }

      ol.lst-kix_list_1-3.start {
        counter-reset: lst-ctn-kix_list_1-3 0
      }

      ul.lst-kix_list_2-8 {
        list-style-type: none
      }

      ol.lst-kix_list_1-2.start {
        counter-reset: lst-ctn-kix_list_1-2 0
      }

      ol.lst-kix_list_4-8 {
        list-style-type: none
      }

      ul.lst-kix_list_2-2 {
        list-style-type: none
      }

      .lst-kix_list_1-0>li:before {
        content: ""counter(lst-ctn-kix_list_1-0, decimal) ". "
      }

      ul.lst-kix_list_2-3 {
        list-style-type: none
      }

      ul.lst-kix_list_2-0 {
        list-style-type: none
      }

      ul.lst-kix_list_2-1 {
        list-style-type: none
      }

      ol.lst-kix_list_4-4 {
        list-style-type: none
      }

      ul.lst-kix_list_2-6 {
        list-style-type: none
      }

      ol.lst-kix_list_4-5 {
        list-style-type: none
      }

      .lst-kix_list_1-1>li:before {
        content: ""counter(lst-ctn-kix_list_1-1, lower-latin) ". "
      }

      .lst-kix_list_1-2>li:before {
        content: ""counter(lst-ctn-kix_list_1-2, lower-roman) ". "
      }

      ul.lst-kix_list_2-7 {
        list-style-type: none
      }

      ol.lst-kix_list_4-6 {
        list-style-type: none
      }

      ul.lst-kix_list_2-4 {
        list-style-type: none
      }

      ol.lst-kix_list_4-7 {
        list-style-type: none
      }

      ul.lst-kix_list_2-5 {
        list-style-type: none
      }

      .lst-kix_list_1-3>li:before {
        content: ""counter(lst-ctn-kix_list_1-3, decimal) ". "
      }

      .lst-kix_list_1-4>li:before {
        content: ""counter(lst-ctn-kix_list_1-4, lower-latin) ". "
      }

      .lst-kix_list_1-0>li {
        counter-increment: lst-ctn-kix_list_1-0
      }

      .lst-kix_list_4-8>li {
        counter-increment: lst-ctn-kix_list_4-8
      }

      .lst-kix_list_1-6>li {
        counter-increment: lst-ctn-kix_list_1-6
      }

      .lst-kix_list_1-7>li:before {
        content: ""counter(lst-ctn-kix_list_1-7, lower-latin) ". "
      }

      .lst-kix_list_1-3>li {
        counter-increment: lst-ctn-kix_list_1-3
      }

      .lst-kix_list_1-5>li:before {
        content: ""counter(lst-ctn-kix_list_1-5, lower-roman) ". "
      }

      .lst-kix_list_1-6>li:before {
        content: ""counter(lst-ctn-kix_list_1-6, decimal) ". "
      }

      li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
      }

      .lst-kix_list_2-0>li:before {
        content: "o  "
      }

      .lst-kix_list_2-1>li:before {
        content: "o  "
      }

      .lst-kix_list_4-5>li {
        counter-increment: lst-ctn-kix_list_4-5
      }

      .lst-kix_list_1-8>li:before {
        content: ""counter(lst-ctn-kix_list_1-8, lower-roman) ". "
      }

      .lst-kix_list_2-2>li:before {
        content: "\x025aa   "
      }

      .lst-kix_list_2-3>li:before {
        content: "\x025cf   "
      }

      .lst-kix_list_4-2>li {
        counter-increment: lst-ctn-kix_list_4-2
      }

      ol {
        margin: 0;
        padding: 0
      }

      table td,
      table th {
        padding: 0
      }

      .c55 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 33.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c79 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 10.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c122 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 0.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c7 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 103.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c52 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c87 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 160.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c76 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 164.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c75 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 103.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c26 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c4 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 54.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c3 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c84 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 33.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c14 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 177.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c13 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c70 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 10.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c69 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 100.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c60 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 164.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c114 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 74.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c110 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c108 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 474.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c93 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 103.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c71 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 474.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c19 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 2.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c36 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 100.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c103 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 1pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c86 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 474.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c51 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 2.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c77 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 39pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c89 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 205.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c12 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c59 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 138.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c99 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 175.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c40 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c9 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c118 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c94 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 119.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c88 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 86pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c115 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 239.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c117 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 10.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c120 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 39pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c44 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 103.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c102 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 205.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c11 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 93.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c81 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c20 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c113 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 0.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c124 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 144.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c6 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 175.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c83 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 111pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c16 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 39pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c57 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 175.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c64 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 93.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c48 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 239.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c98 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c112 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 1.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c107 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 1.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c80 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 444.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c73 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c1 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 175.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c123 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 2.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c74 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 33.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c97 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 100.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c17 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 100.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c10 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 33.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c49 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 86pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c31 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 2.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c35 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 193.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c15 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 10.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c39 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 139.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c46 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 0.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c61 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c78 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 175.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c111 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 39pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c27 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 105.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c85 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 1pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c58 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 39pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c42 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 700;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 14pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c82 {
        -webkit-text-decoration-skip: none;
        color: #03308b;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 8pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c68 {
        -webkit-text-decoration-skip: none;
        color: #0000ff;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c91 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 700;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 8pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c104 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 5.5pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c22 {
        -webkit-text-decoration-skip: none;
        color: #002060;
        font-weight: 700;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 11pt;
        font-family: "Arial Black";
        font-style: italic
      }

      .c25 {
        margin-left: -5pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: center;
        height: 10pt
      }

      .c53 {
        color: #c00000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal
      }

      .c121 {
        color: #002060;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Arial Black";
        font-style: normal
      }

      .c33 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c34 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal
      }

      .c0 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal
      }

      .c96 {
        color: #002060;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6pt;
        font-family: "Arial Black";
        font-style: normal
      }

      .c23 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: center;
        height: 10pt
      }

      .c50 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 9pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c105 {
        color: #c00000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal
      }

      .c106 {
        color: #0000ff;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c24 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Times New Roman";
        font-style: normal
      }

      .c66 {
        color: #000080;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c45 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c101 {
        color: #ff0000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal
      }

      .c43 {
        color: #000080;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6.5pt;
        font-family: "Calibri";
        font-style: normal
      }

      .c116 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Arial";
        font-style: normal
      }

      .c41 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      .c2 {
        padding-top: 3pt;
        padding-bottom: 3pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      .c5 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        text-align: left;
        height: 10pt
      }

      .c18 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: left;
        height: 10pt
      }

      .c63 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: justify
      }

      .c29 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: center
      }

      .c30 {
        margin-left: -5.4pt;
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto
      }

      .c92 {
        margin-left: -5.8pt;
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto
      }

      .c28 {
        padding-top: 3pt;
        padding-bottom: 3pt;
        line-height: 1.0;
        text-align: left
      }

      .c56 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: center
      }

      .c72 {
        background-color: #ffffff;
        max-width: 467.8pt;
        padding: 51pt 70.8pt 56.7pt 56.7pt
      }

      .c38 {
        margin-left: -5pt;
        orphans: 2;
        widows: 2
      }

      .c54 {
        color: inherit;
        text-decoration: inherit
      }

      .c32 {
        margin-left: 36pt;
        padding-left: 0pt
      }

      .c21 {
        padding: 0;
        margin: 0
      }

      .c47 {
        orphans: 2;
        widows: 2
      }

      .c62 {
        height: 6.7pt
      }

      .c109 {
        height: 33.9pt
      }

      .c37 {
        height: 10pt
      }

      .c8 {
        height: 0pt
      }

      .c119 {
        margin-left: -5pt
      }

      .c67 {
        height: 7.5pt
      }

      .c100 {
        height: 11.5pt
      }

      .c95 {
        height: 23.1pt
      }

      .c90 {
        height: 44.9pt
      }

      .c65 {
        height: 28pt
      }

      .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1.0;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      li {
        color: #000000;
        font-size: 10pt;
        font-family: "Times New Roman"
      }

      p {
        margin: 0;
        color: #000000;
        font-size: 10pt;
        font-family: "Times New Roman"
      }

      h1 {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 24pt;
        padding-bottom: 6pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h2 {
        padding-top: 18pt;
        color: #000000;
        font-weight: 700;
        font-size: 18pt;
        padding-bottom: 4pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h3 {
        padding-top: 14pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h4 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 2pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h5 {
        padding-top: 11pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 2pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }

      h6 {
        padding-top: 10pt;
        color: #000000;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
      }
    </style>
  </head>
  <body class="c72 doc-content">
    <div style="width: 100%; display: block; margin: 0 auto; text-align:center">
                    <img src="{logo}" crossorigin="*" style="height: 125px; object-fit: contain;">

    </div>
    <p class="c5">
      <span class="c116"></span>
    </p>
    <table class="c30" style="margin: 0 auto">
      <tbody>
        <tr class="c8">
          <td class="c89" colspan="2" rowspan="1">
            <p class="c29">
              <span class="c34">{school_name}</span>
            </p>
            <p class="c29">
              <span class="c105">{nome_corso}</span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c115" colspan="2" rowspan="1">
            <p class="c28">
              <span class="c34">Verbale di esame n</span>
              <span class="c0">.{id}/{number}</span>
            </p>
            <p class="c28">
              <span class="c34">Stampato alle ore</span>
              <span class="c0">&nbsp;{date} </span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c102" colspan="2" rowspan="1">
            <p class="c29 c37">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c48" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c95">
          <td class="c94" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c0">Cognome ___________________</span>
            </p>
          </td>
          <td class="c87" colspan="2" rowspan="1">
            <p class="c28">
              <span class="c0">Nome _____________________</span>
            </p>
          </td>
          <td class="c60" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c0">Data Esame _________</span>
            </p>
          </td>
        </tr>
        <tr class="c65">
          <td class="c80" colspan="4" rowspan="1">
            <p class="c29 c37">
              <span class="c0"></span>
            </p>
            <p class="c29">
              <span class="c0">Firma Discente _________________</span>
            </p>
            <p class="c29">
              <span class="c0">(firma autografa leggibile e per esteso)</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="c2 c37">
      <span class="c0"></span>
    </p>
    <br><br><table class="c92" style="margin: 0 auto">
      <tbody>
        <tr class="c109">
          <td class="c86" colspan="15" rowspan="1">
            <p class="c63">
              <span class="c0">Per ciascuna delle seguenti domane, il discente/candidato contrassegna con una “X” la risposta ritenuta corretta ed entro il cerchio corrispondente. Le risposte omesse o rettificate equivalgono ad una risposta errata. Non apporre ulteriori segni al di fuori del cerchio predetto. Non apporre ulteriori segni sul retro dei fogli. La Commissione metterà la X nel riquadro corrispondente allo spazio riservato alla Commissione d’esame</span>
            </p>
          </td>
        </tr>
        <tr class="c67">
          <td class="c108" colspan="15" rowspan="1">
            <p class="c63 c37">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c67">
          <td class="c71" colspan="15" rowspan="1">
            <p class="c37 c63">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="2">
            <ol class="c21 lst-kix_list_1-0 start" start="1">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Dove si trova la sede dell'IMO</span>
              </li>
            </ol>
          </td>
          <td class="c27" colspan="2" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c29 c38">
              <span class="c34">Spazio riservato alla commissione </span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c39" colspan="2" rowspan="1">
            <p class="c29 c38">
              <span class="c34">Esatta</span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c29 c38">
              <span class="c34">Errata </span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">a) Parigi</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_2-0 start">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">b) Ginevra</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">c) New York</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">d) Londra</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">e) Berna</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="2">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Il cow è un sistema di lavaggio che viene fatto con ….......?</span>
              </li>
            </ol>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">a) con lo stesso carico</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0 start">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">b) con l’acqua</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">c) manualmente</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">d) senza gas inerte</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">e) tutte le risposte sono errate</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="3">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Quali sono le operazioni che vengono fatte sul castello ?</span>
              </li>
            </ol>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">a) caricazione e discarica</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">b) ormeggio ed ancoraggio</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">c) lavaggio delle cisterne</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">d) lavori di carpenteria</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">e) preparazione di pitture</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="4">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">La COLREG 78 è quella convenzione che regolamenta…................?</span>
              </li>
            </ol>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">a) la sicurezza della vita umana </span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">b) l’inquinamento mare e aria</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">c) lo standard di formazione marittimi</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">d) i diritti del lavoratore</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">e) traffico marittimo</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="5">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Cosa passa attraverso gli occhi di cubia ?</span>
              </li>
            </ol>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">a) le catene delle ancore</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">b) i cavi di ormeggio</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">c) i cavi di acciaio</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">d) il cavo di rimorchio</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">e) tubazioni di lavaggio</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="6">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Quant'è una lunghezza di catena per navi mercantili ?</span>
              </li>
            </ol>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="6">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="6">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">a) 25,00mt </span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">b) 27,35mt</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">c) 30,00mt</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">d) 27,50mt</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c1" colspan="2" rowspan="1">
            <p class="c2">
              <span class="c0">e) 50,00mt</span>
            </p>
          </td>
          <td class="c27" colspan="2" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="7">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Cosa significa PSSR ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c39" colspan="2" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) stazione portuale e responsabilità sociale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) sicurezza personale e responsabilità sociale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) sicurezza personale e rinnovo sociale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) port station survival society</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) tutte le risposte sono errate</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="8">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Cosa è il Ruolo D'appello ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) è una lista di tutte le persone presenti a bordo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) è una lista che indica il numero massimo di persone a bordo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) è una lista preparata dalla capitaneria per il permesso di partenza della nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) è una tabella che riporta le mansioni di tutti i membri dell’equipaggio in caso di emergenza</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) è il luogo dove indica il posizionamento delle lance di salvataggio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="9">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Cosa significa D.P.I. ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) documento pre ingaggio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) dispositivi di protezione isolante</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) dipartimento di polizia investigativo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) deposito per indumenti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) dispositivi di protezione individuale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="10">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Quante ancore sono presente a bordo ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">1 di servizio e 2 di rispetto</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Solo 2 di servizio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">dipende dal modulo di armamento (EN) della nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">2 di servizio e 2 di rispetto</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">1 di servizio e 2 di rispetto</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="11">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Cosa è la discriminazione ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Sbarcare un membro dell'equipaggio di autorità</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">E un complotto dell'equipaggio verso il comandante</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Trasformare un corpo solido in uno liquido</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) è</span>
              <span class="c24">&nbsp;un trattamento non paritario nei confronti di certe categorie di persone ritenute inferiori da non rispettare</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Linciaggio a bordo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29 c37">
              <span class="c0"></span>
            </p>
            <p class="c29 c37">
              <span class="c0"></span>
            </p>
            <p class="c29 c37">
              <span class="c0"></span>
            </p>
            <p class="c29 c37">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="12">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">L'incendio grave come viene segnalato ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="6">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="6">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Un fischio lungo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Due fischi lunghi seguiti dal suono di campane e campanelle per non meno di 10 secondi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Solo dalla viva voce del Comandante</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Tre fischi lunghi seguiti dal suono di campane e campanelle</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Due fischi corti</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="13">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Che cosa è il multi gas detector?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="6">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="6">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">E' un tipo di bombola che può scoppiare in presenza di alte temperature</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">E' uno strumento che misura il grado di esplosione in una cisterna</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">E' un dispositivo che indica le concentrazioni di ossigeno, gas, vapori infiammabili, H2S, CO2</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">E' uno strumento che indica se un materiale è ignifugo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e)</span>
              <span class="c24">&nbsp;Tutte le risposte sono sbagliate </span>
              <span class="c0">&nbsp;</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="14">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Cosa tratta la STCW '78/95?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c39" colspan="2" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">La Convenzione Internazionale che regola le procedure per la Prevenzione dell'Inquinamento</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">La Convenzione internazionale per la Salvaguardia della Vita Umana in Mare</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">La Convenzione Internazionale sugli Standard di Addestramento e sulla Certificazione del Servizio di Guardia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">La convenzione che si interessa dell'educazione del traffico marittimo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">La convenzione che si interessa della protezione del lavoratore marittimo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="15">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Cosa è la falla ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Rottura della chiglia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Rinforzo della chiglia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Incrinatura di una costola</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Foro o squarcio sotto lo scafo della nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Parte inferiore del fasciame</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="16">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Cosa è la collisione ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">E' l'urto fra navi in conseguenza di fortuite circostanze (avarie) o di errata manovra</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Quando una nave rimane incastrata fra gli scogli</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Perdita di idrocarburi a mare</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Rottura del fasciame</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Quando la nave è nell'impossibilità di muoversi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="17">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Come viene segnalato un caduta di una persona a mare ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Un fischio lungo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Due fischi seguiti dal suono di campane e campanelle</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Sette fischi lunghi di sirena seguiti da uno corto e dal suono di campane e campanelli</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Con la viva voce di chi lo ha visto cadere gridando"Uomo a Mare"</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Tre fischi lunghi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="18">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Di cosa parla la SOPEP ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Un piano antinquinamento che prevede procedure operative da seguire per evitare la fuoriuscita accidentale di sostanze oleose in mare</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">E' la rotta di navigazione da mantenere ordinata dal Comandante per l'ufficiale di guardia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">E' un piano che il comandante dispone per l'attacco dei pirati</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Un ente assistenziale per prevenire incidenti durante la sosta della nave in porto</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Tutte le risposte sono sbagliate</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="19">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Quanti occhi ha una nave per tipologia ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="6">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="6">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) 2</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) 3</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) 4</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) 2 a poppa e 1 a prua</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) 1 a poppa e due a prua</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="20">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">A cosa serve l'occhio di Panama?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="6">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="6">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">A leggere l'immersione di prua della nave durante la navigazione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">A metterci una catena per sostituire l'ancora di rispetto</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Come finestra per osservare il Canale di Panama durante il passaggio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">A metterci un riflettore per illuminare il percorso di notte durante il passaggio nel Canale di Panama</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">A passagggio di un cavo per un eventuale rimorchio della nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="21">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Cosa si indica con il termine Emergenza ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c39" colspan="2" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Che si debba abbandonare la nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Che sia caduto un uomo a mare</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Qualsiasi situazione di pericolo per la nave, l’equipaggio e l'ambiente marino</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Quando una nave arriva in porto</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Tutte le risposte sono sbagliate</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="22">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Durante un posto di manovra quali dei seguenti pericoli possono verificarsi maggiormente?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Rottura dei cavi d'ormeggio</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Scivolamento del personale</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Rottura di verricelli e/o salpancora</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Collisione</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Tutte le risposte sono corrette</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="23">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Cosa è l'opera viva ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Parte anteriore della nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Parte sommersa dello scafo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Parte emersa dello scafo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Parte posteriore della nave</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Tutte le risposte sono sbagliate</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="24">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Cosa è la linea di galleggiamento ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Bordo libero</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">La linea che separa la prua dalla poppa</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Linea massima di galleggiabilità</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">La linea d'acqua che separa l'opera viva dall'opera morta</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">La linea più bassa del pettine</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="25">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Per quale motivazione si potrebbe morire in uno spazio chiuso?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Infarto</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Asfissia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Avvelenamento</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Claustrofobia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Tutte le risposte sono sbagliate</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="26">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Qual è il segnale sonoro dell'emergenza generale?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="6">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="6">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Solo dalla viva voce del Comandante</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Due fischi seguiti dal suono di campane e campanelli</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Sette fischi brevi seguito da uno lungo e dal suono di campane e campanelli</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d)</span>
              <span class="c24">&nbsp;Un fischio lungo</span>
              <span class="c0">&nbsp;</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Sirena intermittente</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="27">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Di cosa tratta la Convenzione Internazionale MARPOL 73/78 ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="6">
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="6">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">La Convenzione Internazionale che regola le procedure per la Prevenzione dell'inquinamento marino</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">La Convenzione Internazionale per la Salvaguardia della Vita Umana in Mare</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">La Convenzione Internazionale sugli Standard di Addestramento e sulle Certificazioni del Servizio di Guardia</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d)</span>
              <span class="c24">&nbsp;La convenzione che regola l'educazione del traffico marittimo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">La convenzione che si interessa della protezione del lavoratore marittimo</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="2">
            <ol class="c21 lst-kix_list_1-0" start="28">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Di quanti annessi è composta la Convenzione Internazionale MARPOL 73/78 ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="2">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c35" colspan="11" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c39" colspan="2" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c25">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) 5</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) 4</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) 7</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) 8</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) tutte le risposte sono sbagliate</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_2-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="29">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Quali sono qulle aree ritenute speciali per la Convenzione Internazionale MARPOL ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Il Mar dei Caraibi,Mar Mediterraneo,Mare del Nord</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Il Mar Rosso,Mar Baltico,Antartide,Mar Nero,Mar dei Caraibi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Mar Mediterraneo,Mar Nero,Mar Baltico,Mar Rosso,Mar dei Caraibi,Mare del Nord,Antartide,Zona dei Golfi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Solo le zone racchiuse dalle golfate</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Tutte le risposte sono sbagliate</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <ol class="c21 lst-kix_list_1-0" start="30">
              <li class="c28 c32 li-bullet-0">
                <span class="c0">Cosa tratta il I annesso della Convenzione Internazionale MARPOL 73/78 ?</span>
              </li>
            </ol>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c29">
              <span class="c34">Risposte</span>
            </p>
          </td>
          <td class="c39" colspan="2" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="1">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">a) </span>
              <span class="c24">Prevenzione dell'inquinamento atmosferico</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
          <td class="c39" colspan="2" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c4" colspan="9" rowspan="5">
            <p class="c18">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">b) </span>
              <span class="c24">Prevenzione dell'inquinqmento da liquami delle navi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">c) </span>
              <span class="c24">Prevenzione dell'inquinamento da rifiuti solidi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">d) </span>
              <span class="c24">Prevenzione dell'inquinamento da idrocarburi</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        <tr class="c8">
          <td class="c14" colspan="3" rowspan="1">
            <p class="c2">
              <span class="c0">e) </span>
              <span class="c24">Prevenzione dell'inquinamento da sostanze tossiche nocive trasportate in colli</span>
            </p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <ul class="c21 lst-kix_list_3-0">
              <li class="c23 li-bullet-0">
                <span class="c0"></span>
              </li>
            </ul>
          </td>
        </tr>
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
      </tbody>
    </table>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <table class="c30 firma" style="margin: 0 auto">
      <tbody>
        <tr class="c90">
          <td class="c124" colspan="1" rowspan="1">
            <p class="c56 c47">
              <span class="c0">IL DIRETTORE DEL CORSO</span>
            </p>
            <p class="c56 c47 c37">
              <span class="c0"></span>
            </p>
            <p class="c56 c47 c37">
              <span class="c0"></span>
            </p>
            <p class="c41">
              <span class="c0">________________________</span>
            </p>
            <p class="c37 c41">
              <span class="c0"></span>
            </p>
          </td>
          <td class="c59" colspan="1" rowspan="1">
            <p class="c56 c47">
              <span class="c0">MEMBRO</span>
            </p>
            <p class="c41 c37">
              <span class="c0"></span>
            </p>
            <p class="c41 c37">
              <span class="c0"></span>
            </p>
            <p class="c56 c47">
              <span class="c0">_______________________</span>
            </p>
            <p class="c56 c47 c37">
              <span class="c101"></span>
            </p>
          </td>
          <td class="c83" colspan="1" rowspan="1">
            <p class="c56 c47">
              <span class="c0">MEMBRO E SEGRETARIO</span>
            </p>
            <p class="c47 c37 c56">
              <span class="c0"></span>
            </p>
            <p class="c56 c47">
              <span class="c0">__________________</span>
            </p>
          </td>
          <td class="c59" colspan="1" rowspan="1">
            <p class="c56 c47">
              <span class="c0">IL RAPPRESENTANTE DELL’AUTORITA’ MARITTIMA</span>
            </p>
            <p class="c56 c47">
              <span class="c0">_______________________</span>
            </p>
            <p class="c56 c47 c37">
              <span class="c0"></span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c28">
      <span class="c0">&nbsp;IL DIRETTORE DEL CORSO ___________________________________</span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c28">
      <span class="c0">&nbsp; MEMBRO _______________________________________</span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c28">
      <span class="c0">&nbsp; MEMBRO E SEGRETARIO_____________________________________</span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c18">
      <span class="c0"></span>
    </p>
    <p class="c28">
      <span class="c0">&nbsp;IL RAPPRESENTANTE DELL’AUTORITA’ MARITTIMA ___________________________________</span>
    </p>
    <div>
      <p class="c29 c47">
        <span class="c66">Palermo</span>
        <span class="c43">&nbsp;– Via Francesco Crispi, 155 – Porto Varco S. Lucia – CAP 90133 – </span>
        <span class="c66">Tel. +39 091 320.353</span>
        <span class="c43">&nbsp;– Fax +39 091 612.36.70</span>
      </p>
      <p class="c29 c47">
        <span class="c43">Campo Antincendio S.P. di Bellolampo, 8401-Palermo-email: </span>
        <span class="c68">
          <a class="c54" href="mailto:desantistudio@alice.it">sviluppo.desantiscorsidiformazione.it</a>
        </span>
        <span class="c43">&nbsp;-web:</span>
        <span class="c68">
          <a class="c54" href="https://www.google.com/url?q=http://www.studiodesantissrl.it&amp;sa=D&amp;source=editors&amp;ust=1737694822731745&amp;usg=AOvVaw3L0xSlwCnaytim9GhVoCo0">www.studiodesantissrl.it</a>
        </span>
        <span class="c43">&nbsp; &nbsp; &nbsp; </span>
      </p>
      <p class="c29 c47">
        <span class="c43">Pe</span>
        <span class="c45">c:</span>
        <span class="c68">
          <a class="c54" href="mailto:centro.studio.desantis@pec.it">centro.studio.desantis@pec.it</a>
        </span>
        <span class="c45">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
        <span class="c106">IBAN:ITI7P032680460705295143829</span>
      </p>
      <p class="c29 c47 c37">
        <span class="c82"></span>
      </p>
    </div>
  
</body></html>`
function _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
       binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }
const savePdf = async (payload) => {
    const logo = await fetch('https://mase-storage.s3.eu-west-1.amazonaws.com/1716223770927.document-file.jpeg');
    
    // const mappa = lessons.map((x, i) => singleCourseTemplate.replaceAll('{no}', i + 1).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    // const practicalMap = lessons.filter(x => x.pratica).map((x, i) => Practical.replaceAll('{name}', `Lezione nr. ${i + 1}`).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    html2pdf()
    .set({
        filename: 'scheda_ammissione.pdf',
        margin: 20,
        html2canvas: { useCORS: true },
    })
    .from(Libretto.replaceAll('{docente}', payload.teacher)
            .replaceAll('{direttore}', payload.director)
            .replaceAll('{nome_corso}', payload.nome_corso)
            .replaceAll('{logo}', payload.settings?.logo_top ? payload.settings?.logo_top : payload.school.logo)
            .replaceAll('{autorita_marittima}', payload.course.dataValues.autorita_marittima)
            .replaceAll('{row_number}', payload.rowNumber + 1)
            .replaceAll('{ID}', payload.id ? payload.id : `2024/${payload.nome_corso}/1`)
            .replaceAll('{number}', payload.number)
            .replaceAll('{school_name}', `${payload.school.name}`)
            .replaceAll('{legal_address}', payload.school.sede_legale)
            .replaceAll('{phone}', `+39 ${payload.school.telefono}`)
            .replaceAll('{website}', payload.school.url)
            .replaceAll('{piva}', payload.school.piva)
            .replaceAll('{exam_day}', `${moment(payload.course.dataValues.to).add(1, 'days').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').format('DD/MM/YYYY')}`)
            .replaceAll('{docente}', payload.teacher)
            .replaceAll('{date}', moment().format('DD/MM/YYYY HH:mm'))
                .replaceAll('{direttore}', payload.director)
                .replaceAll('{nome_corso}', payload.nome_corso)
                .replaceAll('{id}', payload.id ? payload.id : `2024/${payload.nome_corso}/1`)
                .replaceAll('{birth_place}', `${payload.partecipant[0].birthplace}`)
                .replaceAll('{birth_province}', `${payload.partecipant[0].birth_province}`)
                .replaceAll('{birthday}', `${moment(payload.partecipant[0].birthday).format('DD/MM/YYYY')}`)
                .replaceAll('{matricola}', `${payload.partecipant[0].matricola}`)
                .replaceAll('{compartment}', `${payload.partecipant[0].compartimento_marittimo}`)
                .replaceAll('{exam_day}', `${moment(payload.course.dataValues.to).add(1, 'days').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').format('DD/MM/YYYY')}`)
                .replaceAll('{expiry}', `${moment(payload.course.dataValues.to).add(1, 'days').add(5, 'years').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').add(5, 'years').format('DD/MM/YYYY')}`)
            
                .replaceAll('{first_name}', `${payload.partecipant[0].first_name}`)
                .replaceAll('{last_name}', `${payload.partecipant[0].last_name}`)
                .replaceAll('{birth_place}', `${payload.partecipant[0].birthplace}`)
                .replaceAll('{birth_province}', `${payload.partecipant[0].birth_province}`)
                .replaceAll('{birth_province}', `${payload.partecipant[0].birth_province}`)
                .replaceAll('{matricola}', `${payload.partecipant[0].matricola}`)
                .replaceAll('{compartment}', `${payload.partecipant[0].compartimento_marittimo}`)
                .replaceAll('{fiscal_code}', `${payload.partecipant[0].fiscal_code}`)
                .replaceAll('{address}', `${payload.partecipant[0].address}`)
                .replaceAll('{civic_number}', `${payload.partecipant[0].civic_number}`)
                .replaceAll('{city_residence}', `${payload.partecipant[0].city_residence}`)
                .replaceAll('{postal_code}', `${payload.partecipant[0].postal_code}`)
                .replaceAll('{compartimento_marittimo}', `${payload.partecipant[0].compartimento_marittimo}`)
                .replaceAll('{phone_number}', `${payload.partecipant[0].phone_number}`)
                .replaceAll('{passport}', `${payload.partecipant[0].passport}`)
                .replaceAll('{decretee}', `${payload.decretee}`)
                .replaceAll('{birthday}', `${moment(payload.partecipant[0].birthday).format('DD/MM/YYYY')}`)
                .replaceAll('{start_day}', `${moment(payload.course.dataValues.from).format('dddd')}, ${moment(payload.course.dataValues.from).format('DD/MM/YYYY')}`)
                // .replaceAll('{partecipants}', payload.partecipant.map((partecipant, idx) => singlePartecipant(partecipant, idx + 1, payload.id)).join('\n'))
                .replaceAll('{school_address}', payload.school.indirizzo_scuola)  
                .replaceAll('{logo}', payload.school.logo)
            ).toPdf().get('pdf').then(pdf => {
                const totalPages = pdf.internal.getNumberOfPages(); // Numero totale di pagine

                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);

                    // Aggiungi Header
                    pdf.setFontSize(10);
                    pdf.text(`${payload.school.name} | Batteria Corso`, 10, 10); // Posizione dell'header (10mm dall'alto)

                    // Aggiungi Footer
                    pdf.text(
                        `Pagina ${i} di ${totalPages}`, // Numerazione delle pagine
                        pdf.internal.pageSize.getWidth() / 2, // Centro della pagina
                        pdf.internal.pageSize.getHeight() - 10, // 10mm dal fondo
                        { align: 'center' }
                    );
                }

                // Apri il PDF
                    window.open(pdf.output('bloburl'), '_blank');
                })
}
export { Libretto, savePdf }